import { useEffect, useState } from 'react';
import queryString from "query-string";
import moment from 'moment'

import Base from '../../utils/base'
import History from './component/history'
import HistoryTransfer from './component/history_transfer'
import AddInventory from './component/add'
import InventoryInfo from './component/info'

export default function InventoryAction(props) {
  var base = new Base()
  const [query, set_query] = useState({})
  const [data, set_data] = useState({
    amount: 0,
    product_final: {
      photo: [],
      product: {
        name: '',
        photo: [],
      },
      currency: {
        name: ''
      },
      price: 0,
      price_after_discount: 0,
    },
  })
  const [rnd, set_rnd] = useState('')
  const [manual_type, set_manual_type] = useState('')

  useEffect(() => {
    var query1 = queryString.parse(props.location.search)
    set_query(query1)
    get_data()
  }, [])

  function get_data() {
    var queryParams = queryString.parse(props.location.search)
    base.request(base.host + '/inventory?id=' + queryParams.id).then(response => {
      if(response != null && response.status === 'success'){
        if(response.data.product_final.photo.length > 0)
          response.data.product_final.photo.forEach((data, index) => {
            data.file_name = base.host + '/image/product?file_name=' + data.file_name
          })
        else
          response.data.product_final.product.photo.forEach((data, index) => {
            data.file_name = base.host + '/image/product?file_name=' + data.file_name
          })
        response.data.product_name = response.data.product_final.product.name
        response.data.product_final.sub_variant.forEach((sub_variant, index) => {
          response.data.product_name += " " + sub_variant.product_sub_variant.product_variant.name + " " + sub_variant.product_sub_variant.name
        })
        set_data(response.data)
      }
    })
  }

  function on_submit(){
    set_rnd(moment().format('YYYYMMDDHHMMSS'))
    get_data()
  }

  return (
    <div>
      <h3>{base.i18n.t('detail_inventory')}</h3>

      <AddInventory inventory={data} type={manual_type} on_submit={() => on_submit()}/>
      <button className="btn btn-primary mr-3" data-toggle="modal" data-target="#manualAddInventory" onClick={() => set_manual_type('add')}>{base.i18n.t('add')}</button>
      <button className="btn btn-primary" data-toggle="modal" data-target="#manualAddInventory" onClick={() => set_manual_type('subtract')}>{base.i18n.t('subtract')}</button>

      <div className="row mt-3">
        <div className="col-12 mb-3">
          <InventoryInfo inventory={data}/>
        </div>
        <div className="col-12">
          {
            query.id != null ?
            <History query={query} rnd={rnd}/>
            :
            <></>
          }
        </div>
        <div className="col-12 col-lg-6 d-none">
          {
            query.id != null ?
            <HistoryTransfer query={query} rnd={rnd}/>
            :
            <></>
          }
        </div>
      </div>

    </div>
  );
}
