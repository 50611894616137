import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import BlogSustainabilityPage from '../../pages/master/blog_content/sustainability/index'
import BlogSustainabilityActionPage from '../../pages/master/blog_content/sustainability/action'

export default function BlogSustainabilityRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={BlogSustainabilityPage} />
      <Route exact path={`${props.match.path}/action`} component={BlogSustainabilityActionPage} />
    </>
  )
}
