import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../utils/base'
import Overview from './component/overview'
import CourierOverview from './component/courier_overview'
import User from './component/user'
import Payment from './component/payment'
import Detail from './component/detail'
import Address from './component/address'
import ConfirmationModal from '../../layout/modal/confirmation_modal'
import InputShippingNoModal from './component/input_shipping_no_modal'

export default function OrderAction(props) {
  var base = new Base()
  const [data, set_data] = useState({
    id: '',
    grand_total: 0,
    detail: [],
    city: {
      name: '',
      province: {
        name: '',
        country: {
          name: '',
        },
      },
    },
    payment_method: {
      name: ''
    },
    voucher: {
      code: ''
    },
    date: '',
    currency: {
      name: '',
    },
    courier: {
      name: "",
    },
    status: '',
    status_payment: '',
    user: {
      name: '',
      phone: '',
      email: '',
    },
    total_price: 0,
    shipping_fee: 0,
    discount: 0,
  })
  const [query, set_query] = useState({})
  const [status_str, set_status_str] = useState('')

  useEffect(() => {
    var query1 = queryString.parse(props.location.search)
    set_query(query1)

    get_data()
  }, [])

  function get_data() {
    var queryParams = queryString.parse(props.location.search)
    base.request(base.host + '/order?id=' + queryParams.id)
      .then(response => {
        if(response != null && response.status === 'success'){
          response.data.detail.forEach((detail, index) => {
            detail.product_final.name = ''
            detail.product_final.sub_variant.forEach((sub_variant, index1) => {
              detail.product_final.name += ' ' + sub_variant.product_sub_variant.product_variant.name + ' ' + sub_variant.product_sub_variant.name
            })
          })

          if(response.data.status === 'wait_payment')
            set_status_str(base.i18n.t('set_as_processed'))
          else if(response.data.status === 'processed')
            set_status_str(base.i18n.t('set_as_shipped'))
          else if(response.data.status === 'shipped')
            set_status_str(base.i18n.t('set_as_arrived'))
          else if(response.data.status === 'complained')
            set_status_str(base.i18n.t('set_as_done'))

          set_data(response.data)
        }
      })
  }

  function change_status(status) {
    var temp = JSON.parse(JSON.stringify(data))
    temp.status = status

    base.request(base.host + '/order', 'put', temp).then(response => {
      if(response != null && response.status === 'success'){
        get_data()
      }
    })
  }

  function change_status_payment(status) {
    var temp = JSON.parse(JSON.stringify(data))

    base.request(base.host + '/order/bypass/payment', 'put', temp).then(response => {
      if(response != null && response.status === 'success'){
        get_data()
      }
    })
  }

  function on_cancel_confirmation(){
    window.$('#confirmationModal').modal('hide')
  }

  function on_change_status(){
    if(data.status === 'processed')
      window.$('#inputShippingNoModal').modal('show')
    else
      window.$('#confirmationModal').modal('show')
  }

  function on_confirmation(){
    window.$('#confirmationModal').modal('hide')

    var status = ''
    if(data.status === 'wait_payment')
      status = 'processed'
    else if(data.status === 'processed')
      status = 'shipped'
    else if(data.status === 'shipped')
      status = 'arrived'
    else if(data.status === 'complained')
      status = 'done'

    change_status(status)
  }

  function on_download(){
    window.print()
  }

  return (
    <div>
      <ConfirmationModal
        on_cancel_click={() => on_cancel_confirmation()}
        on_confirm_click={() => on_confirmation()}/>
      <InputShippingNoModal
        data={data}
        on_finish={() => get_data()}/>
      <h3>{base.i18n.t('detail_order')}</h3>
      <div className="d-print-none mb-3">
        {
          data.status_payment == 'paid' ?
          (
            data.status == 'wait_payment' || data.status == 'processed' || data.status == 'shipped' || data.status == 'complained' ?
            <button className="btn btn-primary mr-3" onClick={() => on_change_status()}>{status_str}</button>
            :
            <></>
          )
          :
          <button className="btn btn-primary mr-3" onClick={() => change_status_payment()}>{base.i18n.t('bypass_payment')}</button>
        }
        <a className="btn btn-primary mr-3" target="_blank" href={base.host + "/download/order?id=" + data.id}>{base.i18n.t('download')}</a>
        <a className="btn btn-primary mr-3" target="_blank" href={base.host + "/download/receipt?id=" + data.id}>{base.i18n.t('download_receipt')}</a>
        {
          data.status !== 'wait_payment' && data.status !== 'accepted' && data.status !== 'canceled' ?
          <a className="btn btn-primary mr-3" target="_blank" href={base.host + "/download/shipping-label?id=" + data.id}>{base.i18n.t('download_shipping_label')}</a>
          :
          <></>
        }

      </div>
      <div className="row mb-3">
        <div className="col-12 col-lg-6 mb-3">
          <Overview data={data}/>
        </div>
        <div className="col-12 col-lg-6 mb-3">
          <CourierOverview Overview data={data}/>
        </div>
        <div className="col-12 col-lg-6 mb-3">
          <User data={data}/>
        </div>
        <div className="col-12 col-lg-6 mb-3">
          <Payment data={data}/>
        </div>
        <div className="col-12 col-lg-6 mb-3">
          <Address data={data}/>
        </div>
        <div className="col-12 mb-3">
          <Detail data={data}/>
        </div>
      </div>
    </div>
  );
}
