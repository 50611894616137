export default {
  app_name: "Lumos Admin",
  product: "Product",
  web_content: "Web Content",
  enter_email: 'Enter Email',
  enter_password: 'Enter Password',
  email_empty: 'Email is empty',
  not_email_format: 'Email is not in email format',
  password_empty: 'Password is empty',
  name_empty: 'Name is empty',
  category_empty: 'Category is empty',
  variant_empty: 'Variant is empty',
  photo_empty: 'Photo is empty',
  phone_empty: 'Phone is empty',
  address_empty: 'Address is empty',
  title_empty: 'Title is empty',
  content_empty: 'Content is empty',
  code_empty: 'Code is empty',
  blog_type_empty: 'Home Subtitle is empty',
  type_discount_empty: 'Type discount is empty',
  currency_empty: 'Currency is empty',
  price_empty: 'Price is empty',
  amount_empty: 'Amount is empty',
  banner_empty: 'Banner is empty',
  discount_empty: 'Discount is empty',
  start_date_empty: 'Start date is empty',
  end_date_empty: 'End Date is empty',
  country_empty: 'Country is empty',
  province_empty: 'Province is empty',
  dimension_empty: 'Dimension is empty',
  current_password_empty: 'Current Password is empty',
  new_password_empty: 'New Password is empty',
  confirm_password_empty: 'Confirm Password is empty',
  product_not_choose: 'Product is not choosen yet',
  stock_empty: 'Stock is empty',
  expired_date_empty: 'Expired Date is empty',
  weight_empty: 'Weight is empty',
  shipping_receipt_no_empty: 'Shipping receipt no is empty',
  expired_date: 'Expired Date',
  shipped_date: 'Shipped Date',
  arrived_date: 'Arrived Date',
  canceled_date: 'Canceled Date',
  complained_date: 'Complained Date',
  accepted_date: 'Accepted Date',
  done_date: 'Done Date',
  notice: 'Notice',
  please_wait: 'Please Wait...',
  inventory: "Inventory",
  image: "Image",
  name: "Name",
  birth_date: "Birth Date",
  description: 'Description',
  code: 'Code',
  price_in_rupiah: 'Price in Rupiah',
  customer_service: 'Customer Service',
  detail_customer_service: 'Detail Customer Service',
  min_payment: 'Minimum Payment',
  type_discount: 'type discount',
  fixed: 'fixed',
  percentage: 'percentage',
  max_discount: 'maximum discount',
  discount: 'Discount',
  add_discount: 'Add Discount',
  edit_discount: 'Edit Discount',
  sender: "Sender",
  receiver: "Receiver",
  message: "Message",
  email: "Email",
  phone: "Phone",
  amount: "Amount",
  category: "Category",
  courier: "Courier",
  date: "Date",
  type: "Type",
  no: "No",
  yes: "Yes",
  id: 'ID',
  published: "Published",
  unpublished: "Unpublished",
  enabled: "Enabled",
  disabled: "Disabled",
  detail: "Detail",
  submit: "Submit",
  info: "Info",
  address: "Address",
  variant: "Variant",
  currency: "Currency",
  price: "Price",
  price_after_discount: "Price after Discount",
  total_price: "Total Price",
  shipping_fee: "Shipping Fee",
  grand_total: "Grand Total",
  weight: "Weight",
  dimension: "Dimension",
  photo: "Photo",
  upload_file: "Upload File",
  delete: "Delete",
  close: "Close",
  title: "Title",
  content: "Content",
  add: "Add",
  subtract: "Subtract",
  view: "View",
  action: "Action",
  edit: "Edit",
  profile: "Profile",
  logout: "Logout",
  login: "Login",
  cannot_find_variant: "Cannot find your variant?",
  go_back: "Go Back",
  no_data_found: "No Data Found",
  no_image: "No Image",
  status_publish: "Status Publish",
  status_enabled: "Status Enabled",
  status_payment: "Status Payment",
  status: "Status",
  choose: "Choose",
  detail_inventory: "Detail Inventory",
  history_transfer_inventory: "History Transfer Inventory",
  history_inventory: "History Inventory",
  choose_option: "Choose Option",
  choose_product: "Choose Product",
  choose_product_variant: "Choose Product Variant",
  clear_product_variant: "Clear Product Variant",
  choose_category: "Choose Category",
  add_address: "Add Address",
  product_info: "Product Info",
  shipping_info: "Shipping Info",
  product_photo: "Product Photo",
  add_product: "Add Product",
  edit_product: "Edit Product",
  add_product_variant: "Add Product Variant",
  edit_product_variant: "Edit Product Variant",
  product_variant: "Product Variant",
  user: "User",
  product_name: "Product Name",
  exchange: "Exchange",
  last_exchange: "Last Exchange",
  history_exchange: "History Exchange",
  add_exchange: "Add Exchange",
  order: "Order",
  register_at: "Register at",
  detail_order: "Detail Order",
  order_overview: "Order Overview",
  user_info: "User Info",
  payment_info: "Payment Info",
  add_user: "Add User",
  edit_user: "Edit User",
  banner: "Banner",
  add_banner: "Add Banner",
  edit_banner: "Edit Banner",
  blog: "Blog",
  blog_payment_method: "Blog Payment Method",
  store_policy: "Store Policy",
  add_blog: "Add Blog",
  edit_blog: "Edit Blog",
  blog_in_press: "In the Press",
  add_blog_in_press: "Add In the Press",
  edit_blog_in_press: "Edit In the Press",
  blog_home: "Home Content",
  add_blog_home: "Add Home Content",
  edit_blog_home: "Edit Home Content",
  add_blog_sustainability: "Add Sustainability",
  edit_blog_sustainability: "Edit Sustainability",
  add_blog_collection: "Add Collection",
  edit_blog_collection: "Edit Collection",
  add_blog_about_us: "Add About Us",
  edit_blog_about_us: "Edit About Us",
  add_blog_promo: "Add Promo",
  edit_blog_promo: "Edit Promo",
  add_blog_contact: "Add Contact",
  edit_blog_contact: "Edit Contact",
  add_blog_store_policy: "Add Store Policy",
  edit_blog_store_policy: "Edit Store Policy",
  add_blog_payment_method: "Add Blog Payment Method",
  edit_blog_payment_method: "Edit Blog Payment Method",
  add_category: "Add Category",
  edit_category: "Edit Category",
  add_courier: "Add Courier",
  edit_courier: "Edit Courier",
  start_date: "Start Date",
  end_date: "End Date",
  end: "End",
  city: "City",
  add_city: "Add City",
  edit_city: "Edit City",
  client: "Client",
  add_client: "Add Client",
  edit_client: "Edit Client",
  country: "Country",
  add_country: "Add Country",
  edit_country: "Edit Country",
  province: "Province",
  add_province: "Add Province",
  edit_province: "Edit Province",
  voucher: "Voucher",
  add_voucher: "Add Voucher",
  edit_voucher: "Edit Voucher",
  wait_payment: "Wait Payment",
  processed: "Processed",
  shipped: "Shipped",
  arrived: "Arrived",
  accepted: "Accepted",
  complained: "Complained",
  canceled: "Canceled",
  done: "Done",
  set_as_wait_payment: "Set as Wait Payment",
  set_as_processed: "Set as Processed",
  set_as_shipped: "Set as Shipped",
  set_as_arrived: "Set as Arrived",
  set_as_accepted: "Set as Accepted",
  set_as_complained: "Set as Complained",
  set_as_canceled: "Set as Canceled",
  set_as_done: "Set as Done",
  cancel: "Cancel",
  download: "Download",
  dashboard: 'Dashboard',
  master: 'Master',
  confirm: 'Confirm',
  confirmation: 'Are you sure?',
  logout_confirmation: 'Are you sure to logout?',
  report_sales: 'Report Sales',
  report: 'Report',
  sales: 'Sales',
  today: 'Today',
  conversion_rate: 'Conversion Rate',
  ranking: 'Ranking',
  report_ranking: 'Report Ranking',
  balance: 'Balance',
  inventory_info: 'Inventory Info',
  sales_report: 'Sales Report',
  list_order: 'List Order',
  product_viewed: 'Product Viewed',
  total_viewer: 'Total Viewer',
  viewer: 'Viewer',
  stock: 'Stock',
  order_id: 'Order ID',
  sales_based: 'Sales Based',
  product_based: 'Product Based',
  my_business: 'My Business',
  set_primary: 'Set as primary',
  as_primary: 'As Primary',
  variant_toggle: 'Variant Toggle',
  sales_info: 'Sales Info',
  variant_list: 'Variant List',
  variant_price: 'Variant Price',
  add_variant: 'Add Variant',
  option: 'Options',
  sku: 'SKU',
  fee: 'Fee',
  add_option: 'Add Options',
  is_empty: 'is empty',
  photo_list: 'Photo List',
  user_name: 'User Name',
  variant_name: 'Variant Name',
  courier_overview: 'Courier Overview',
  courier_name: 'Courier Name',
  courier_packet_name: 'Courier Packet Name',
  courier_packet_detail: 'Courier Packet Detail',
  last_updated: 'Last Updated',
  detail_address: 'Detail Address',
  total_comment: 'Total Comment',
  total_viewer: 'Total Viewer',
  user_admin: 'User Admin',
  add_user_admin: 'Add User Admin',
  edit_user_admin: 'Edit User Admin',
  add_blog_type: 'Add Home Subtitle',
  edit_blog_type: 'Edit Home Subtitle',
  blog_type: 'Home Subtitle',
  edit_profile: 'Edit Profile',
  edit_setting_general: 'Edit Setting General',
  edit_setting_shipping_discount: 'Edit Setting Shipping Discount',
  setting: 'Setting',
  setting_shipping_discount: 'Setting Shipping Discount',
  send_email_admin: 'Send Email to Admin',
  send_email_user: 'Send Email to User',
  sustainability: 'Sustainability',
  collection: 'Collection',
  about_us: 'About Us',
  status_publish: 'Status Publish',
  download_shipping_label: 'Download Shipping Label',
  download_receipt: 'Download Receipt',
  bypass_payment: 'Bypass Payment',
  end_now: 'End Now',
  notes: 'Notes',
  total_order: 'Total Order',
  input_shipping: 'Input Shipping Info',
  shipping_no: 'Shipping Receipt No',
  shipping_address: 'Shipping Address',
  payment_method: 'Payment Method',
  complain_message: 'Complain Message',
  promo: 'Promo',
  hide: 'Hide',
  show: 'Show',
  change_hide: 'Change Hide',
  change_show: 'Change Show',
  show_hidden: 'Show/Hidden',
  contact: 'Contact',
  confirm_password: 'Confirm Password',
  current_password: 'Current Password',
  new_password: 'New Password',
  change_password: 'Change Password',
  confirm_password_not_same: 'Confirm Password is not same with New Password',
  add_stock: 'Add Stock',
  kecamatan: 'Kecamatan',
  kelurahan: 'Kelurahan',
  notification: "Notification",
  shipping_voucher: "Shipping Voucher",
  order_shipping_discount_is_publish: "Order Shipping Discount Status Publish",
  order_min_purchase_shipping_discount: "Order Shipping Discount Minimum Purchase",
  add_shipping_voucher: "Add Shipping Voucher",
  edit_shipping_voucher: "Edit Shipping Voucher",
  order_shipping_discount: "Order Shipping Discount",
}
