import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import SalesPage from '../../pages/report/sales/index'
import RankingPage from '../../pages/report/ranking/index'

export default function SalesRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/sales`} component={SalesPage} />
      <Route exact path={`${props.match.path}/ranking`} component={RankingPage} />
    </>
  )
}
