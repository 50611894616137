import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import CityPage from '../../pages/master/city/index'
import CityActionPage from '../../pages/master/city/action'

export default function CityRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={CityPage} />
      <Route exact path={`${props.match.path}/action`} component={CityActionPage} />
    </>
  )
}
