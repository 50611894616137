import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import ClientPage from '../../pages/master/client/index'
import ClientActionPage from '../../pages/master/client/action'

export default function ClientRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={ClientPage} />
      <Route exact path={`${props.match.path}/action`} component={ClientActionPage} />
    </>
  )
}
