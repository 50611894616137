import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2'
import moment from 'moment'

import Base from '../../../../utils/base'

export default function SalesViewer() {
  var base = new Base()
  const [arr, set_arr] = useState([])
  const [data, set_data] = useState({
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [{
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
    }]
  })
  const [options, set_options] = useState({
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  })

  useEffect(() => {
    get_data()
  }, [])

  useEffect(() => {
    var arr_labels = manage_label()
    var arr_temp = manage_dataset(arr_labels)
    var temp = {
      labels: arr_labels,
      datasets: [{
        label: base.i18n.t('viewer'),
        data: arr_temp,
      }]
    }
    set_data(temp)
  }, [arr])

  function manage_label(){
    var arr_labels = []
    arr.forEach((data1, index1) => {
      var counter = 0
      arr_labels.forEach((label, index2) => {
        if(label === moment(data1.last_visit_at).format('DD/MM/YYYY'))
          return
        counter++
      })
      if(counter === arr_labels.length)
        arr_labels.push(moment(data1.last_visit_at).format('DD/MM/YYYY'))
    })
    return arr_labels
  }

  function manage_dataset(arr_labels){
    var arr_temp = []
    arr_labels.forEach((label, index1) => {
      var total = 0
      arr.forEach((data2, index2) => {
        if(label === moment(data2.created_at).format('DD/MM/YYYY'))
          total++
      })
      arr_temp.push(total)
    })
    return arr_temp
  }

  function get_data() {
    var sort = [
      {
        name: 'last_visit_at',
        sort: 'desc',
      },
    ]
    base.request(base.host + '/website-viewer?sort=' + JSON.stringify(sort)).then(response => {
      if(response != null && response.status === 'success'){
        var arr_temp = []
        for(let x = response.data.data.length - 1; x >= 0; x--)
          arr_temp.push(response.data.data[x])
        set_arr(arr_temp)
      }
    })
  }

  return (
    <div className="card">
      <div className="card-header">
        <h5>{base.i18n.t('total_viewer')}</h5>
      </div>
      <div className="card-body">
        <Line data={data} options={options} />
      </div>
    </div>
  );
}
