import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../../utils/base'

export default function SettingGeneral(props) {
  var base = new Base()
  const [data, set_data] = useState({})
  const [shipping_discount_max, set_shipping_discount_max] = useState(0)
  const [min_purchase_shipping_discount, set_min_purchase_shipping_discount] = useState(0)
  const [shipping_discount_is_publish, set_shipping_discount_is_publish] = useState(0)
  const [query, set_query] = useState({})

  useEffect(() => {
    var query1 = queryString.parse(props.location.search)
    set_query(query1)

    get_data()
  }, [])

  useEffect(() => {
    base.to_currency_format(shipping_discount_max, set_shipping_discount_max)
    base.to_currency_format(min_purchase_shipping_discount, set_min_purchase_shipping_discount)
  }, [shipping_discount_max, min_purchase_shipping_discount])

  function get_data() {
    var queryParams = queryString.parse(props.location.search)
    base.request(base.host + '/setting').then(response => {
      if(response != null && response.status === 'success'){
        set_data(response.data)
        set_shipping_discount_max(response.data.shipping_discount_max.toLocaleString(base.locale_string))
        set_min_purchase_shipping_discount(response.data.min_purchase_shipping_discount.toLocaleString(base.locale_string))
        set_shipping_discount_is_publish(response.data.shipping_discount_is_publish.toLocaleString(base.locale_string))
      }
    })
  }

  function submit() {
    // if(name === '')
    //   base.notify_user(base.i18n.t('name_empty'))
    // else{
      var queryParams = queryString.parse(props.location.search)
      var temp = data
      temp.shipping_discount_max = shipping_discount_max
      temp.min_purchase_shipping_discount = min_purchase_shipping_discount
      temp.shipping_discount_is_publish = shipping_discount_is_publish

      base.request(base.host + '/setting', 'put', temp).then(response => {
        if(response != null && response.status === 'success'){
          // window.location.reload()
        }
      })
    // }
  }

  return (
    <div>
      <h3>{base.i18n.t('edit_setting_shipping_discount')}</h3>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">

              <div className="form-group col-12">
                <label>{base.i18n.t('status_publish')}</label>
                <div>
                  <div className="form-check-inline">
                    <input className="form-check-input" type="radio" onChange={() => set_shipping_discount_is_publish(0)} checked={shipping_discount_is_publish == 0} value="0" id="flexRadioDefaultNo"/>
                    <label className="form-check-label" for="flexRadioDefaultNo">{base.i18n.t('no')}</label>
                  </div>
                  <div className="form-check-inline">
                    <input className="form-check-input" type="radio" onChange={() => set_shipping_discount_is_publish(1)} checked={shipping_discount_is_publish == 1} value="1" id="flexRadioDefaultYes"/>
                    <label className="form-check-label" for="flexRadioDefaultYes">{base.i18n.t('yes')}</label>
                  </div>
                </div>
              </div>

              <div className="form-group col-12">
                <label>{base.i18n.t('max_discount')}</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Rp.</div>
                  </div>
                  <input type="text"className="form-control" onChange={e => set_shipping_discount_max(e.target.value)} value={shipping_discount_max}/>
                </div>
              </div>

              <div className="form-group col-12">
                <label>{base.i18n.t('min_payment')}</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Rp.</div>
                  </div>
                  <input type="text"className="form-control" onChange={e => set_min_purchase_shipping_discount(e.target.value)} value={min_purchase_shipping_discount}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-3">
          <button className="btn btn-primary" onClick={() => submit()}>{base.i18n.t('submit')}</button>
        </div>
      </div>
    </div>
  );
}
