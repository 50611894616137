import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2'
import moment from 'moment'

import Base from '../../../utils/base'
import SalesRanking from './component/sales_ranking'
import CountRanking from './component/count_ranking'

export default function ReportRanking() {
  var base = new Base()
  const [arr_tabs, set_arr_tabs] = useState([
    {
      id: 'sales_based',
      label: base.i18n.t('sales_based'),
      component: <SalesRanking/>,
    },
    {
      id: 'product_based',
      label: base.i18n.t('product_based'),
      component: <CountRanking/>,
    },
  ])

  return (
    <div>
      <h3>{base.i18n.t('report_ranking')}</h3>
      <ul className="nav nav-pills d-print-none" id="myTab" role="tablist">
        {
          arr_tabs.map((data, index) => (
            <li className="nav-item" role="presentation" key={index}>
              <a className={"nav-link" + (index == 0 ? ' active' : '')} id={data.id+'-tab'} data-toggle="tab" href={'#'+data.id} role="tab" aria-controls={'nav-'+data.id} aria-selected="false">{data.label}</a>
            </li>
          ))
        }
      </ul>
      <div className="tab-content mt-3" id="myTabContent">
        {
          arr_tabs.map((data, index) => (
            <div className={"tab-pane fade" + (index == 0 ? ' show active' : '')} key={index} id={data.id} role="tabpanel" aria-labelledby={data.id + '-tab'}>{data.component}</div>
          ))
        }
      </div>
    </div>
  );
}
