import { useEffect, useState } from 'react';
import moment from 'moment'

import Base from '../../../utils/base'
import CustomTable from '../../../layout/custom_table/index'

export default function Voucher() {
  var base = new Base()
  const [arr_header, set_arr_header] = useState([
    {
      label: base.i18n.t('image'),
      data: 'file_name',
      type: 'image',
    },
    {
      label: base.i18n.t('name'),
      data: 'name',
    },
    {
      label: base.i18n.t('code'),
      data: 'code',
    },
    {
      label: base.i18n.t('min_payment'),
      data: 'min_payment',
    },
    {
      label: base.i18n.t('discount'),
      data: 'discount',
    },
    {
      label: base.i18n.t('start_date'),
      data: 'start_at_str',
    },
    {
      label: base.i18n.t('expired_date'),
      data: 'expired_at_str',
    },
    {
      label: base.i18n.t('show_hidden'),
      data: 'is_show_format',
    },
  ])
  const [arr, set_arr] = useState([])

  function on_update_data(arr){
    arr.forEach((data, index) => {
      if(data.file_name != null)
        data.file_name = base.host + '/image/voucher?file_name=' + data.file_name
      data.min_payment = 'Rp.' + data.min_payment.toLocaleString(base.locale_string)
      data.max_discount = 'Rp.' + data.max_discount.toLocaleString(base.locale_string)
      data.discount = data.type_discount === 'fixed' ? 'Rp.' + data.discount.toLocaleString(base.locale_string) : data.discount.toLocaleString(base.locale_string) + '%'
      data.amount = 'Rp.' + data.amount.toLocaleString(base.locale_string)
      data.start_at_str = data.expired_at != null ? moment(data.start_at).format('DD MMMM YYYY') : '-'
      data.expired_at_str = data.expired_at != null ? moment(data.expired_at).format('DD MMMM YYYY') : '-'

      var start_at = moment(data.start_at)
      var expired_at = moment(data.expired_at)
      data.allow_delete = expired_at >= moment()
      data.allow_hide_show = expired_at >= moment()
    })
    set_arr(arr)
  }

  return (
    <div>
      <h3>{base.i18n.t('voucher')}</h3>
      <CustomTable
        url='/voucher'
        arr_header={arr_header}
        arr={arr}
        url_update='/voucher/action'
        url_create='/voucher/action'
        url_delete='/voucher'
        url_hide_show='/voucher/show'
        text_delete={base.i18n.t('end_now')}
        on_update_data={data => on_update_data(data)}/>
    </div>
  );
}
