import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../../utils/base'
import AddressAction from './address_action'

export default function Address(props) {
  var base = new Base()
  const [data, set_data] = useState(null)
  const [index, set_index] = useState(null)

  useEffect(() => reset_data(), [])

  function reset_data(){
    set_index(null)
    set_data({
      country: {
        id: ''
      },
      province: {
        id: ''
      },
      city: {
        id: ''
      },
      kelurahan: '',
      kecamatan: '',
      address: '',
      detail: '',
    })
  }

  function edit_action(index){
    set_index(index)
    var temp = JSON.parse(JSON.stringify(props.data[index]))
    set_data(temp)
    window.$('#addressAction').modal('show')
  }

  function on_submit(data){
    if(index == null)
      props.onAdd(data)
    else
      props.onEdit(data, index)
  }

  return (
    <div>
      <AddressAction data={data} on_submit={data => on_submit(data)}/>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div>
                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#addressAction" onClick={() => reset_data()}>{base.i18n.t('add')}</button>
              </div>
              <div className="table-responsive">
                <table className="table table-bordered mt-3">
                  <thead>
                    <tr>
                      <th>{base.i18n.t('country')}</th>
                      <th>{base.i18n.t('province')}</th>
                      <th>{base.i18n.t('city')}</th>
                      <th>{base.i18n.t('kecamatan')}</th>
                      <th>{base.i18n.t('kelurahan')}</th>
                      <th>{base.i18n.t('address')}</th>
                      <th>{base.i18n.t('detail')}</th>
                      <th>{base.i18n.t('action')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      props.data.length > 0 ?
                      props.data.map((data, index) => (
                        <tr key={index}>
                          <td>{data.country.name}</td>
                          <td>{data.province.name}</td>
                          <td>{data.city.name}</td>
                          <td>{data.kecamatan}</td>
                          <td>{data.kelurahan}</td>
                          <td>{data.address}</td>
                          <td>{data.detail}</td>
                          <td>
                            <button className="btn btn-warning" onClick={() => edit_action(index)}>{base.i18n.t('edit')}</button>
                            <button className="btn btn-danger" onClick={() => props.onDelete(index)}>{base.i18n.t('delete')}</button>
                          </td>
                        </tr>
                      ))
                      :
                      <tr>
                        <td colSpan="6" className="text-center">{base.i18n.t('no_data_found')}</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
