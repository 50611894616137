import { useEffect, useState } from 'react';
import queryString from "query-string";
import moment from 'moment'

import Base from '../../utils/base'
import Info from './component/info'
import Address from './component/address'

export default function UserAction(props) {
  var base = new Base()
  const [data, set_data] = useState({})
  const [email, set_email] = useState('')
  const [name, set_name] = useState('')
  const [phone, set_phone] = useState('')
  const [birth_date, set_birth_date] = useState(new Date())
  const [file_name, set_file_name] = useState('')
  const [image, set_image] = useState('')
  const [arr_address, set_arr_address] = useState([])
  const [query, set_query] = useState({})
  const [arr_tabs, set_arr_tabs] = useState([])

  useEffect(() => {
    reload_tabs()
    base.limit_data_length(email, set_email)
    base.limit_data_length(name, set_name)
    base.limit_data_length(phone, set_phone, 20)
  }, [email, name, phone, birth_date, file_name, image, arr_address, file_name])

  useEffect(() => {
    setTimeout(() => {
      var query1 = queryString.parse(props.location.search)
      set_query(query1)

      if(query1.id != null)
        get_data()
    },100)
  }, [])

  function reload_tabs(){
    set_arr_tabs([
      {
        id: 'info',
        label: base.i18n.t('info'),
        component: <Info
          name={name}
          email={email}
          phone={phone}
          birth_date={birth_date}
          file_name={file_name}
          image={image}
          query_url={query}
          onChange={(key, value) => on_info_change(key, value)}/>,
      },
      {
        id: 'address',
        label: base.i18n.t('address'),
        component: <Address
          data={arr_address}
          query_url={query}
          onAdd={(data) => on_add_address(data)}
          onEdit={(data, index) => on_edit_address(data, index)}
          onDelete={(index) => on_delete_address(index)}/>,
      },
    ])
  }

  function on_info_change(key, value) {
    if(key == 'email')
      set_email(value)
    else if(key == 'name')
      set_name(value)
    else if(key == 'phone')
      set_phone(value)
    else if(key == 'file_name')
      set_file_name(value)
    else if(key == 'birth_date')
      set_birth_date(value)
    else if(key == 'image')
      set_image(value)
  }

  function on_add_address(data_address) {
    base.add_array(arr_address, set_arr_address, data_address)
  }

  function on_edit_address(data_address, index) {
    base.update_array(arr_address, set_arr_address, data_address, index)
  }

  function on_delete_address(index) {
    base.remove_array(arr_address, set_arr_address, index)
  }

  function get_data() {
    var queryParams = queryString.parse(props.location.search)
    base.request(base.host + '/user?id=' + queryParams.id)
      .then(response => {
        if(response != null && response.status === 'success'){
          set_name(response.data.name)
          set_phone(response.data.phone)
          set_email(response.data.email)
          set_birth_date(new Date(response.data.birth_date))
          set_file_name(base.host + '/image/user?file_name=' + response.data.file_name)
          set_arr_address(response.data.address)
          set_data(response.data)
        }
      })
  }

  function submit() {
    if(name === '')
      base.notify_user(base.i18n.t('name_empty'))
    else if(email === '')
      base.notify_user(base.i18n.t('email_empty'))
    else if(!base.validateEmail(email))
      base.notify_user(base.i18n.t('not_email_format'))
    else{
      var queryParams = queryString.parse(props.location.search)
      var temp = {
        name: name,
        phone: phone,
        email: email,
        birth_date: moment(birth_date).format('YYYY-MM-DD'),
        arr_address: arr_address,
      }
      if(image != '')
        temp.image = image
      if(data.id != null)
        temp.id = data.id

      base.request(base.host + '/user', queryParams.id == null ? 'post' : 'put', temp, 'application/json')
        .then(response => {
          if(response != null && response.status === 'success')
            window.location.href = "/user"
        })
    }
  }

  return (
    <div>
      <h3>{query.id != null ? base.i18n.t('edit_user') : base.i18n.t('add_user')}</h3>
      <ul className="nav nav-pills" id="myTab" role="tablist">
        {
          arr_tabs.map((data, index) => (
            <li className="nav-item" role="presentation" key={index}>
              <a className={"nav-link" + (index == 0 ? ' active' : '')} id={data.id+'-tab'} data-toggle="tab" href={'#'+data.id} role="tab" aria-controls={'nav-'+data.id} aria-selected="false">{data.label}</a>
            </li>
          ))
        }
      </ul>
      <div className="tab-content mt-3" id="myTabContent">
        {
          arr_tabs.map((data, index) => (
            <div className={"tab-pane fade" + (index == 0 ? ' show active' : '')} key={index} id={data.id} role="tabpanel" aria-labelledby={data.id + '-tab'}>{data.component}</div>
          ))
        }
      </div>
      <div className="mt-3">
        <button className="btn btn-primary" onClick={() => submit()}>{base.i18n.t('submit')}</button>
      </div>
    </div>
  );
}
