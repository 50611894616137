import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import CourierPage from '../../pages/master/courier/index'
import CourierActionPage from '../../pages/master/courier/action'

export default function CourierRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={CourierPage} />
      <Route exact path={`${props.match.path}/action`} component={CourierActionPage} />
    </>
  )
}
