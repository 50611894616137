import { useEffect, useState } from 'react';
import moment from 'moment'

import Base from '../../utils/base'
import CustomTable from '../../layout/custom_table/index'

export default function CustomerService() {
  var base = new Base()
  const [arr_header, set_arr_header] = useState([
    {
      label: base.i18n.t('user'),
      data: 'user_name',
    },
    {
      label: base.i18n.t('message'),
      data: 'message',
    },
    {
      label: base.i18n.t('date'),
      data: 'date',
    },
  ])
  const [arr, set_arr] = useState([])

  function on_update_data(arr){
    base.reset_localstorage()
    arr.forEach((data, index) => {
      data.user_name = data.user.name
      data.date = moment(data.created_at).format('DD MMMM YYYY')
    })
    set_arr(arr)
  }

  return (
    <div>
      <h3>{base.i18n.t('customer_service')}</h3>
      <CustomTable 
        url='/customer-service' 
        arr_header={arr_header} 
        arr={arr} 
        url_update='/customer-service/action'
        text_update={base.i18n.t('view')}
        on_update_data={data => on_update_data(data)}/>
    </div>
  );
}