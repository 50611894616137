import { useEffect, useState } from 'react';

import Base from '../../utils/base'
import CustomTable from '../../layout/custom_table/index'

import AddInventory from './component/add'

export default function Inventory() {
  var base = new Base()
  const [arr_header, set_arr_header] = useState([
    {
      label: base.i18n.t('image'),
      data: 'file_name',
      type: 'image',
    },
    {
      label: base.i18n.t('name'),
      data: 'product_name',
    },
    {
      label: base.i18n.t('amount'),
      data: 'amount',
    },
  ])
  const [arr, set_arr] = useState([])
  const [data, set_data] = useState({})
  const [manual_type, set_manual_type] = useState('')

  function on_update_data(arr){
    base.reset_localstorage()
    arr.forEach((data, index) => {
      if(data.product_final.photo.length > 0)
        data.product_final.photo.forEach((photo, index) => {
          if(photo.is_primary === 1 && photo.file_name != null){
            data.file_name = base.host + '/image/product?file_name=' + photo.file_name
            return
          }
        })
      else
        data.product_final.product.photo.forEach((photo, index) => {
          if(photo.is_primary === 1 && photo.file_name != null){
            data.file_name = base.host + '/image/product?file_name=' + photo.file_name
            return
          }
        })

      data.product_name = data.product_final.product.name
      data.product_final.sub_variant.forEach((sub_variant, index) => {
        data.product_name += " " + sub_variant.product_sub_variant.product_variant.name + " " + sub_variant.product_sub_variant.name
      })
      data.amount = data.amount.toLocaleString(base.locale_string)
    })
    set_arr(arr)
  }

  function on_submit(){
    window.location.reload()
  }

  function on_action(index){
    set_data(arr[index])
    set_manual_type('add')
    window.$('#manualAddInventory').modal('show')
  }

  return (
    <div>
      <AddInventory inventory={data} type={manual_type} on_submit={() => on_submit()}/>
      <h3>{base.i18n.t('inventory')}</h3>
      <CustomTable
        url='/inventory'
        arr_header={arr_header}
        arr={arr}
        url_update='/inventory/action'
        text_update={base.i18n.t('view')}
        on_update_data={data => on_update_data(data)}
        on_action_clicked={index => on_action(index)}
        text_action={base.i18n.t('add_stock')}/>
    </div>
  );
}
