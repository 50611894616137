import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import BlogPage from '../../pages/master/blog/index'
import BlogActionPage from '../../pages/master/blog/action'

import BlogHome from './blog_home'
import BlogType from './blog_type'
import BlogSustainability from './blog_sustainability'
import BlogAboutUs from './blog_about_us'
import BlogInPress from './blog_in_press'
import BlogPromo from './blog_promo'
import BlogContact from './blog_contact'
import BlogStorePolicy from './blog_store_policy'
import BlogPaymentMethod from './blog_payment_method'
import BlogCollection from './blog_collection'

export default function BlogRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={BlogPage} />
      <Route exact path={`${props.match.path}/action`} component={BlogActionPage} />

      <Route path={`${props.match.path}/home`} component={BlogHome}/>
      <Route path={`${props.match.path}/in-press`} component={BlogInPress}/>
      <Route path={`${props.match.path}/type`} component={BlogType}/>
      <Route path={`${props.match.path}/sustainability`} component={BlogSustainability}/>
      <Route path={`${props.match.path}/about-us`} component={BlogAboutUs}/>
      <Route path={`${props.match.path}/promo`} component={BlogPromo}/>
      <Route path={`${props.match.path}/contact`} component={BlogContact}/>
      <Route path={`${props.match.path}/collection`} component={BlogCollection}/>
      <Route path={`${props.match.path}/store-policy`} component={BlogStorePolicy}/>
      <Route path={`${props.match.path}/payment-method`} component={BlogPaymentMethod}/>
    </>
  )
}
