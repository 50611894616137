import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../../utils/base'

export default function InputShippingNoModal(props) {
  var base = new Base()
  const [shipping_receipt_no, set_shipping_receipt_no] = useState('')

  useEffect(() => {
    set_shipping_receipt_no(props.data.shipping_receipt_no)
  }, [])

  useEffect(() => {
    base.limit_data_length(shipping_receipt_no, set_shipping_receipt_no)
  }, [shipping_receipt_no])

  function on_confirm_click(){
    if(shipping_receipt_no === '')
      base.notify_user(base.i18n.t("shipping_receipt_no_empty"))
    else{
      var temp = props.data
      temp.status = 'shipped'
      temp.shipping_receipt_no = shipping_receipt_no

      base.request(base.host + '/order', 'put', temp)
        .then(response => {
          if(response != null && response.status == 'success'){
            set_shipping_receipt_no('')
            window.$('#inputShippingNoModal').modal('hide')
            props.on_finish()
          }
        })
    }
  }

  return (
    <div className="modal fade" id="inputShippingNoModal" data-keyboard="false" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">{base.i18n.t('input_shipping')}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label>{base.i18n.t('shipping_no')}</label>
              <input type="text" className="form-control" onChange={e => set_shipping_receipt_no(e.target.value)} value={shipping_receipt_no}/>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" data-dismiss="modal">{base.i18n.t('cancel')}</button>
            <button className="btn btn-primary" onClick={() => on_confirm_click()}>{base.i18n.t('confirm')}</button>
          </div>
        </div>
      </div>
    </div>
  );
}
