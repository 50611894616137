import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import BlogHomePage from '../../pages/master/blog_content/home/index'
import BlogHomeActionPage from '../../pages/master/blog_content/home/action'

export default function BlogHomeRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={BlogHomePage} />
      <Route exact path={`${props.match.path}/action`} component={BlogHomeActionPage} />
    </>
  )
}
