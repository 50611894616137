import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../../../utils/base'

export default function VariantPrice(props) {
  var base = new Base()
  const [currency, set_currency] = useState({
    name: ''
  })

  useEffect(() => {
    get_currency_data()
  }, [props.currency])

  function get_currency_data() {
    base.request(base.host + '/currency?id=' + props.currency).then(response => {
      if(response != null && response.status === 'success'){
        set_currency(response.data)
      }
    })
  }

  function on_upload_image(e, index1){
    var temp_arr = []
    var temp_image_arr = []
    Array.from(e.target.files).forEach((data, index) => {
      temp_image_arr.push(data)

      var image = URL.createObjectURL(data)
      var data1 = {}
      data1.file_name = image
      data1.is_primary = false

      base.getBase64(data, result => {
        data1.image = {
          image: result
        }
      })

      temp_arr.push(data1)
    })
    props.onAddPhotoFinal(index1, temp_arr)
  }

  return (
    <div>
      <h5 className="mb-3">{base.i18n.t('variant_price')}</h5>
      <div className="row">
        {
          props.arr_variant_price.map((variant_price, index) => (
            <div key={index} className="col-12 col-lg-6 mb-3">
              <div className="card">
                <div className="card-header">
                  <h5>{variant_price.variant_name}</h5>
                </div>
                <div className="card-body row">
                  <div className="form-group col-6">
                    <label>{base.i18n.t('sku')}</label>
                    <input type="text" className="form-control" value={variant_price.sku} onChange={e => props.onChangeSku(index, e.target.value)}/>
                  </div>
                  <div className="form-group col-6">
                    <label>{base.i18n.t('name')}</label>
                    <input type="text" className="form-control" disabled value={variant_price.variant_name}/>
                  </div>
                  <div className="form-group col-6">
                    <label>{base.i18n.t('price')}</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">{currency.name}</div>
                      </div>
                      <input type="text" className="form-control" value={variant_price.price} onChange={e => props.onChangePrice(index, e.target.value)}/>
                    </div>
                  </div>
                  <div className="form-group col-6">
                    <label>{base.i18n.t('stock')}</label>
                    <div className="input-group">
                      <input type="text" className="form-control" value={variant_price.stock} onChange={e => props.onChangeStock(index, e.target.value)} disabled={variant_price.is_stock_enabled != null && !variant_price.is_stock_enabled}/>
                      <div className="input-group-append">
                        <div className="input-group-text">Pcs</div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-12">
                    <label>{base.i18n.t('photo')}</label>
                    <div>
                      <input type="file" id={"inputFileCombine" + index} className="d-none" multiple onChange={e => on_upload_image(e, index)}/>
                      <button type="button" className="btn btn-primary" onClick={() => window.$('#inputFileCombine' + index).trigger('click')}>{base.i18n.t('upload_file')}</button>
                    </div>
                    <div className="row mt-3">
                      {
                        variant_price.arr_photo.map((photo, index1) => (
                          <div className="col-6" key={index1}>
                            <img src={photo.file_name} width="100%" height="150rem"/>
                            {
                              photo.is_primary ?
                              <div className="w-100 mt-3 text-center">
                                {base.i18n.t('as_primary')}
                              </div>
                              :
                              <div className="w-100 mt-3">
                                <button className="btn btn-primary w-100" onClick={() => props.onChangePrimaryPhotoFinal(index, index1)}>{base.i18n.t('set_primary')}</button>
                              </div>
                            }

                            <div className="w-100 mt-3">
                              <button className="btn btn-danger w-100" onClick={() => props.onDeletePhotoFinal(index, index1)}>{base.i18n.t('delete')}</button>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}
