import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../../utils/base'

export default function AddressAction(props) {
  var base = new Base()
  const [country, set_country] = useState('')
  const [province, set_province] = useState('')
  const [city, set_city] = useState('')
  const [address, set_address] = useState('')
  const [detail, set_detail] = useState('')
  const [kelurahan, set_kelurahan] = useState('')
  const [kecamatan, set_kecamatan] = useState('')
  const [arr_city, set_arr_city] = useState([])
  const [arr_province, set_arr_province] = useState([])
  const [arr_country, set_arr_country] = useState([])

  useEffect(() => {
    get_country_data()
  }, [])

  useEffect(() => {
    set_city([])
    get_province_data()
  }, [country])

  useEffect(() => {
    get_city_data()
  }, [province])

  useEffect(() => {
    if(props.data != null){
      set_country(arr_country.length > 0 ? props.data.country.id : '')
      set_province(arr_province.length > 0 ? props.data.province.id : '')
      set_city(arr_city.length > 0 ? props.data.city.id : '')
      set_kecamatan(props.data.kecamatan)
      set_kelurahan(props.data.kelurahan)
      set_address(props.data.address)
      set_detail(props.data.detail)
    }
  }, [props.data])

  function get_country_data() {
    base.request(base.host + '/country/all').then(response => {
      if(response != null && response.status === 'success'){
        set_arr_country(response.data)
      }
    })
  }

  function get_province_data() {
    base.request(base.host + '/province/all?country_id=' + country).then(response => {
      if(response != null && response.status === 'success'){
        set_arr_province(response.data)
      }
    })
  }

  function get_city_data() {
    base.request(base.host + '/city/all?province_id=' + province).then(response => {
      if(response != null && response.status === 'success'){
        set_arr_city(response.data)
      }
    })
  }

  function get_all_data(arr, data_id){
    var temp
    arr.forEach((data, index) => {
      if(data.id == data_id){
        temp = data
        return
      }
    })
    return temp
  }

  function submit(){
    if(city == '')
      base.notify_user('City is empty')
    else if(address == '')
      base.notify_user('Address is empty')
    else if(kelurahan == '')
      base.notify_user('Kelurahan is empty')
    else if(kecamatan == '')
      base.notify_user('Kecamatan is empty')
    else{
      var country_temp = get_all_data(arr_country, country)
      var province_temp = get_all_data(arr_province, province)
      var city_temp = get_all_data(arr_city, city)

      var data = {
        country: country_temp,
        province: province_temp,
        city: city_temp,
        address: address,
        kelurahan: kelurahan,
        kecamatan: kecamatan,
        detail: detail,
      }
      window.$('#addressAction').modal('hide')
      props.on_submit(data)
    }
  }

  return (
    <div className="modal fade" id="addressAction" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">{base.i18n.t('add_address')}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <span>
                <label>{base.i18n.t('country')}</label>
              </span>
              <select className="form-control" onChange={e => set_country(e.target.value)} value={country}>
                <option value=''>{base.i18n.t('choose_option')}</option>
                {
                  arr_country.map((data,index) => (
                    <option value={data.id} key={index}>{data.name}</option>
                  ))
                }
              </select>
            </div>
            <div className="form-group">
              <span>
                <label>{base.i18n.t('province')}</label>
              </span>
              <select className="form-control" onChange={e => set_province(e.target.value)} value={province}>
                <option value=''>{base.i18n.t('choose_option')}</option>
                {
                  arr_province.map((data,index) => (
                    <option value={data.id} key={index}>{data.name}</option>
                  ))
                }
              </select>
            </div>
            <div className="form-group">
              <span>
                <label>{base.i18n.t('city')}</label>
              </span>
              <select className="form-control" onChange={e => set_city(e.target.value)} value={city}>
                <option value=''>{base.i18n.t('choose_option')}</option>
                {
                  arr_city.map((data,index) => (
                    <option value={data.id} key={index}>{data.name}</option>
                  ))
                }
              </select>
            </div>

            <div className="form-group">
              <label>{base.i18n.t('kelurahan')}</label>
              <input type="text" className="form-control" onChange={e => set_kelurahan(e.target.value)} value={kelurahan}/>
            </div>

            <div className="form-group">
              <label>{base.i18n.t('kecamatan')}</label>
              <input type="text" className="form-control" onChange={e => set_kecamatan(e.target.value)} value={kecamatan}/>
            </div>

            <div className="form-group">
              <label>{base.i18n.t('address')}</label>
              <input type="text" className="form-control" onChange={e => set_address(e.target.value)} value={address}/>
            </div>
            <div className="form-group">
              <label>{base.i18n.t('detail')}</label>
              <textarea className="form-control" onChange={e => set_detail(e.target.value)} value={detail}></textarea>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">{base.i18n.t('close')}</button>
            <button type="button" className="btn btn-primary" onClick={() => submit()}>{base.i18n.t('submit')}</button>
          </div>
        </div>
      </div>
    </div>
  );
}
