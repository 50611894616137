import { useEffect, useState } from 'react';
import queryString from "query-string";
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css';

import Base from '../../../../utils/base'

export default function BlogStorePolicyAction(props) {
  var base = new Base()
  const [data, set_data] = useState({})
  const [blog_home_type, set_blog_home_type] = useState('')
  const [is_publish, set_is_publish] = useState(0)
  const [title, set_title] = useState('')
  const [content, set_content] = useState('')
  const [query, set_query] = useState({})

  useEffect(() => {
    base.limit_data_length(title, set_title)
    base.limit_data_length(content, set_content)
  }, [content, title,])

  useEffect(() => {
    var query1 = queryString.parse(props.location.search)
    set_query(query1)
    get_blog_home_type()

    if(query1.id != null)
      get_data()
  }, [])

  function get_data() {
    var queryParams = queryString.parse(props.location.search)
    base.request(base.host + '/blog/home?id=' + queryParams.id).then(response => {
      if(response != null && response.status === 'success'){
        set_data(response.data)
        set_title(response.data.title)
        set_content(response.data.content)
        set_is_publish(response.data.is_publish)
      }
    })
  }

  function get_blog_home_type() {
    base.request(base.host + '/blog/home/type?name=store_policy').then(response => {
      if(response != null && response.status === 'success'){
        set_blog_home_type(response.data.data[0])
      }
    })
  }

  function submit() {
    // if(title === '')
    //   base.notify_user(base.i18n.t('title_empty'))
    // else{
      var queryParams = queryString.parse(props.location.search)
      var temp = {
        title: title,
        content: content,
        is_publish: is_publish,
        blog_home_type: blog_home_type,
      }
      if(data.id != null)
        temp.id = data.id

      base.request(base.host + '/blog/home', queryParams.id == null ? 'post' : 'put', temp).then(response => {
        if(response != null && response.status === 'success'){
          window.location.href = "/blog/store-policy"
        }
      })
    // }
  }

  return (
    <div>
      <h3>{query.id != null ? base.i18n.t('edit_blog_store_policy') : base.i18n.t('add_blog_store_policy')}</h3>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="form-group">
                <label>{base.i18n.t('status_publish')}</label>
                <div>
                  <div className="form-check-inline">
                    <input className="form-check-input" type="radio" name="status" value="0" onChange={() => set_is_publish(0)} id="flexRadioDefaultYes" checked={is_publish === 0}/>
                    <label className="form-check-label" htmlFor="flexRadioDefaultYes">{base.i18n.t('no')}</label>
                  </div>
                  <div className="form-check-inline">
                    <input className="form-check-input" type="radio" name="status" value="1" onChange={() => set_is_publish(1)} id="flexRadioDefaultNo" checked={is_publish === 1}/>
                    <label className="form-check-label" htmlFor="flexRadioDefaultNo">{base.i18n.t('yes')}</label>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>{base.i18n.t('title')}</label>
                <input type="text" className="form-control" onChange={e => set_title(e.target.value)} value={title}/>
              </div>
              <div className="form-group">
                <label>{base.i18n.t('content')}</label>
                <ReactSummernote
                  value={content}
                  onChange={content => set_content(content)}/>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-3">
          <button className="btn btn-primary" onClick={() => submit()}>{base.i18n.t('submit')}</button>
        </div>
      </div>
    </div>
  );
}
