import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../utils/base'

export default function PleaseWaitWithProgressModal(props) {
  var base = new Base()

  return (
    <div className="modal fade" id="pleaseWaitWithProgress" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">{base.i18n.t('notice')}</h5>
          </div>
          <div className="modal-body">
            {base.i18n.t('please_wait')}
            <div className="progress mt-3">
              <div className="progress-bar" role="progressbar" style={{ width: props.progress + '%' }} aria-valuenow={props.progress} aria-valuemin="0" aria-valuemax="100">{props.progress}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}