import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../../utils/base'

export default function BannerItem(props) {
  var base = new Base()

  return (
    <div>
      <input type="file" className="d-none" id={"inputFileItem" + props.index} onChange={e => props.on_upload_image(e)}/>
      <div className="w-100" style={{ height: '11rem' }}>
        <img src={props.data.file_name} width="100%"/>
      </div>
      <div className="form-group mt-3">
        <label>{base.i18n.t('status_publish')}</label>
        <div>
          <div className="form-check-inline">
            <input className="form-check-input" type="radio" name={"status" + props.index} value="0" onChange={() => props.on_change_publish(0)} id={"flexRadioDefaultYes" + props.index} checked={props.data.is_publish == 0}/>
            <label className="form-check-label" htmlFor={"flexRadioDefaultYes" + props.index}>{base.i18n.t('no')}</label>
          </div>
          <div className="form-check-inline">
            <input className="form-check-input" type="radio" name={"status" + props.index} value="1" onChange={() => props.on_change_publish(1)} id={"flexRadioDefaultNo" + props.index} checked={props.data.is_publish == 1}/>
            <label className="form-check-label" htmlFor={"flexRadioDefaultNo" + props.index}>{base.i18n.t('yes')}</label>
          </div>
        </div>
      </div>
      <div className="mt-3">
        {
          props.data.is_primary === 1 ?
          <div className="w-100 mt-3 text-center">
            {base.i18n.t('as_primary')}
          </div>
          :
          <div className="w-100 mt-3">
            <button className="btn btn-primary w-100" onClick={() => props.on_change_primary()}>{base.i18n.t('set_primary')}</button>
          </div>
        }
        <button className="btn btn-primary w-100 mt-1" onClick={() => window.$('#inputFileItem' + props.index).trigger('click')}>{base.i18n.t('edit')}</button>
        <button className="btn btn-danger w-100 mt-1" onClick={() => props.on_delete()}>{base.i18n.t('delete')}</button>
      </div>
    </div>
  );
}
