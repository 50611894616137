import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import BlogPromoPage from '../../pages/master/blog_content/promo/index'
import BlogPromoActionPage from '../../pages/master/blog_content/promo/action'

export default function BlogPromoRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={BlogPromoPage} />
      <Route exact path={`${props.match.path}/action`} component={BlogPromoActionPage} />
    </>
  )
}
