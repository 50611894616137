import { useEffect, useState } from 'react';
import queryString from "query-string";
import moment from "moment";

import CustomTable from '../../../layout/custom_table/index'
import Base from '../../../utils/base'

export default function HistoryTransfer(props) {
  var base = new Base()
  const [arr_header, set_arr_header] = useState([
    {
      label: base.i18n.t('date'),
      data: 'created_at',
    },
    {
      label: base.i18n.t('type'),
      data: 'type',
    },
    {
      label: base.i18n.t('amount'),
      data: 'amount',
    },
  ])
  const [arr, set_arr] = useState([])

  function on_update_data(arr){
    arr.forEach((data, index) => {
      data.created_at = moment(data.created_at).format('DD MMMM YYYY HH:mm')
      data.amount = data.amount.toLocaleString(base.locale_string)
    })
    set_arr(arr)
  }

  return (
    <div className="card">
      <div className="card-body">
        <h5>{base.i18n.t('history_transfer_inventory')}</h5>
        {
          props.query.id != null ?
          <CustomTable
            url={'/inventory/history/transfer?inventory_id=' + props.query.id}
            arr_header={arr_header}
            arr={arr}
            rnd={props.rnd}
            on_update_data={data => on_update_data(data)}/>
          :
          <></>
        }
      </div>
    </div>
  );
}
