import { useEffect, useState } from 'react';
import queryString from "query-string";
import moment from 'moment'
import {DatePicker} from "react-rainbow-components"

import Base from '../../../../utils/base'

export default function DiscountInfo(props) {
  var base = new Base()

  return (
    <div>
      <div className="row">
        <div className="col-12 mb-3">
          <div className="card">
            <div className="card-body row">
              <div className="form-group col-12">
                <label>{base.i18n.t('name')}</label>
                <input type="text" className="form-control" onChange={e => props.onChange('name', e.target.value)} value={props.name}/>
              </div>

              <div className="form-group col-12">
                <label>{base.i18n.t('description')}</label>
                <textarea className="form-control" onChange={e => props.onChange('description', e.target.value)} value={props.description}></textarea>
              </div>

              <div className="form-group col-12 col-md-6">
                <label>{base.i18n.t('type_discount')}</label>
                <select className="form-control" onChange={e => props.onChange('type_discount', e.target.value)} value={props.type_discount}>
                  <option value="fixed">{base.i18n.t('fixed')}</option>
                  <option value="percentage">{base.i18n.t('percentage')}</option>
                </select>
              </div>

              <div className="form-group col-12 col-md-6">
                <label>{base.i18n.t('discount')}</label>
                <div class="input-group">
                  {
                    props.type_discount === 'fixed' &&
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon2">IDR</span>
                    </div>
                  }

                  <input type="text" className="form-control" onChange={e => props.onChange('discount', e.target.value)} value={props.discount}/>

                  {
                    props.type_discount === 'percentage' &&
                    <div class="input-group-append">
                      <span class="input-group-text" id="basic-addon2">%</span>
                    </div>
                  }
                </div>
              </div>

              <div className="form-group col-12 col-md-6">
                <label>{base.i18n.t('start_date')}</label>
                <DatePicker locale={base.locale_string} value={props.start_date} onChange={date => props.onChange('start_date', date)} maxDate={new Date(props.end_date)}/>
              </div>

              <div className="form-group col-12 col-md-6">
                <label>{base.i18n.t('end_date')}</label>
                <DatePicker locale={base.locale_string} value={props.end_date} onChange={date => props.onChange('end_date', date)} minDate={props.start_date != "" ? new Date(props.start_date) : new Date()}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
