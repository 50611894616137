import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../../../utils/base'
import DiscountChooseProduct from '../component/choose_product'
import DiscountChooseProductFinal from '../component/choose_product_final'

export default function ProductAction(props) {
  var base = new Base()
  const [arr_product, set_arr_product] = useState([])
  const [product, set_product] = useState({})
  const [product_final, set_product_final] = useState({})

  useEffect(() => {
    if(props.data != null){
      set_product(props.data.product)
      set_product_final(props.data.product_final)
    }
  }, [props.data])

  useEffect(() => {
    set_arr_product(props.arr_product)
  }, [props.arr_product])

  function submit(){
    if(product.id == '')
      base.notify_user('Product is empty')
    else{
      var data = {
        product: product,
        product_final: product_final,
      }

      var flag = true
      for(var temp of arr_product){
        if(temp.product != null && temp.product.id != null && temp.product.id == data.product.id){
          flag = false
          break
        }
        else if(temp.product_final != null && temp.product_final.id != null && temp.product_final.id == data.product_final.id){
          flag = false
          break
        }
      }

      if(flag){
        window.$('#productAction').modal('hide')
        props.on_submit(data)
      }
      else{
        base.notify_user('Product already exist in discount list')
      }
    }
  }

  function on_choose_product(data){
    set_product(data)
    set_product_final({})
  }

  function on_choose_product_final(data){
    set_product_final(data)
    set_product(data.product)
  }

  function on_clear_product_variant(){
    set_product_final({})
  }

  return (
    <div>
      <div className="modal fade" id="productAction" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{base.i18n.t('add_address')}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group col-12 col-md-6">
                <label>{base.i18n.t('product')}</label>
                <div>
                  <button className="btn btn-primary" data-toggle="modal" data-target="#discountChooseProduct">{base.i18n.t('choose_product')}</button>
                  {
                    product.id != null &&
                    <div>
                      <p className="m-0">{base.i18n.t('name')}: {product.name}</p>
                      <p className="m-0">{base.i18n.t('category')}: {product.category.name}</p>
                    </div>
                  }
                </div>
              </div>
              {
                product.id != null &&
                <div className="form-group col-12 col-md-6">
                  <label>{base.i18n.t('product_variant')}</label>
                  <div>
                    <span>
                      <button className="btn btn-primary mr-3 mb-1 mb-lg-0" data-toggle="modal" data-target="#discountChooseProductFinal">{base.i18n.t('choose_product_variant')}</button>
                      {
                        product_final.id != null &&
                        <button className="btn btn-danger" onClick={() => on_clear_product_variant()}>{base.i18n.t('clear_product_variant')}</button>
                      }
                    </span>
                    {
                      product_final.id != null &&
                      <div>
                        <p className="m-0">{base.i18n.t('name')}: {product_final.product_name}</p>
                        <p className="m-0">{base.i18n.t('price')}: Rp. {product_final.price.toLocaleString(base.locale_string)}</p>
                        <p className="m-0">{base.i18n.t('stock')}: {product_final.inventory.amount.toLocaleString(base.locale_string)} Pcs</p>
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">{base.i18n.t('close')}</button>
              <button type="button" className="btn btn-primary" onClick={() => submit()}>{base.i18n.t('submit')}</button>
            </div>
          </div>
        </div>
      </div>

      <DiscountChooseProduct on_choose={data => on_choose_product(data)} product={product}/>
      {
        product.id != null &&
        <DiscountChooseProductFinal
          on_choose={data => on_choose_product_final(data)}
          product_final={product_final}
          product={product}/>
      }
    </div>
  );
}
