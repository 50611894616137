import { useEffect, useState } from 'react';
import queryString from "query-string";
import moment from 'moment'

import Base from '../../../utils/base'

export default function OrderCourierOverview(props) {
  var base = new Base()

  return (
    <div className="card">
      <div className="card-header">
        {base.i18n.t('courier_overview')}
      </div>
      <div className="card-body">
        <div className="form-row">
          <div className="form-group col-12 col-md-6">
            <label>{base.i18n.t('courier_name')}</label>
            <input type="text" disabled className="form-control" value={props.data.courier.name + " (" + props.data.courier.code + ")"}/>
          </div>
          <div className="form-group col-12 col-md-6">
            <label>{base.i18n.t('courier_packet_name')}</label>
            <input type="text" disabled className="form-control" value={props.data.packet}/>
          </div>
          <div className="form-group col-12 col-md-6">
            <label>{base.i18n.t('courier_packet_detail')}</label>
            <input type="text" disabled className="form-control" value={props.data.packet_detail}/>
          </div>
          <div className="form-group col-12 col-md-6">
            <label>{base.i18n.t('fee')}</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">IDR</div>
              </div>
              <input type="text" disabled className="form-control" value={props.data.shipping_fee.toLocaleString(base.locale_string)}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
