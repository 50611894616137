import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import CountryPage from '../../pages/master/country/index'
import CountryActionPage from '../../pages/master/country/action'

export default function CountryRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={CountryPage} />
      <Route exact path={`${props.match.path}/action`} component={CountryActionPage} />
    </>
  )
}
