import { useEffect, useState } from 'react';
import queryString from "query-string";
import moment from 'moment'

import Base from '../../../utils/base'

export default function OrderUser(props) {
  var base = new Base()

  return (
    <div className="card">
      <div className="card-header">
        {base.i18n.t('user_info')}
      </div>
      <div className="card-body row">
        <div className="form-group col-12 col-md-6">
          <label>{base.i18n.t('name')}</label>
          <input type="text" disabled className="form-control" value={props.data.user.name}/>
        </div>
        <div className="form-group col-12 col-md-6">
          <label>{base.i18n.t('phone')}</label>
          <input type="text" disabled className="form-control" value={props.data.user.phone}/>
        </div>
        <div className="form-group col-12 col-md-6">
          <label>{base.i18n.t('email')}</label>
          <input type="text" disabled className="form-control" value={props.data.user.email}/>
        </div>
      </div>
    </div>
  );
}
