import { useEffect, useState } from 'react';
import queryString from "query-string";
import moment from 'moment'

import Base from '../../../utils/base'

export default function OrderOverview(props) {
  var base = new Base()

  return (
    <div className="card">
      <div className="card-header">
        {base.i18n.t('order_overview')}
      </div>
      <div className="card-body">
        <div className="form-row">
          <div className="form-group col-12 col-md-6">
            <label>{base.i18n.t('date')}</label>
            <input type="text" disabled className="form-control" value={moment(props.data.date).format('DD MMMM YYYY')}/>
          </div>
          <div className="form-group col-12 col-md-6">
            <label>{base.i18n.t('order_id')}</label>
            <input type="text" disabled className="form-control" value={props.data.id}/>
          </div>
          <div className="form-group col-12 col-md-6">
            <label>{base.i18n.t('city')}</label>
            <input type="text" disabled className="form-control" value={props.data.city.name}/>
          </div>
          <div className="form-group col-12 col-md-6">
            <label>{base.i18n.t('currency')}</label>
            <input type="text" disabled className="form-control" value={props.data.currency.name}/>
          </div>
          <div className="form-group col-12 col-md-6">
            <label>{base.i18n.t('status')}</label>
            <input type="text" disabled className="form-control" value={base.i18n.t(props.data.status)}/>
          </div>
          <div className="form-group col-12">
            <label>{base.i18n.t('notes')}</label>
            <textarea disabled className="form-control" value={props.data.notes != null && props.data.notes !== '-' ? props.data.notes : '-'}></textarea>
          </div>
          {
            props.data.status === 'complained' ?
            <div className="form-group col-12">
              <label>{base.i18n.t('complain_message')}</label>
              <textarea disabled className="form-control" value={props.data.complain_text}></textarea>
            </div>
            :
            <></>
          }
        </div>
      </div>
    </div>
  );
}
