import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import CategoryPage from '../../pages/master/category/index'
import CategoryActionPage from '../../pages/master/category/action'

export default function CategoryRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={CategoryPage} />
      <Route exact path={`${props.match.path}/action`} component={CategoryActionPage} />
    </>
  )
}
