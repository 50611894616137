import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2'
import moment from 'moment'

import Base from '../../../../utils/base'
import CustomTable from '../../../../layout/custom_table/index'

export default function CountRanking() {
  var base = new Base()
  const [arr, set_arr] = useState([])
  const [sort] = useState([
    {
      name: 'total_order',
      sort: 'desc',
    },
  ])
  const [arr_header, set_arr_header] = useState([
    {
      label: base.i18n.t('ranking'),
      data: 'ranking',
      style: {
        width: '10%',
        cursor: 'pointer',
      }
    },
    {
      label: base.i18n.t('product'),
      data: 'product_name',
    },
    {
      label: base.i18n.t('total_order'),
      data: 'total_order',
    },
  ])

  function on_update_data(arr){
    arr.forEach((data, index) => {
      data.total_order = data.total_order.toLocaleString(base.locale_string)
      data.product_name = data.name
      data.ranking = index + 1
    })
    set_arr(arr)
  }

  function on_download(){
    window.print()
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-print-none mb-3">
          <button className="btn btn-primary mr-3" onClick={() => on_download()}>{base.i18n.t('download')}</button>
        </div>
        <CustomTable
          url='/report/ranking/count'
          arr_header={arr_header}
          include_sort={false}
          arr={arr}
          on_update_data={data => on_update_data(data)}/>
      </div>
    </div>
  );
}
