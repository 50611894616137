import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../../utils/base'
import SalesInfo from './variant_component/sales_info'
import VariantList from './variant_component/variant_list'
import VariantPrice from './variant_component/variant_price'

export default function Variant(props) {
  var base = new Base()

  return (
    <div>
      <div className="custom-control custom-switch mb-3">
        <input type="checkbox" className="custom-control-input" id="customSwitch1" checked={props.is_variant_enable} disabled={props.data_product.id != null} onChange={() => props.onChangeVariantEnable()}/>
        <label className="custom-control-label" htmlFor="customSwitch1">{base.i18n.t('variant_toggle')}</label>
      </div>
      {
        !props.is_variant_enable ?
        <div>
          <SalesInfo
            sku={props.sku}
            price={props.price}
            stock={props.stock}
            currency={props.currency}
            data_product={props.data_product}
            onChange={(key, value) => props.onChange(key, value)}/>
        </div>
        :
        <div>
          <div className="mb-3">
            <VariantList
              arr_variant={props.arr_variant}
              data_product={props.data_product}
              currency={props.currency}
              onChangeNameVariant={(index, value) => props.onChangeNameVariant(index, value)}
              onAddPhotoVariant={(index, arr) => props.onAddPhotoVariant(index, arr)}
              onDeletePhotoVariant={(index, index1) => props.onDeletePhotoVariant(index, index1)}
              onChangePrimaryPhotoVariant={(index, index1) => props.onChangePrimaryPhotoVariant(index, index1)}
              onChangeNameSubVariant={(index, index1, value) => props.onChangeNameSubVariant(index, index1, value)}
              onAddSubVariant={index => props.onAddSubVariant(index)}
              onDeleteSubVariant={(index, index1) => props.onDeleteSubVariant(index, index1)}
              onAddVariant={() => props.onAddVariant()}
              onDeleteVariant={index => props.onDeleteVariant(index)}/>
          </div>
          <div className="mb-3">
            <VariantPrice
              arr_variant_price={props.arr_variant_price}
              data_product={props.data_product}
              currency={props.currency}
              onChangePrice={(index, value) => props.onChangePriceSubVariant(index, value)}
              onChangeSku={(index, value) => props.onChangeSkuSubVariant(index, value)}
              onChangeStock={(index, value) => props.onChangeStockSubVariant(index, value)}
              onChangeCurrency={(index, value) => props.onChangeCurrencySubVariant(index, value)}
              onAddPhotoFinal={(index, arr) => props.onAddPhotoFinal(index, arr)}
              onDeletePhotoFinal={(index, index1) => props.onDeletePhotoFinal(index, index1)}
              onChangePrimaryPhotoFinal={(index, index1) => props.onChangePrimaryPhotoFinal(index, index1)}/>
          </div>
        </div>
      }

    </div>
  );
}
