import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../../../utils/base'

export default function VariantItem(props) {
  var base = new Base()

  function on_upload_image(e){
    var temp_arr = []
    var temp_image_arr = []
    Array.from(e.target.files).forEach((data, index) => {
      temp_image_arr.push(data)

      var image = URL.createObjectURL(data)
      var data1 = {}
      data1.file_name = image
      data1.is_primary = false

      base.getBase64(data, result => {
        data1.image = {
          image: result
        }
      })

      temp_arr.push(data1)
    })
    props.onAddPhotoVariant(temp_arr)
  }

  return (
    <div className="card">
      <div className="card-header d-flex align-items-center justify-content-between">
        <h5 className="m-0">{props.data.name === '' ? base.i18n.t('variant') + " " + (props.index + 1) : props.data.name}</h5>
        {
          props.index > 0 ?
          <button className="btn btn-danger" onClick={() => props.onDeleteVariant()}>{base.i18n.t('delete')}</button>
          :
          <></>
        }

      </div>
      <div className="card-body">
        <div className="form-group">
          <label>{base.i18n.t('name')}</label>
          <input type="text" className="form-control" onChange={e => props.onChangeNameVariant(e.target.value)} value={props.data.name}/>
        </div>
        <div className="form-group d-none">
          <label>{base.i18n.t('photo')}</label>
          <div>
            <input type="file" id={"inputFileVariant" + props.index} className="d-none" multiple onChange={e => on_upload_image(e)}/>
            <button type="button" className="btn btn-primary" onClick={() => window.$('#inputFileVariant' + props.index).trigger('click')}>{base.i18n.t('upload_file')}</button>
          </div>
          <div className="row mt-3">
            {
              props.data.arr_photo.map((photo, index) => (
                <div className="col-6" key={index}>
                  <img src={photo.file_name} width="100%" height="150rem"/>
                  {
                    photo.is_primary ?
                    <div className="w-100 mt-3 text-center">
                      {base.i18n.t('as_primary')}
                    </div>
                    :
                    <div className="w-100 mt-3">
                      <button className="btn btn-primary w-100" onClick={() => props.onChangePrimaryPhotoVariant(index)}>{base.i18n.t('set_primary')}</button>
                    </div>
                  }

                  <div className="w-100 mt-3">
                    <button className="btn btn-danger w-100" onClick={() => props.onDeletePhotoVariant(index)}>{base.i18n.t('delete')}</button>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
        <div className="form-group mt-3">
          <label>{base.i18n.t('option')}</label>
          {
            props.data.arr_sub_variant.map((sub_variant, index) => (
              <div className="mb-3 row align-items-center" key={index}>
                <div className="col">
                  <label>{base.i18n.t('name')}</label>
                  <input type="text" className="form-control" onChange={e => props.onChangeNameSubVariant(index, e.target.value)} value={sub_variant.name} placeholder={base.i18n.t('option') + " " + (index + 1)}/>
                </div>
                <div className="col-auto">
                  {
                    index > 0 ?
                    <button className="btn btn-danger" onClick={() => props.onDeleteSubVariant(index)}>{base.i18n.t('delete')}</button>
                    :
                    <button className="btn btn-danger" disabled>{base.i18n.t('delete')}</button>
                  }
                </div>
              </div>
            ))
          }
          <div className="w-100 mt-3">
            <button className="btn btn-primary w-100" onClick={() => props.onAddSubVariant()}>{base.i18n.t('add_option')}</button>
          </div>
        </div>
      </div>
    </div>
  );
}
