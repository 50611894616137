import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../../utils/base'

export default function ExchangeAction(props) {
  var base = new Base()
  const [data, set_data] = useState({})
  const [price, set_price] = useState('0')
  const [currency, set_currency] = useState({})
  const [query, set_query] = useState({})

  useEffect(() => {
    var query1 = queryString.parse(props.location.search)
    set_query(query1)

    if(query1.id != null)
      get_data()

    get_currency_data()
  }, [])

  useEffect(() => {
    // console.log(isNaN(price))
    base.to_currency_format(price, set_price)
  }, [price])

  function get_currency_data() {
    base.request(base.host + '/currency/all?name=USD').then(response => {
      if(response != null && response.status === 'success'){
        set_currency(response.data[0])
      }
    })
  }

  function get_data() {
    var query1 = queryString.parse(props.location.search)
    base.request(base.host + '/exchange?id=' + query1.id)
      .then(response => {
        if(response != null && response.status === 'success'){
          set_price(response.data.price.toLocaleString(base.locale_string))
          set_data(response.data)
        }
      })
  }

  function submit() {
    if(price === '0')
      base.notify_user(base.i18n.t('price_empty'))
    else{
      var temp = {
        price: parseFloat(price.replace(/\./g, '')),
        currency: currency,
      }

      // if(data.id != null)
      //   temp.id = data.id

      base.request(base.host + '/exchange', 'post', temp, 'application/json').then(response => {
        if(response != null && response.status === 'success'){
          window.location.href = "/exchange"
        }
      })
    }
  }


  return (
    <div>
      <h3>{base.i18n.t('add_exchange')}</h3>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="form-group">
                <label>1 {currency.name} =</label>
                <input type="text" className="form-control" onChange={e => set_price(e.target.value)} value={price}/>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-3">
          <button className="btn btn-primary" onClick={() => submit()}>{base.i18n.t('submit')}</button>
        </div>
      </div>
    </div>
  );
}
