import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../../../utils/base'
import CustomTable from '../../../../layout/custom_table/index'

export default function DiscountChooseProductFinal(props) {
  var base = new Base()
  const [arr_header, set_arr_header] = useState([
    {
      label: base.i18n.t('name'),
      data: 'product_name',
    },
    {
      label: base.i18n.t('price'),
      data: 'price_format',
    },
    {
      label: base.i18n.t('stock'),
      data: 'stock_format',
    },
  ])
  const [arr, set_arr] = useState([])

  function on_update_data(arr){
    arr.forEach((data, index) => {
      data.price_format = "Rp. " + data.price.toLocaleString(base.locale_string)
      data.stock_format = data.inventory.amount.toLocaleString(base.locale_string) + ' Pcs'
    })
    set_arr(arr)
  }

  function on_action_clicked(index){
    props.on_choose(arr[index])
    window.$('#discountChooseProductFinal').modal('hide')
  }

  return (
    <div className="modal fade" id="discountChooseProductFinal" data-keyboard="false" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">{base.i18n.t('choose_product_variant')}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <CustomTable
              url={'/product-final?product_id=' + props.product.id}
              arr_header={arr_header}
              arr={arr}
              on_action_clicked={index => on_action_clicked(index)}
              text_action={base.i18n.t('choose')}
              on_update_data={data => on_update_data(data)}/>
          </div>
        </div>
      </div>
    </div>
  );
}
