import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import ProductPage from '../pages/product/index'
import ProductActionPage from '../pages/product/action'

export default function ProductRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={ProductPage} />
      <Route exact path={`${props.match.path}/action`} component={ProductActionPage} />
    </>
  )
}
