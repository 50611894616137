import { useEffect, useState } from 'react';
import moment from 'moment'
import axios from 'axios'

import Base from '../../utils/base'

export default function Login() {
  var base = new Base()
  const [email, set_email] = useState('')
  const [password, set_password] = useState('')

  useEffect(() => {
    base.limit_data_length(email, set_email)
    base.limit_data_length(password, set_password)
  }, [email, password,])

  function login_action() {
    if(email === '')
      base.notify_user(base.i18n.t('email_empty'))
    else if(password === '')
      base.notify_user(base.i18n.t('password_empty'))
    else{
      var data = {}
      data.email = email
      data.password = password
      data.type = 'web_admin'

      base.request(base.host+'/auth/login', 'post', data).then(response => {
        if(response != null && response.status === 'success'){
          window.localStorage.setItem('token', response.token)
          window.localStorage.setItem('token_expired', moment().add(3, 'hours').format('YYYY-MM-DD HH:mm:ss'))
          var destination_path = window.localStorage.getItem('destination_path')
          window.location.href = destination_path != null && destination_path != '' ? destination_path : "/"
        }
      })
    }
  }

  function on_key_up(e){
    if(e.keyCode === 13)
      login_action()
  }

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="p-5">
                <div className="text-center">
                  <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                </div>
                <form className="user">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control form-control-user"
                      id="exampleInputEmail"
                      aria-describedby="emailHelp"
                      onChange={e => set_email(e.target.value)}
                      onKeyUp={e => on_key_up(e)}
                      value={email}
                      placeholder={base.i18n.t('enter_email')}/>
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control form-control-user"
                      id="exampleInputPassword"
                      onChange={e => set_password(e.target.value)}
                      value={password}
                      onKeyUp={e => on_key_up(e)}
                      placeholder={base.i18n.t('enter_password')}/>
                  </div>
                  <button type="button" onClick={() => login_action()} className="btn btn-primary btn-user btn-block">
                    {base.i18n.t('login')}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
