import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import ProvincePage from '../../pages/master/province/index'
import ProvinceActionPage from '../../pages/master/province/action'

export default function ProvinceRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={ProvincePage} />
      <Route exact path={`${props.match.path}/action`} component={ProvinceActionPage} />
    </>
  )
}
