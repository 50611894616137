import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import BlogStorePolicyPage from '../../pages/master/blog_content/store_policy/index'
import BlogStorePolicyActionPage from '../../pages/master/blog_content/store_policy/action'

export default function BlogStorePolicyRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={BlogStorePolicyPage} />
      <Route exact path={`${props.match.path}/action`} component={BlogStorePolicyActionPage} />
    </>
  )
}
