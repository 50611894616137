import { useEffect, useState } from 'react';

import Base from '../../../utils/base'
import CustomTable from '../../../layout/custom_table/index'

export default function Category() {
  var base = new Base()
  const [arr_header, set_arr_header] = useState([
    {
      label: base.i18n.t('name'),
      data: 'name',
    },
    {
      label: base.i18n.t('title'),
      data: 'title',
    },
  ])
  const [arr, set_arr] = useState([])

  function on_update_data(arr){
    arr.forEach((data, index) => {
      data.title = data.title === null || data.title === '' ? '-' : data.title
    })
    set_arr(arr)
  }

  return (
    <div>
      <h3>{base.i18n.t('blog_type')}</h3>
      <CustomTable
        url='/blog/home/type'
        arr_header={arr_header}
        arr={arr}
        url_update='/blog/type/action'
        on_update_data={data => on_update_data(data)}/>
    </div>
  );
}
