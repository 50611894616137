import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import BannerPage from '../../pages/master/banner/index'

export default function BannerRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={BannerPage} />
    </>
  )
}
