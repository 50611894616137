import { useEffect, useState } from 'react';
import moment from 'moment'

import Base from '../../utils/base'
import CustomTable from '../../layout/custom_table/index'

export default function User() {
  var base = new Base()
  const [arr_header, set_arr_header] = useState([
    {
      label: base.i18n.t('email'),
      data: 'email',
    },
    {
      label: base.i18n.t('name'),
      data: 'name',
    },
    {
      label: base.i18n.t('phone'),
      data: 'phone',
    },
    {
      label: base.i18n.t('register_at'),
      data: 'created_at',
    },
  ])
  const [arr, set_arr] = useState([])

  function on_update_data(arr){
    base.reset_localstorage()
    arr.forEach((data, index) => {
      if(data.file_name != null)
        data.file_name = base.host + '/image/user?file_name=' + data.file_name
      data.created_at = moment(data.created_at).format('DD MMMM YYYY HH:mm')
    })
    set_arr(arr)
  }

  return (
    <div>
      <h3>{base.i18n.t('user_admin')}</h3>
      <CustomTable
        url='/user?type=admin_only'
        arr_header={arr_header}
        arr={arr}
        url_update='/user-admin/action'
        url_create='/user-admin/action'
        url_delete='/user'
        on_update_data={data => on_update_data(data)}/>
    </div>
  );
}
