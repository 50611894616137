import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import BlogPaymentMethodPage from '../../pages/master/blog_content/payment_method/index'
import BlogPaymentMethodActionPage from '../../pages/master/blog_content/payment_method/action'

export default function BlogPaymentMethodRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={BlogPaymentMethodPage} />
      <Route exact path={`${props.match.path}/action`} component={BlogPaymentMethodActionPage} />
    </>
  )
}
