import { useEffect, useState } from 'react'
import moment from 'moment'
import Base from '../utils/base'

import no_profile_picture from "../assets/no_profile_picture.png"

export default function Navbar() {
  var base = new Base()
  const [data, set_data] = useState({
    email: '',
    name: '',
    phone: '',
    arr_notification: [],
  })

  useEffect(() => get_data(), [])

  function get_data() {
    base.request(base.host + '/auth/profile')
    .then(response => {
      if(response != null && response.status === 'success'){
        response.data.file_name = response.data.file_name != null ? base.host + '/image/user?file_name=' + response.data.file_name : ''
        for(let notification of response.data.arr_notification)
          notification.date = moment(notification.created_at)
        set_data(response.data)
      }
    })
  }

  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow d-print-none">

      <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
        <i className="fa fa-bars"></i>
      </button>

      <ul className="navbar-nav ml-auto">
        <li class="nav-item dropdown no-arrow mx-1">
          <a class="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-bell fa-fw"></i>
          </a>
          <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="alertsDropdown">
            <h6 class="dropdown-header">
              {base.i18n.t('notification')}
            </h6>
            {
              data.arr_notification.length > 0 ?
              data.arr_notification.map((notification, index) => (
                <a class="dropdown-item d-flex align-items-center" href="#">
                  <div class="mr-3">
                    <div class="icon-circle bg-primary">
                      <i class="fas fa-file-alt text-white"></i>
                    </div>
                  </div>
                  <div>
                    <div class="small text-gray-500">{notification.date.format('DD MMMM YYYY')}</div>
                    <span class="font-weight-bold">{notification.title}</span>
                  </div>
                </a>
              ))
              :
              <div class="dropdown-item text-center small text-gray-500">{base.i18n.t('no_data_found')}</div>
            }
          </div>
        </li>

        <div className="topbar-divider d-none d-sm-block"></div>

        <li className="nav-item dropdown no-arrow">
          <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span className="mr-2 d-none d-lg-inline text-gray-600 small">{data.name}</span>
            <img className="img-profile rounded-circle" src={data.file_name != null && data.file_name != '' ? data.file_name : no_profile_picture}/>
          </a>
          <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="userDropdown">
            <a className="dropdown-item" href="/auth/profile">
              <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
              {base.i18n.t('profile')}
            </a>
            <a className="dropdown-item" href="/auth/change-password">
              <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
              {base.i18n.t('change_password')}
            </a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutConfirmationModal">
              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
              {base.i18n.t('logout')}
            </a>
          </div>
        </li>
      </ul>
    </nav>
  );
}
