import { useEffect, useState } from 'react';
import moment from 'moment'

import Base from '../utils/base'

export default function NotFound() {
  var base = new Base()

  return (
    <div>
      <h3>Not Found</h3>
    </div>
  );
}
