import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2'
import moment from 'moment'

import Base from '../../../../utils/base'
import CustomTable from '../../../../layout/custom_table/index'

export default function SalesProductViewed() {
  var base = new Base()
  const [arr, set_arr] = useState([])
  const [sort] = useState([
    {
      name: 'created_at',
      sort: 'desc',
    },
  ])
  const [arr_header, set_arr_header] = useState([
    {
      label: base.i18n.t('name'),
      data: 'name',
    },
    {
      label: base.i18n.t('viewer'),
      data: 'viewer',
    },
  ])

  function on_update_data(arr){
    arr.forEach((data, index) => {
      data.viewer = data.total_visit
    })
    set_arr(arr)
  }

  return (
    <div className="card">
      <div className="card-header">
        <h5>{base.i18n.t('product_viewed')}</h5>
      </div>
      <div className="card-body">
        <CustomTable
          url={'/product'}
          arr_header={arr_header}
          arr={arr}
          on_update_data={data => on_update_data(data)}/>
      </div>
    </div>
  );
}
