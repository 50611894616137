import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../utils/base'

export default class ProductActionHelper {
  base = new Base()

  on_change_photo(arr_photo, arr) {
    var arr_temp = JSON.parse(JSON.stringify(arr_photo))
    arr.forEach((data, index) => {
      if(arr_temp.length == 0)
        data.is_primary = true
      arr_temp.push(data)
    })
    return arr_temp
  }

  on_change_primary_photo(arr_photo, index) {
    var arr_temp = JSON.parse(JSON.stringify(arr_photo))
    arr_temp.forEach((data, index) => {
      data.is_primary = false
    })
    arr_temp[index].is_primary = true
    return arr_temp
  }

  on_delete_photo(arr_photo, index){
    var arr_temp = JSON.parse(JSON.stringify(arr_photo))
    var is_primary = arr_temp[index].is_primary
    arr_temp.splice(index, 1)

    if(is_primary){
      arr_temp[0].is_primary = true
    }

    return arr_temp
  }

  on_change_name_variant(arr_variant, index, value){
    var arr_temp = JSON.parse(JSON.stringify(arr_variant))
    arr_temp[index].name = this.base.limit_data_length(value)
    return arr_temp
  }

  on_add_photo_variant(arr_variant, index, arr){
    var arr_temp = JSON.parse(JSON.stringify(arr_variant))
    var arr_photo_temp = arr_temp[index].arr_photo
    arr.forEach((data, index) => {
      if(arr_photo_temp.length == 0)
        data.is_primary = true
      arr_photo_temp.push(data)
    })
    return arr_temp
  }

  on_delete_photo_variant(arr_variant, index, index1){
    var arr_temp = JSON.parse(JSON.stringify(arr_variant))
    var is_primary = arr_temp[index].arr_photo[index1].is_primary
    arr_temp[index].arr_photo.splice(index1, 1)

    if(is_primary){
      arr_temp[index].arr_photo[0].is_primary = true
    }

    return arr_temp
  }

  on_change_primary_photo_variant(arr_variant, index, index1){
    var arr_temp = JSON.parse(JSON.stringify(arr_variant))
    var arr_photo_temp = arr_temp[index].arr_photo
    arr_photo_temp.forEach((data, index) => {
      data.is_primary = false
    })
    arr_photo_temp[index1].is_primary = true
    return arr_temp
  }

  on_change_name_sub_variant(arr_variant, index, index1, value){
    var arr_temp = JSON.parse(JSON.stringify(arr_variant))
    arr_temp[index].arr_sub_variant[index1].name = this.base.limit_data_length(value)
    return arr_temp
  }

  manage_arr_variant_price(arr_variant_price, arr_temp){
    var arr_index = this.base.get_array_index_sub_variant(arr_temp)
    var arr_price = []
    var arr_variant_price_temp = JSON.parse(JSON.stringify(arr_variant_price))

    arr_index.forEach((data, index) => {
      var variant_name = ''
      var id_str = ''
      data.forEach((data1, index1) => {
        variant_name += arr_temp[index1].name + " " + arr_temp[index1].arr_sub_variant[data1].name + " "
        id_str += data1
      })

      var ind = null
      arr_variant_price_temp.forEach((variant_price, index2) => {
        if(id_str === variant_price.index){
          ind = index2
          return
        }
      })

      var arr_sub_variant_temp1 = []
      data.forEach((data1, index1) => {
        var ind1 = null
        if(ind != null)
          arr_variant_price_temp[ind].arr_sub_variant.forEach((variant_price, index2) => {
            if(variant_price.product_sub_variant != null && arr_temp[index1].arr_sub_variant[data1].id === variant_price.product_sub_variant.id){
              ind1 = index2
              return
            }
          })

        var arr_temp1 = ind != null && ind1 != null ? arr_variant_price_temp[ind].arr_sub_variant[ind1] : {}
        arr_temp1.name = arr_temp[index1].arr_sub_variant[data1].name
        arr_sub_variant_temp1.push(arr_temp1)
      })

      var arr_temp1 = ind != null ? arr_variant_price_temp[ind] : {}
      arr_temp1.index = ind != null ? arr_variant_price_temp[ind].index : id_str
      arr_temp1.variant_name = variant_name
      arr_temp1.arr_sub_variant = arr_sub_variant_temp1
      arr_temp1.price = ind != null ? arr_variant_price_temp[ind].price : '0'
      arr_temp1.sku = ind != null ? arr_variant_price_temp[ind].sku : ''
      arr_temp1.stock = ind != null ? arr_variant_price_temp[ind].stock : '0'
      arr_temp1.currency = ind != null ? arr_variant_price_temp[ind].currency : {id: 'CURRENCY_0001', name: 'IDR'}
      arr_temp1.arr_photo = ind != null ? arr_variant_price_temp[ind].arr_photo : []

      arr_price.push(arr_temp1)
    })
    return arr_price
  }

  on_change_price_sub_variant(arr_variant_price, index, value){
    var arr_temp = JSON.parse(JSON.stringify(arr_variant_price))
    arr_temp[index].price = this.base.to_currency_format(this.base.limit_data_length(value))
    return arr_temp
  }

  on_change_sku_sub_variant(arr_variant_price, index, value){
    var arr_temp = JSON.parse(JSON.stringify(arr_variant_price))
    arr_temp[index].sku = this.base.limit_data_length(value)
    return arr_temp
  }

  on_change_stock_sub_variant(arr_variant_price, index, value){
    var arr_temp = JSON.parse(JSON.stringify(arr_variant_price))
    arr_temp[index].stock = this.base.to_currency_format(this.base.limit_data_length(value))
    return arr_temp
  }

  on_change_currency_sub_variant(arr_variant_price, index, value){
    var arr_temp = JSON.parse(JSON.stringify(arr_variant_price))
    arr_temp[index].currency = value
    return arr_temp
  }

  on_add_sub_variant(arr_variant, index){
    var arr_temp = JSON.parse(JSON.stringify(arr_variant))
    arr_temp[index].arr_sub_variant.push({
      name: '',
    })
    return arr_temp
  }

  on_delete_sub_variant(arr_variant, index, index1){
    var arr_temp = JSON.parse(JSON.stringify(arr_variant))
    arr_temp[index].arr_sub_variant.splice(index1, 1)
    return arr_temp
  }

  check_arr_variant(arr_variant){
    var flag = true
    var arr_temp = JSON.parse(JSON.stringify(arr_variant))
    arr_temp.forEach((temp, index) => {
      if(temp.name === ''){
        this.base.notify_user(this.base.i18n.t("variant") + " " + (index + 1) + " " + this.base.i18n.t("name_empty"))
        flag = false
        return
      }

      var counter = 0
      temp.arr_sub_variant.forEach((sub_variant, index1) => {
        if(sub_variant.name === ''){
          this.base.notify_user(this.base.i18n.t("option") + " " + (index1 + 1) + " " + this.base.i18n.t("name_empty"))
          return
        }
        counter++
      })
      if(counter < temp.arr_sub_variant.length){
        flag = false
        return
      }
    })
    return flag
  }

  check_arr_variant_price(arr_variant_price){
    var flag = true
    var arr_temp = JSON.parse(JSON.stringify(arr_variant_price))
    arr_temp.forEach((temp, index) => {
      if(temp.price === '0'){
        this.base.notify_user(this.base.i18n.t("variant") + " " + temp.variant_name + " " + this.base.i18n.t("price_empty"))
        flag = false
        return
      }
      // else if(temp.stock === '0'){
      //   this.base.notify_user(this.base.i18n.t("variant") + " " + temp.variant_name + " " + this.base.i18n.t("stock_empty"))
      //   flag = false
      //   return
      // }
      else if(temp.arr_photo.length === 0){
        this.base.notify_user(this.base.i18n.t("variant") + " " + temp.variant_name + " " + this.base.i18n.t("photo_empty"))
        flag = false
        return
      }

      // var counter = 0
      // temp.arr_photo.forEach((photo, index) => {
      //   if(!photo.is_publish)
      //     counter++
      // })
      // if(counter === temp.arr_photo.length)
      //   temp.arr_photo[0].is_publish = true
    })
    return flag
  }
}
