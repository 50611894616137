import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../../utils/base'

export default function AddInventory(props) {
  var base = new Base()
  const [amount, set_amount] = useState('0')
  const [description, set_description] = useState('')

  useEffect(() => {
    base.to_currency_format(amount, set_amount)
  }, [amount])

  function submit() {
    if(amount === '0')
      base.notify_user(base.i18n.t('amount_empty'))
    else{
      var temp = {
        amount: parseFloat(amount.replace(/\./g, '')),
        inventory: props.inventory,
        description: description,
      }

      base.request(base.host + '/inventory/manual/' + props.type, 'post', temp).then(response => {
        if(response != null && response.status === 'success'){
          window.$('#manualAddInventory').modal('hide')
          props.on_submit()
        }
      })
    }
  }

  return (
    <div className="modal fade" id="manualAddInventory" data-keyboard="false" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">{base.i18n.t(props.type)}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label>{base.i18n.t('amount')}</label>
              <input type="text" className="form-control" onChange={e => set_amount(e.target.value)} value={amount}/>
            </div>
            <div className="form-group">
              <label>{base.i18n.t('description')}</label>
              <textarea className="form-control" onChange={e => set_description(e.target.value)} value={description}></textarea>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-primary" onClick={() => submit()}>{base.i18n.t('submit')}</button>
          </div>
        </div>
      </div>
    </div>
  );
}
