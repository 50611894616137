import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import ExchangePage from '../../pages/master/exchange/index'
import ExchangeActionPage from '../../pages/master/exchange/action'

export default function ExchangeRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`}component={ExchangePage} />
      <Route exact path={`${props.match.path}/action`} component={ExchangeActionPage} />
    </>
  )
}
