import { useEffect, useState } from 'react';
import moment from 'moment'
import { Line } from 'react-chartjs-2'

import Base from '../../../utils/base'

export default function MyBusiness() {
  var base = new Base()
  const [arr, set_arr] = useState([])
  const [arr_overview, set_arr_overview] = useState([
    {
      id: 'total_viewer',
      title: base.i18n.t('total_viewer'),
      data: 0,
    },
    {
      id: 'product_viewed',
      title: base.i18n.t('product_viewed'),
      data: 0,
    },
    {
      id: 'order',
      title: base.i18n.t('order'),
      data: 0,
    },
    {
      id: 'conversion_rate',
      title: base.i18n.t('conversion_rate'),
      data: 0,
    },
  ])
  const [data, set_data] = useState({
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [{
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
    }]
  })
  const [options, set_options] = useState({
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  })

  useEffect(() => {
    get_sales_data()
    get_data()
  }, [])

  useEffect(() => {
    var arr_labels = manage_label()
    var arr_temp = manage_dataset(arr_labels)
    var temp = {
      labels: arr_labels,
      datasets: [{
        label: base.i18n.t('sales'),
        data: arr_temp,
      }]
    }
    set_data(temp)
  }, [arr])

  function manage_label(){
    var arr_labels = []
    arr.forEach((data1, index1) => {
      var counter = 0
      arr_labels.forEach((label, index2) => {
        if(label === moment(data1.created_at).format('DD/MM/YYYY'))
          return
        counter++
      })
      if(counter === arr_labels.length)
        arr_labels.push(moment(data1.created_at).format('DD/MM/YYYY'))
    })
    return arr_labels
  }

  function manage_dataset(arr_labels){
    var arr_temp = []
    arr_labels.forEach((label, index1) => {
      var total = 0
      arr.forEach((data2, index2) => {
        if(label === moment(data2.created_at).format('DD/MM/YYYY'))
          total += data2.grand_total
      })
      arr_temp.push(total)
    })
    return arr_temp
  }

  function get_data() {
    var sort = [
      {
        name: 'created_at',
        sort: 'desc',
      },
    ]
    base.request(base.host + '/order?sort=' + JSON.stringify(sort)).then(response => {
      if(response != null && response.status === 'success'){
        set_arr(response.data.data)
      }
    })
  }

  function get_sales_data() {
    base.request(base.host + '/report/overview/sales').then(response => {
      if(response != null && response.status === 'success'){
        var arr_temp = JSON.parse(JSON.stringify(arr_overview))
        response.data.forEach((data, index) => {
          arr_temp.forEach((data1, index1) => {
            if(data1.id == data.id){
              data1.data = data.data
              return
            }
          })
        })
        set_arr_overview(arr_temp)
      }
    })
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className="mb-3">
          <h5>{base.i18n.t('my_business')}</h5>
          <div>
            {base.i18n.t('today') + ' ' + moment().format('DD/MM/YYYY')}
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 mb-3">
            <h6>{base.i18n.t('sales')}</h6>
            <Line data={data} options={options} />
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <div className="row">
              {
                arr_overview.map((overview, index) => (
                  <div className="col-12 col-lg-6 mb-5" key={index}>
                    <h6>{overview.title}</h6>
                    <h4>{overview.data}</h4>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
