import { useEffect, useState } from 'react';
import moment from 'moment'

import Base from '../../utils/base'
import CustomTable from '../../layout/custom_table/index'

export default function OrderItem(props) {
  var base = new Base()
  const [arr_header, set_arr_header] = useState([
    {
      label: base.i18n.t('order_id'),
      data: 'id',
    },
    {
      label: base.i18n.t('date'),
      data: 'date',
    },
    {
      label: base.i18n.t('user_name'),
      data: 'user_name',
    },
    {
      label: base.i18n.t('address'),
      data: 'address',
    },
    {
      label: base.i18n.t('price'),
      data: 'grand_total',
    },
  ])
  const [arr, set_arr] = useState([])

  function check_header(arr_temp, data_header){
    for(let data of arr_temp){
      if(data_header == data.data)
        return false
    }
    return true
  }

  function on_update_data(arr){
    base.reset_localstorage()
    arr.forEach((data, index) => {
      data.date = moment(data.date).format('DD MMMM YYYY HH:mm')
      data.user_name = data.user.name
      data.status = base.i18n.t(data.status)
      data.grand_total = data.currency.name + ' ' + data.grand_total.toLocaleString(base.locale_string)
      if(props.status === 'shipped')
        data.shipped_at = moment(data.updated_at).format('DD MMMM YYYY HH:mm')
      else if(props.status === 'arrived')
        data.arrived_at = moment(data.updated_at).format('DD MMMM YYYY HH:mm')
      else if(props.status === 'canceled')
        data.canceled_at = moment(data.updated_at).format('DD MMMM YYYY HH:mm')
      else if(props.status === 'accepted')
        data.accepted_at = moment(data.updated_at).format('DD MMMM YYYY HH:mm')
      else if(props.status === 'complained')
        data.complained_at = moment(data.updated_at).format('DD MMMM YYYY HH:mm')
      else if(props.status === 'done')
        data.done_at = moment(data.updated_at).format('DD MMMM YYYY HH:mm')
    })

    var arr_temp = JSON.parse(JSON.stringify(arr_header))
    if(props.status === 'shipped' && check_header(arr_temp, 'shipped_at'))
      arr_temp.push({
        label: base.i18n.t('shipped_date'),
        data: 'shipped_at',
      })
    else if(props.status === 'arrived' && check_header(arr_temp, 'arrived_at'))
      arr_temp.push({
        label: base.i18n.t('arrived_date'),
        data: 'arrived_at',
      })
    else if(props.status === 'canceled' && check_header(arr_temp, 'canceled_at'))
      arr_temp.push({
        label: base.i18n.t('canceled_date'),
        data: 'canceled_at',
      })
    else if(props.status === 'accepted' && check_header(arr_temp, 'accepted_at'))
      arr_temp.push({
        label: base.i18n.t('accepted_date'),
        data: 'accepted_at',
      })
    else if(props.status === 'complained' && check_header(arr_temp, 'complained_at'))
      arr_temp.push({
        label: base.i18n.t('complained_date'),
        data: 'complained_at',
      })
    else if(props.status === 'done' && check_header(arr_temp, 'done_at'))
      arr_temp.push({
        label: base.i18n.t('done_date'),
        data: 'done_at',
      })
      // console.log(arr_temp)
    set_arr_header(arr_temp)
    set_arr(arr)
  }

  return (
    <div>
      <CustomTable
        modal_id={props.status + "ConfirmationModal"}
        url={'/order?status=' + props.status}
        arr_header={arr_header}
        arr={arr}
        current_page_type='multiple'
        current_page_id={props.status}
        url_update='/order/action'
        text_update={base.i18n.t('view')}
        url_delete={props.status === 'wait_payment' || props.status === 'processed' ? '/order' : null}
        text_delete={base.i18n.t('cancel')}
        on_update_data={data => on_update_data(data)}/>
    </div>
  );
}
