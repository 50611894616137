import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2'
import moment from 'moment'

import Base from '../../../utils/base'
import SalesChart from './component/chart'
import SalesListOrder from './component/list_order'
import SalesProductViewed from './component/product_viewed'
import SalesViewer from './component/viewer'

export default function ReportSales() {
  var base = new Base()

  function on_download(){
    window.print()
  }

  return (
    <div>
      <h3>{base.i18n.t('report_sales')}</h3>
      <div className="d-print-none mb-3">
        <button className="btn btn-primary mr-3" onClick={() => on_download()}>{base.i18n.t('download')}</button>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6 mb-3">
          <SalesChart/>
        </div>
        <div className="col-12 col-lg-6 mb-3">
          <SalesViewer/>
        </div>
        <div className="col-12 col-lg-6 mb-3">
          <SalesProductViewed/>
        </div>

        <div className="col-12 mb-3">
          <SalesListOrder/>
        </div>
      </div>
    </div>
  );
}
