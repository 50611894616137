import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../../../utils/base'
import VariantItem from './variant_item'

export default function VariantList(props) {
  var base = new Base()

  return (
    <div>
      <h5 className="mb-3">{base.i18n.t('variant_list')}</h5>
      <div className="row mb-3">
        {
          props.arr_variant.map((variant, index) => (
            <div key={index} className="col-12 col-lg-6 mb-3">
              <VariantItem
                data={variant}
                index={index}
                onChangeNameVariant={value => props.onChangeNameVariant(index, value)}
                onAddPhotoVariant={arr => props.onAddPhotoVariant(index, arr)}
                onDeletePhotoVariant={index1 => props.onDeletePhotoVariant(index, index1)}
                onChangePrimaryPhotoVariant={index1 => props.onChangePrimaryPhotoVariant(index, index1)}
                onChangeNameSubVariant={(index1, value) => props.onChangeNameSubVariant(index, index1, value)}
                onAddSubVariant={() => props.onAddSubVariant(index)}
                onDeleteSubVariant={index1 => props.onDeleteSubVariant(index, index1)}
                onDeleteVariant={() => props.onDeleteVariant(index)}/>
            </div>
          ))
        }
      </div>
      <div>
        <button className="btn btn-primary w-100" onClick={() => props.onAddVariant()} disabled={props.data_product.id != null}>{base.i18n.t('add_variant')}</button>
      </div>
    </div>
  );
}
