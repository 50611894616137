import { useEffect, useState } from 'react';
import queryString from "query-string";
import {DatePicker} from "react-rainbow-components"

import Base from '../../../utils/base'

export default function Info(props) {
  var base = new Base()
  const [base64, set_base64] = useState(null)

  useEffect(() => {
    if(base64 != null){
      var data = {
        image: base64
      }
      props.onChange('image', data)
    }
  }, [base64])

  function on_upload_image(e){
    var image = URL.createObjectURL(e.target.files[0])
    base.getBase64(e.target.files[0], result => {
      set_base64(result)
    })

    props.onChange('file_name', image)
  }

  return (
    <div className="row">
      <div className="col-12 col-lg-4 mb-3">
        <div className="card">
          <div className="card-body">
            <input type="file" className="d-none" id="inputFile" onChange={e => on_upload_image(e)}/>
            <button className="btn btn-primary" onClick={e => window.$('#inputFile').trigger('click')}>{base.i18n.t('upload_file')}</button>
            <div className="mt-3">
              {
                props.file_name != '' ?
                <img src={props.file_name} width="100%"/>
                :
                <></>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-8 mb-3">
        <div className="card">
          <div className="card-body">
            <div className="form-group">
              <label>{base.i18n.t('email')}</label>
              <input type="email" className="form-control" disabled={props.query_url.id != null} onChange={e => props.onChange('email', e.target.value)} value={props.email}/>
            </div>
            <div className="form-group">
              <label>{base.i18n.t('name')}</label>
              <input type="text" className="form-control" onChange={e => props.onChange('name', e.target.value)} value={props.name}/>
            </div>
            <div className="form-group">
              <label>{base.i18n.t('phone')}</label>
              <input type="number" className="form-control" onChange={e => props.onChange('phone', e.target.value)} value={props.phone}/>
            </div>
            <div className="form-group">
              <label>{base.i18n.t('birth_date')}</label>
              <DatePicker locale={base.locale_string} value={props.birth_date} onChange={date => props.onChange('birth_date', date)} maxDate={new Date()}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
