import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2'
import moment from 'moment'

import Base from '../../../../utils/base'
import CustomTable from '../../../../layout/custom_table/index'

export default function SalesListOrder() {
  var base = new Base()
  const [arr, set_arr] = useState([])
  const [sort] = useState([
    {
      name: 'created_at',
      sort: 'desc',
    },
  ])
  const [arr_header, set_arr_header] = useState([
    {
      label: base.i18n.t('order_id'),
      data: 'id',
    },
    {
      label: base.i18n.t('user'),
      data: 'user_name',
    },
    {
      label: base.i18n.t('price'),
      data: 'grand_total',
    },
    {
      label: base.i18n.t('date'),
      data: 'date',
    },
    {
      label: base.i18n.t('address'),
      data: 'address',
    },
    {
      label: base.i18n.t('status'),
      data: 'status_str',
    },
  ])

  function on_update_data(arr){
    arr.forEach((data, index) => {
      data.grand_total = data.currency.name + ' ' + data.grand_total.toLocaleString(base.locale_string)
      data.date = moment(data.created_at).format('DD MMMM YYYY')
      data.user_name = data.user.name
      data.status_str = data.status.replace(/_/g, ' ')
    })
    set_arr(arr)
  }

  return (
    <div className="card">
      <div className="card-header">
        <h5>{base.i18n.t('list_order')}</h5>
      </div>
      <div className="card-body">
        <CustomTable
          url={'/order?sort=' + JSON.stringify(sort)}
          arr_header={arr_header}
          arr={arr}
          on_update_data={data => on_update_data(data)}/>
      </div>
    </div>
  );
}
