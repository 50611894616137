import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../utils/base'

export default function PleaseWaitModal(props) {
  var base = new Base()

  return (
    <div className="modal fade" id="pleaseWait" data-backdrop="static" data-keyboard="false" tabIndex="-1">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">{base.i18n.t('notice')}</h5>
          </div>
          <div className="modal-body">
            {base.i18n.t('please_wait')}
          </div>
        </div>
      </div>
    </div>
  );
}
