import { useEffect, useState } from 'react';

import Base from '../../../utils/base'
import CustomTable from '../../../layout/custom_table/index'

export default function Country() {
  var base = new Base()
  const [arr_header, set_arr_header] = useState([
    {
      label: base.i18n.t('name'),
      data: 'name',
    },
  ])
  const [arr, set_arr] = useState([])

  function on_update_data(arr){
    set_arr(arr)
  }

  return (
    <div>
      <h3>{base.i18n.t('country')}</h3>
      <CustomTable 
        url='/country' 
        arr_header={arr_header} 
        arr={arr} 
        url_update='/country/action'
        url_create='/country/action'
        url_delete='/country'
        on_update_data={data => on_update_data(data)}/>
    </div>
  );
}