import { useEffect, useState } from 'react';
import moment from 'moment'
// import axios from 'axios'

import Base from '../../utils/base'
import MyBusiness from './component/my_business'
import Overview from './component/overview'

export default function Home() {
  var base = new Base()

  // useEffect(() => {
  //   axios.post("http://form-pendaftaran-admin.quantumtri.com/api/auth/login", {
  //     email: "admin@admin.com",
  //     password: "12345",
  //   }, {
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Accept": "application/json"
  //     },
  //     withCredentials: true,
  //   })
  // })

  return (
    <div className="row">
      <Overview/>
      <div className="col-12 mb-3">
        <MyBusiness/>
      </div>
    </div>
  );
}
