import { useEffect, useState } from 'react';

import Base from '../../utils/base'
import Paginate from './paginate'
import ConfirmationModal from '../modal/confirmation_modal'

import no_image_available from "../../assets/no_image_available.jpeg"

export default function CustomTable(props) {
  var base = new Base()
  const [arr_header, set_arr_header] = useState([])
  const [arr, set_arr] = useState([])
  const [arr_specific_search, set_arr_specific_search] = useState([])
  const [arr_sort, set_arr_sort] = useState([])
  const [total_page, set_total_page] = useState(0)
  const [current_page, set_current_page] = useState(1)
  const [per_page, set_per_page] = useState(10)
  const [arr_per_page, set_arr_per_page] = useState([5, 10, 20, 50, 100])
  const [index, set_index] = useState(1)
  const [search, set_search] = useState("")
  const [response, set_response] = useState({})
  const [first_time, set_first_time] = useState(1)

  const [show_update, set_show_update] = useState(false)
  const [show_delete, set_show_delete] = useState(false)

  const [timeout, set_timeout] = useState(null)

  useEffect(() => {
    if(props.current_page_type != null && props.current_page_type == "multiple"){
      var arr_current_page = window.localStorage.getItem('arr_current_page') != null ? JSON.parse(window.localStorage.getItem('arr_current_page')) : {}
      var arr_per_page = window.localStorage.getItem('arr_per_page') != null ? JSON.parse(window.localStorage.getItem('arr_per_page')) : {}

      if(arr_current_page[props.current_page_id] != null)
        set_current_page(arr_current_page[props.current_page_id])
      if(arr_per_page[props.current_page_id] != null)
        set_per_page(arr_per_page[props.current_page_id])
    }
    else{
      if(window.localStorage.getItem('current_page') != null)
        set_current_page(window.localStorage.getItem('current_page'))
      if(window.localStorage.getItem('per_page') != null)
        set_per_page(window.localStorage.getItem('per_page'))
    }
  }, [])

  useEffect(() => {
    set_arr(props.arr)
    manage_action()
    set_arr_header(props.arr_header)
  }, [props.arr_header, props.arr])

  useEffect(() => {
    if(JSON.stringify(arr_header) != JSON.stringify(props.arr_header))
      manage_specific_search()
  }, [arr_header, props.arr_header])

  useEffect(() => {
    if(props.url != null)
      get_data()
  }, [current_page, props.rnd, props.url, per_page])

  useEffect(() => {
    if(props.save_current_page == null || (props.save_current_page != null && props.save_current_page)){
      if(props.current_page_type != null && props.current_page_type == "multiple"){
        var arr_current_page = window.localStorage.getItem('arr_current_page') != null ? JSON.parse(window.localStorage.getItem('arr_current_page')) : {}

        arr_current_page[props.current_page_id] = current_page
        window.localStorage.setItem('arr_current_page', JSON.stringify(arr_current_page))
      }
      else
        window.localStorage.setItem('current_page', current_page)
    }
  }, [current_page, ])

  useEffect(() => {
    if(props.save_current_page == null || (props.save_current_page != null && props.save_current_page)){
      if(props.current_page_type != null && props.current_page_type == "multiple"){
        var arr_per_page = window.localStorage.getItem('arr_per_page') != null ? JSON.parse(window.localStorage.getItem('arr_per_page')) : {}

        arr_per_page[props.current_page_id] = per_page
        window.localStorage.setItem('arr_per_page', JSON.stringify(arr_per_page))
      }
      else
        window.localStorage.setItem('per_page', per_page)
    }
  }, [per_page, ])

  useEffect(() => {
    if(!first_time)
      wait_for_typing()
  }, [arr_sort, search, arr_specific_search])

  function manage_action(){
    if(props.url_update != null && props.url_update != "")
      set_show_update(true)
    if(props.url_delete != null && props.url_delete != "")
      set_show_delete(true)
  }

  function manage_specific_search(){
    var arr_spe = []
    var arr_sor = []
    props.arr_header.forEach((data, index) => {
      var data_spe = data
      data_spe.value = ""
      arr_spe.push(data_spe)

      var data_sor = {}
      data_sor.sort = 'none'
      arr_sor.push(data_sor)
    })
    set_arr_specific_search(arr_spe)
    set_arr_sort(arr_sor)
  }

  function get_data(){

    var url = base.host + props.url + (props.url.indexOf('?') != -1 ? "&page=" : "?page=") + current_page + "&search=" + search + "&per_page=" + per_page

    arr_specific_search.forEach((data, index) => {
      url += "&" + data.data + "=" + data.value
    })

    if(props.url.indexOf('sort') == -1){
      var arr_sort_temp = []
      arr_sort.forEach((data, index) => {
        var data1 = {}
        data1.name = props.arr_header[index].data
        data1.sort = data.sort == 'none' ? '' : data.sort
        arr_sort_temp.push(data1)
      })
      url += '&sort=' + JSON.stringify(arr_sort_temp)
    }

    base.request(url).then((response) => {
      if(response != null && response.data != null){
        set_total_page(response.data.last_page)
        props.on_update_data(response.data.data)
        set_first_time(false)
      }
    })
  }

  function on_cancel_confirmation(){
    window.$('#' + (props.modal_id != null ? props.modal_id : "confirmationModal")).modal('hide')
  }

  function delete_action(index){
    set_index(index)
    window.$('#' + (props.modal_id != null ? props.modal_id : "confirmationModal")).modal('show')
  }

  function on_confirmation(){
    window.$('#' + (props.modal_id != null ? props.modal_id : "confirmationModal")).modal('hide')
    base.request(base.host + props.url_delete + "?id=" + arr[index].id, "delete").then((response) => {
      get_data()
    })
  }
  
  function on_action_hide_show_clicked(index){
    base.request(base.host + props.url_hide_show, "post", {
      id: arr[index].id,
    }).then((response) => {
      get_data()
    })
  }

  function page_change(data){
    set_current_page(data.selected + 1)
  }

  function wait_for_typing(){
    if(timeout != null)
      clearTimeout(timeout)

    set_timeout(setTimeout(() => get_data(),base.search_wait_time))
  }

  function search_action(data){
    reset_specific_search()
    set_search(data)
  }

  function reset_specific_search(){
    var arr_spe = JSON.parse(JSON.stringify(arr_specific_search))
    arr_spe.forEach((data, index) => {
      data.value = ""
    })
    set_arr_specific_search(arr_spe)
  }

  function search_specific_action(index, value){
    var arr_temp = JSON.parse(JSON.stringify(arr_specific_search))
    arr_temp[index].value = value
    set_arr_specific_search(arr_temp)
  }

  function sort_action(index){
    if(props.include_sort == null || (props.include_sort != null && props.include_sort)){
      var arr_temp = JSON.parse(JSON.stringify(arr_sort))
      arr_temp.forEach((temp, index1) => {
        if(index1 !== index)
          temp.sort = "none"
      })

      if(arr_temp[index].sort === 'none')
        arr_temp[index].sort = 'asc'
      else if(arr_temp[index].sort === 'asc')
        arr_temp[index].sort = 'desc'
      else
        arr_temp[index].sort = 'none'
      set_arr_sort(arr_temp)
    }
  }

  return (
    <div className="my-3">
      <ConfirmationModal
        modal_id={props.modal_id}
        on_cancel_click={() => on_cancel_confirmation()}
        on_confirm_click={() => on_confirmation()}/>
      <div className="">
        <div className="text-right">
          {
            props.url_create ?
            <a href={props.url_create} className="btn btn-primary">{props.text_add == null ? base.i18n.t('add') : props.text_add}</a>
            :
            <></>
          }
        </div>
        {
          props.no_search == null || (props.no_search != null && !props.no_search) ?
          <input type="text" className="form-control my-3" value={search} onChange={e => search_action(e.target.value)}/>
          :
          <></>
        }
      </div>
      {
        arr.length > 0 ?
        <div className="d-flex justify-content-between">
          <div>
            <Paginate
              total_page={total_page}
              current_page={current_page}
              on_page_change={data => page_change(data)}/>
          </div>
          <div>
            <select className="form-control" value={per_page} onChange={e => set_per_page(e.target.value)}>
              {
                arr_per_page.map((data, index) => (
                  <option key={index} value={data}>{data}</option>
                ))
              }
            </select>
          </div>
        </div>
        :
        <></>
      }
      <div className="table-responsive">
        <table className="table table-bordered my-3">
          <thead>
            <tr>
              {
                props.arr_header.map((data, index) => {
                  if(props.arr_header[index].type != 'image')
                    return (
                      <th key={index} onClick={() => sort_action(index)} style={data.style != null ? data.style : { cursor: 'pointer' }}>
                        <div className='d-flex justify-content-between align-items-center'>
                          {data.label}
                          {
                            (props.include_sort == null || (props.include_sort != null && props.include_sort)) ?
                            <div>
                              {
                                arr_sort.length > 0 && arr_sort[index] != null && arr_sort[index].sort === 'asc' ?
                                <i className="fas fa-sort-up"></i>
                                :
                                (
                                  arr_sort.length > 0 && arr_sort[index] != null && arr_sort[index].sort === 'desc' ?
                                  <i className="fas fa-sort-down"></i>
                                  :
                                  <i className="fas fa-sort"></i>
                                )
                              }
                            </div>
                            :
                            <></>
                          }
                        </div>
                      </th>
                    )
                  else
                    return (<th key={index}>{data.label}</th>)
                })
              }
              { show_update || show_delete || props.on_action_clicked != null ? <th style={{ width: '18%' }}>{base.i18n.t('action')}</th> : <></> }
            </tr>
            <tr>
              {
                arr_specific_search.map((data, index) => {
                  if(props.arr_header[index].type != 'image')
                    return (
                      <th key={index}>
                        <input type="text" className="form-control" value={data.value} onChange={e => search_specific_action(index, e.target.value)}/>
                      </th>
                    )
                  else
                  return (
                    <th key={index}>
                    </th>
                  )
                })
              }
              { show_update || show_delete || props.on_action_clicked != null ? <th></th> : <></> }
            </tr>
          </thead>
          <tbody>
            {
              arr.length > 0 ?
              arr.map((data, index) => (
                <tr key={index}>
                  {
                    props.arr_header.map((data1, index1) => {
                      if(data1.type !== null && data1.type === 'image')
                        return (
                          <td key={index1}>
                            {
                              data[data1.data] != null && data[data1.data] != '' ?
                              <img src={data[data1.data]} width="200rem"/>
                              :
                              <img src={no_image_available} width="200rem"/>
                            }
                          </td>
                        )
                      else
                        return (
                          <td key={index1} dangerouslySetInnerHTML={{
                            __html: data[data1.data]
                          }}>
                          </td>
                        )
                    })
                  }
                  {
                    show_update || show_delete || props.on_action_clicked != null ?
                      <td>
                        {
                          ((data.allow_update == null && show_update) || (data.allow_update != null && data.allow_update && show_update)) &&
                          <a className="btn btn-primary text-white mb-3" href={props.url_update + "?id=" + data.id}>{props.text_update == null ? base.i18n.t('edit') : props.text_update}</a>
                        }
                        {
                          ((data.allow_delete == null && show_delete) || (data.allow_delete != null && data.allow_delete && show_delete)) &&
                          <button className="btn btn-danger ml-3 mb-3" onClick={() => delete_action(index)}>{props.text_delete == null ? base.i18n.t('delete') : props.text_delete}</button>
                        }
                        {
                          props.on_action_clicked != null &&
                          <button className="btn btn-primary text-white ml-3 mb-3" onClick={() => props.on_action_clicked(index)}>{props.text_action == null ? base.i18n.t('action') : props.text_action}</button>
                        }
                        {
                          ((data.allow_hide_show == null && props.url_hide_show != null) || (data.allow_hide_show != null && data.allow_hide_show && props.url_hide_show != null)) &&
                          <button className="btn btn-primary text-white ml-3 mb-3" onClick={() => on_action_hide_show_clicked(index)}>{data.is_show == 1 ? base.i18n.t('change_hide') : base.i18n.t('change_show')}</button>
                        }
                      </td>
                    :
                    <></>
                  }
                </tr>
              ))
              :
              <tr>
                <td colSpan={props.on_action_clicked != null || show_update || show_delete ? props.arr_header.length + 1 : props.arr_header.length} className="text-center">{base.i18n.t('no_data_found')}</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      {
        arr.length > 0 ?
        <Paginate
          total_page={total_page}
          current_page={current_page}
          on_page_change={data => page_change(data)}/>
        :
        <></>
      }
    </div>
  )
}
