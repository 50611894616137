import { useEffect, useState } from 'react';
import queryString from "query-string";
import moment from 'moment'

import Base from '../../../utils/base'
import Info from './action_component/info'
import Product from './action_component/product'

export default function DiscountAction(props) {
  var base = new Base()
  const [data, set_data] = useState({})
  const [name, set_name] = useState('')
  const [description, set_description] = useState('')
  const [type_discount, set_type_discount] = useState('percentage')
  const [discount, set_discount] = useState('0')
  const [start_date, set_start_date] = useState('')
  const [end_date, set_end_date] = useState('')
  const [arr_product, set_arr_product] = useState([])
  const [base64, set_base64] = useState(null)
  const [query, set_query] = useState({})
  const [arr_tabs, set_arr_tabs] = useState([])

  useEffect(() => {
    reload_tabs()
    base.limit_data_length(name, set_name)
  }, [name, arr_product, description, type_discount, discount, start_date, end_date,])

  useEffect(() => {
    var temp = parseFloat(discount.replace(/\./g, ''))
    if(discount != '' && type_discount === 'percentage'){
      if(temp > 100)
        temp = 100
    }
    base.to_currency_format(discount != '' ? temp.toLocaleString(base.locale_string) : discount, set_discount)
  }, [discount])

  useEffect(() => {
    setTimeout(() => {
      var query1 = queryString.parse(props.location.search)
      set_query(query1)

      if(query1.id != null)
        get_data()
    },100)
  }, [])

  function reload_tabs(){
    set_arr_tabs([
      {
        id: 'info',
        label: base.i18n.t('info'),
        component: <Info
          name={name}
          description={description}
          type_discount={type_discount}
          discount={discount}
          start_date={start_date}
          end_date={end_date}
          query_url={query}
          onChange={(key, value) => on_info_change(key, value)}/>,
      },
      {
        id: 'product',
        label: base.i18n.t('product'),
        component: <Product
          data={arr_product}
          query_url={query}
          onAdd={(data) => on_add_product(data)}
          onEdit={(data, index) => on_edit_product(data, index)}
          onDelete={(index) => on_delete_product(index)}/>,
      },
    ])
  }

  function on_info_change(key, value) {
    if(key == 'name')
      set_name(value)
    else if(key == 'description')
      set_description(value)
    else if(key == 'type_discount')
      set_type_discount(value)
    else if(key == 'discount')
      set_discount(value)
    else if(key == 'start_date')
      set_start_date(value)
    else if(key == 'end_date')
      set_end_date(value)
  }

  function on_add_product(data_product) {
    base.add_array(arr_product, set_arr_product, data_product)
  }

  function on_edit_product(data_product, index) {
    base.update_array(arr_product, set_arr_product, data_product, index)
  }

  function on_delete_product(index) {
    base.remove_array(arr_product, set_arr_product, index)
  }

  function get_data() {
    var queryParams = queryString.parse(props.location.search)
    base.request(base.host + '/discount?id=' + queryParams.id)
      .then(response => {
        if(response != null && response.status === 'success'){
          set_name(response.data.name)
          set_description(response.data.description)
          set_type_discount(response.data.type_discount)
          set_discount(response.data.discount.toLocaleString(base.locale_string))
          set_start_date(new Date(response.data.start_date))
          set_end_date(new Date(response.data.end_date))
          set_arr_product(response.data.arr_product)
          set_data(response.data)
        }
      })
  }

  function submit() {
    if(name === '')
      base.notify_user(base.i18n.t('name_empty'))
    else if(discount === '0')
      base.notify_user(base.i18n.t('discount_empty'))
    else if(start_date === '')
      base.notify_user(base.i18n.t('start_date_empty'))
    else if(end_date === '')
      base.notify_user(base.i18n.t('end_date_empty'))
    else{
      var queryParams = queryString.parse(props.location.search)
      var temp = {
        arr_product: arr_product,
        name: name,
        description: description,
        discount: parseFloat(discount.replace(/\./g, '')),
        type_discount: type_discount,
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
      }
      if(data.id != null)
        temp.id = data.id

      base.request(base.host + '/discount', queryParams.id == null ? 'post' : 'put', temp, 'application/json').then(response => {
        if(response != null && response.status === 'success'){
          window.location.href = "/discount"
        }
      })
    }
  }

  return (
    <div>
      <h3>{query.id != null ? base.i18n.t('edit_discount') : base.i18n.t('add_discount')}</h3>
      <ul className="nav nav-pills" id="myTab" role="tablist">
        {
          arr_tabs.map((data, index) => (
            <li className="nav-item" role="presentation" key={index}>
              <a className={"nav-link" + (index == 0 ? ' active' : '')} id={data.id+'-tab'} data-toggle="tab" href={'#'+data.id} role="tab" aria-controls={'nav-'+data.id} aria-selected="false">{data.label}</a>
            </li>
          ))
        }
      </ul>
      <div className="tab-content mt-3" id="myTabContent">
        {
          arr_tabs.map((data, index) => (
            <div className={"tab-pane fade" + (index == 0 ? ' show active' : '')} key={index} id={data.id} role="tabpanel" aria-labelledby={data.id + '-tab'}>{data.component}</div>
          ))
        }
      </div>
      <div className="mt-3">
        <button className="btn btn-primary" onClick={() => submit()}>{base.i18n.t('submit')}</button>
      </div>
    </div>
  );
}
