import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import ShippingVoucherPage from '../../pages/master/shipping_voucher/index'
import ShippingVoucherActionPage from '../../pages/master/shipping_voucher/action'

export default function ShippingVoucherRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={ShippingVoucherPage} />
      <Route exact path={`${props.match.path}/action`} component={ShippingVoucherActionPage} />
    </>
  )
}
