import { useEffect, useState } from 'react';
import moment from 'moment'

import Base from '../../utils/base'
import OrderItem from './order_item'

export default function Order() {
  var base = new Base()
  const [arr_tabs, set_arr_tabs] = useState([])
  const [selected_tabs, set_selected_tabs] = useState(null)

  useEffect(() => {
    reload_tabs()
  }, [])

  useEffect(() => {
    if(arr_tabs.length > 0)
      setTimeout(() => {
        window.$('a[data-toggle="tab"]').on('shown.bs.tab', (event) => {
          var explode = event.target.id.split('-')
          window.localStorage.setItem('order_tab', explode[0])
        })
      }, 1000)

  }, [arr_tabs])

  function reload_tabs(){
    set_selected_tabs(window.localStorage.getItem('order_tab'))
    set_arr_tabs([
      {
        id: 'wait_payment',
        label: base.i18n.t('wait_payment'),
        component: <OrderItem status='wait_payment'/>,
      },
      {
        id: 'processed',
        label: base.i18n.t('processed'),
        component: <OrderItem status='processed'/>,
      },
      {
        id: 'shipped',
        label: base.i18n.t('shipped'),
        component: <OrderItem status='shipped'/>,
      },
      {
        id: 'arrived',
        label: base.i18n.t('arrived'),
        component: <OrderItem status='arrived'/>,
      },
      {
        id: 'accepted',
        label: base.i18n.t('accepted'),
        component: <OrderItem status='accepted'/>,
      },
      {
        id: 'complained',
        label: base.i18n.t('complained'),
        component: <OrderItem status='complained'/>,
      },
      {
        id: 'canceled',
        label: base.i18n.t('canceled'),
        component: <OrderItem status='canceled'/>,
      },
      {
        id: 'done',
        label: base.i18n.t('done'),
        component: <OrderItem status='done'/>,
      },
    ])
  }

  return (
    <div>
      <h3>{base.i18n.t('order')}</h3>
      <ul className="nav nav-pills" id="myTab" role="tablist">
        {
          arr_tabs.map((data, index) => (
            <li className="nav-item" role="presentation" key={index}>
              <a className={"nav-link" + ((selected_tabs == null && index == 0) || (selected_tabs != null && selected_tabs === data.id) ? ' active' : '')} id={data.id+'-tab'} data-toggle="tab" href={'#'+data.id} role="tab" status={data.id} aria-controls={'nav-'+data.id} aria-selected="false">{data.label}</a>
            </li>
          ))
        }
      </ul>
      <div className="tab-content mt-3" id="myTabContent">
        {
          arr_tabs.map((data, index) => (
            <div className={"tab-pane fade" + ((selected_tabs == null && index == 0) || (selected_tabs != null && selected_tabs === data.id) ? ' show active' : '')} key={index} id={data.id} role="tabpanel" aria-labelledby={data.id + '-tab'}>{data.component}</div>
          ))
        }
      </div>
    </div>
  );
}
