import BaseRouter from './routes/base'
import Navbar from './layout/navbar'
import Sidebar from './layout/sidebar'
// import io from 'https://cdnjs.cloudflare.com/ajax/libs/socket.io/2.3.0/socket.io.js'

import $ from 'jquery'
import 'bootstrap';
import 'popper.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications/lib/notifications.css';
import '@fortawesome/fontawesome-free/css/all.min.css'
import "react-datepicker/dist/react-datepicker.css"

import './assets/sb-admin/css/sb-admin-2.min.css'

import {NotificationContainer, NotificationManager} from 'react-notifications'
import { useEffect } from 'react';
// import io from 'socket.io-client';

import PleaseWaitModal from './layout/modal/please_wait_modal'
import LogoutConfirmationModal from './layout/modal/logout_confirmation_modal'
import Base from './utils/base'

export default function App() {
  var base = new Base()
  // const socket = io("https://papanda-admin.quantumtri.com:6001", {jsonp: false})

  useEffect(() => {
    window.$ = $
    window.jQuery = $

    window.$('.modal').on('hidden.bs.modal', function (event) {
      window.$('.modal-backdrop').addClass('d-none')
    })

    // socket.emit('subscribe', {
    //   channel: 'consultation.CONSULTATION_000001',
    // }).on('App\\Events\\ConsultationRoomEvent', function(channel, data) {
    //   console.log(data);
    // })
  }, [])

  function on_cancel_confirmation(){
    window.$('#logoutConfirmationModal').modal('hide')
  }

  function on_confirmation(){
    window.$('#logoutConfirmationModal').modal('hide')
    base.logout_action()
  }

  return (
    <>
      {
        window.location.pathname != '/auth/login' ?
        <div id="wrapper">
          <Sidebar/>
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Navbar/>
              <div className="container-fluid">
                <BaseRouter/>

              </div>
            </div>
          </div>
          <LogoutConfirmationModal
            on_cancel_click={() => on_cancel_confirmation()}
            on_confirm_click={() => on_confirmation()}/>
        </div>
        :
        <BaseRouter/>
      }
      <PleaseWaitModal/>
      <NotificationContainer/>
    </>
  );
}
