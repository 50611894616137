import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../../utils/base'

export default function SettingGeneral(props) {
  var base = new Base()
  const [data, set_data] = useState({})
  const [send_email_admin, set_send_email_admin] = useState(0)
  const [send_email_user, set_send_email_user] = useState(0)
  const [query, set_query] = useState({})

  useEffect(() => {
    var query1 = queryString.parse(props.location.search)
    set_query(query1)

    get_data()
  }, [])

  // useEffect(() => {
  //   base.to_currency_format(shipping_discount, set_shipping_discount)
  //   base.to_currency_format(min_purchase_shipping_discount, set_min_purchase_shipping_discount)
  // }, [shipping_discount, min_purchase_shipping_discount])

  function get_data() {
    var queryParams = queryString.parse(props.location.search)
    base.request(base.host + '/setting').then(response => {
      if(response != null && response.status === 'success'){
        set_data(response.data)
        set_send_email_admin(response.data.send_email_admin)
        set_send_email_user(response.data.send_email_user)
      }
    })
  }

  function submit() {
    // if(name === '')
    //   base.notify_user(base.i18n.t('name_empty'))
    // else{
      var queryParams = queryString.parse(props.location.search)
      var temp = data
      temp.send_email_admin = send_email_admin
      temp.send_email_user = send_email_user

      base.request(base.host + '/setting', 'put', temp).then(response => {
        if(response != null && response.status === 'success'){
          // window.location.reload()
        }
      })
    // }
  }

  return (
    <div>
      <h3>{base.i18n.t('edit_setting_general')}</h3>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">

              <div className="form-group">
                <label>{base.i18n.t('send_email_admin')}</label>
                <div>
                  <div className="form-check-inline">
                    <input className="form-check-input" type="radio" name="send_email_admin" value="0" onChange={() => set_send_email_admin(0)} id="adminFlexRadioDefaultNo" checked={send_email_admin == 0}/>
                    <label className="form-check-label" htmlFor="adminFlexRadioDefaultNo">{base.i18n.t('no')}</label>
                  </div>
                  <div className="form-check-inline">
                    <input className="form-check-input" type="radio" name="send_email_admin" value="1" onChange={() => set_send_email_admin(1)} id="adminFlexRadioDefaultYes" checked={send_email_admin == 1}/>
                    <label className="form-check-label" htmlFor="adminFlexRadioDefaultYes">{base.i18n.t('yes')}</label>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>{base.i18n.t('send_email_user')}</label>
                <div>
                  <div className="form-check-inline">
                    <input className="form-check-input" type="radio" name="send_email_user" value="0" onChange={() => set_send_email_user(0)} id="userFlexRadioDefaultNo" checked={send_email_user == 0}/>
                    <label className="form-check-label" htmlFor="userFlexRadioDefaultNo">{base.i18n.t('no')}</label>
                  </div>
                  <div className="form-check-inline">
                    <input className="form-check-input" type="radio" name="send_email_user" value="1" onChange={() => set_send_email_user(1)} id="userFlexRadioDefaultYes" checked={send_email_user == 1}/>
                    <label className="form-check-label" htmlFor="userFlexRadioDefaultYes">{base.i18n.t('yes')}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-3">
          <button className="btn btn-primary" onClick={() => submit()}>{base.i18n.t('submit')}</button>
        </div>
      </div>
    </div>
  );
}
