import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../utils/base'

export default function UserAction(props) {
  var base = new Base()
  const [data, set_data] = useState({})
  const [email, set_email] = useState('')
  const [name, set_name] = useState('')
  const [phone, set_phone] = useState('')
  const [file_name, set_file_name] = useState('')
  const [base64, set_base64] = useState(null)
  const [image, set_image] = useState('')
  const [arr_address, set_arr_address] = useState([])
  const [query, set_query] = useState({})
  const [first_time, set_first_time] = useState(true)
  const [arr_tabs, set_arr_tabs] = useState([])

  useEffect(() => {
    if(!first_time){
      base.limit_data_length(email, set_email)
      base.limit_data_length(name, set_name)
      base.limit_data_length(phone, set_phone, 20)
    }
  }, [email, name, phone, file_name, image, file_name])

  useEffect(() => {
    setTimeout(() => {
      var query1 = queryString.parse(props.location.search)
      set_query(query1)

      if(query1.id != null)
        get_data()
    },100)
  }, [])

  function on_upload_image(e){
    var image = URL.createObjectURL(e.target.files[0])
    base.getBase64(e.target.files[0], result => {
      set_base64(result)
    })

    set_file_name(image)
  }

  function get_data() {
    var queryParams = queryString.parse(props.location.search)
    base.request(base.host + '/user?id=' + queryParams.id)
      .then(response => {
        if(response != null && response.status === 'success'){
          set_name(response.data.name)
          set_phone(response.data.phone)
          set_first_time(false)
          set_email(response.data.email)
          set_file_name(base.host + '/image/user?file_name=' + response.data.file_name)
          set_data(response.data)
        }
      })
  }

  function submit() {
    if(name === '')
      base.notify_user(base.i18n.t('name_empty'))
    else if(phone === '')
      base.notify_user(base.i18n.t('phone_empty'))
    else if(email === '')
      base.notify_user(base.i18n.t('email_empty'))
    else if(!base.validateEmail(email))
      base.notify_user(base.i18n.t('not_email_format'))
    else{
      var queryParams = queryString.parse(props.location.search)
      var temp = {
        name: name,
        phone: phone,
        email: email,
      }
      if(image != '')
        temp.image = image
      if(data.id != null)
        temp.id = data.id

      base.request(base.host + '/user/admin', queryParams.id == null ? 'post' : 'put', temp)
        .then(response => {
          if(response != null && response.status === 'success')
            window.location.href = "/user"
        })
    }
  }

  return (
    <div>
      <h3>{query.id != null ? base.i18n.t('edit_user_admin') : base.i18n.t('add_user_admin')}</h3>
      <div className="row">
        <div className="col-12 col-lg-4 mb-3">
          <div className="card">
            <div className="card-body">
              <input type="file" className="d-none" id="inputFile" onChange={e => on_upload_image(e)}/>
              <button className="btn btn-primary" onClick={e => window.$('#inputFile').trigger('click')}>{base.i18n.t('upload_file')}</button>
              <div className="mt-3">
                {
                  file_name != '' ?
                  <img src={file_name} width="100%"/>
                  :
                  <></>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8 mb-3">
          <div className="card">
            <div className="card-body">
              <div className="form-group">
                <label>{base.i18n.t('email')}</label>
                <input type="email" className="form-control" onChange={e => set_email(e.target.value)} value={email}/>
              </div>
              <div className="form-group">
                <label>{base.i18n.t('name')}</label>
                <input type="text" className="form-control" onChange={e => set_name(e.target.value)} value={name}/>
              </div>
              <div className="form-group">
                <label>{base.i18n.t('phone')}</label>
                <input type="number" className="form-control" onChange={e => set_phone(e.target.value)} value={phone}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <button className="btn btn-primary" onClick={() => submit()}>{base.i18n.t('submit')}</button>
      </div>
    </div>
  );
}
