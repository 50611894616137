import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import InventoryPage from '../pages/inventory/index'
import InventoryActionPage from '../pages/inventory/action'

export default function InventoryRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={InventoryPage} />
      <Route exact path={`${props.match.path}/action`} component={InventoryActionPage} />
    </>
  )
}
