import { useEffect, useState } from 'react';
import moment from 'moment'

import Base from '../../../utils/base'

export default function Overview() {
  var base = new Base()
  const [arr, set_arr] = useState([
    {
      id: 'wait_payment',
      title: base.i18n.t('wait_payment'),
      data: 0,
    },
    {
      id: 'processed',
      title: base.i18n.t('processed'),
      data: 0,
    },
    {
      id: 'shipped',
      title: base.i18n.t('shipped'),
      data: 0,
    },
    {
      id: 'arrived',
      title: base.i18n.t('arrived'),
      data: 0,
    },
    {
      id: 'accepted',
      title: base.i18n.t('accepted'),
      data: 0,
    },
    {
      id: 'complained',
      title: base.i18n.t('complained'),
      data: 0,
    },
    {
      id: 'canceled',
      title: base.i18n.t('canceled'),
      data: 0,
    },
    {
      id: 'done',
      title: base.i18n.t('done'),
      data: 0,
    },
  ])

  useEffect(() => {
    get_data()
  }, [])

  function get_data() {
    base.request(base.host + '/report/overview/order').then(response => {
      if(response != null && response.status === 'success'){
        var arr_temp = JSON.parse(JSON.stringify(arr))
        response.data.forEach((data, index) => {
          arr_temp.forEach((data1, index1) => {
            if(data1.id == data.id){
              data1.data = data.data
              return
            }
          })
        })
        set_arr(arr_temp)
      }
    })
  }

  return (
    <>
      {
        arr.map((data, index) => (
          <div className="col-12 col-md-6 col-lg-3 mb-3" key={index}>
            <div className="card">
              <div className="card-body">
                <p className="text-center m-0">{data.title}</p>
                <h4 className="text-center m-0">{data.data}</h4>
              </div>
            </div>
          </div>
        ))
      }
    </>
  );
}
