import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../../utils/base'

export default function CityAction(props) {
  var base = new Base()
  const [data, set_data] = useState({})
  const [country, set_country] = useState('')
  const [province, set_province] = useState('')
  const [name, set_name] = useState('')
  const [query, set_query] = useState({})
  const [arr_country, set_arr_country] = useState([])
  const [arr_province, set_arr_province] = useState([])

  useEffect(() => {
    base.limit_data_length(name, set_name)
  }, [name,])

  useEffect(() => {
    if(query.id != null)
      get_data()
  }, [arr_country])

  useEffect(() => {
    get_province_data()
  }, [country])

  useEffect(() => {
    var query1 = queryString.parse(props.location.search)
    set_query(query1)
    get_country_data()
  }, [])

  function get_country_data() {
    base.request(base.host + '/country/all').then(response => {
      if(response != null && response.status === 'success'){
        set_arr_country(response.data)
      }
    })
  }

  function get_province_data() {
    base.request(base.host + '/province/all?country_id=' + country).then(response => {
      if(response != null && response.status === 'success'){
        set_arr_province(response.data)
      }
    })
  }

  function get_data() {
    var queryParams = queryString.parse(props.location.search)
    base.request(base.host + '/city?id=' + queryParams.id).then(response => {
      if(response != null && response.status === 'success'){
        set_country(response.data.country.id)
        set_province(response.data.province.id)
        set_name(response.data.name)
        set_data(response.data)
      }
    })
  }

  function submit() {
    if(name === '')
      base.notify_user(base.i18n.t('name'))
    else if(province === '')
      base.notify_user(base.i18n.t('province_empty'))
    else{
      var queryParams = queryString.parse(props.location.search)
      var temp = {
        country: {
          id: country
        },
        province: {
          id: province
        },
        name: name,
      }
      if(data.id != null)
        temp.id = data.id

      base.request(base.host + '/city', queryParams.id == null ? 'post' : 'put', temp, 'application/json').then(response => {
        if(response != null && response.status === 'success'){
          window.location.href = "/city"
        }
      })
    }
  }

  return (
    <div>
      <h3>{query.id != null ? base.i18n.t('edit_city') : base.i18n.t('add_city')}</h3>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="form-group">
                <span>
                  <label>{base.i18n.t('country')}</label>
                </span>
                <select className="form-control" onChange={e => set_country(e.target.value)} value={country}>
                  <option value=''>{base.i18n.t('choose_option')}</option>
                  {
                    arr_country.map((data,index) => (
                      <option value={data.id} key={index}>{data.name}</option>
                    ))
                  }
                </select>
              </div>
              <div className="form-group">
                <span>
                  <label>{base.i18n.t('province')}</label>
                </span>
                <select className="form-control" onChange={e => set_province(e.target.value)} value={province}>
                  <option value=''>{base.i18n.t('choose_option')}</option>
                  {
                    arr_province.map((data,index) => (
                      <option value={data.id} key={index}>{data.name}</option>
                    ))
                  }
                </select>
              </div>
              <div className="form-group">
                <label>{base.i18n.t('name')}</label>
                <input type="text" className="form-control" onChange={e => set_name(e.target.value)} value={name}/>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-3">
          <button className="btn btn-primary" onClick={() => submit()}>{base.i18n.t('submit')}</button>
        </div>
      </div>
    </div>
  );
}
