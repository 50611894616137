import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import UserPage from '../pages/user/index'
import UserActionPage from '../pages/user/action'

export default function UserRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={UserPage} />
      <Route exact path={`${props.match.path}/action`} component={UserActionPage} />
    </>
  )
}
