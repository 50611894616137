import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../../utils/base'

export default function BlogHomeTypeAction(props) {
  var base = new Base()
  const [data, set_data] = useState({})
  const [name, set_name] = useState('')
  const [title, set_title] = useState('')
  const [query, set_query] = useState({})

  // useEffect(() => {
  //   base.limit_data_length(name, set_name)
  //   base.limit_data_length(title, set_title)
  // }, [name, title])

  useEffect(() => {
    var query1 = queryString.parse(props.location.search)
    set_query(query1)

    if(query1.id != null)
      get_data()
  }, [])

  function get_data() {
    var queryParams = queryString.parse(props.location.search)
    base.request(base.host + '/blog/home/type?id=' + queryParams.id).then(response => {
      if(response.status === 'success'){
        set_data(response.data)
        set_name(response.data.name)
        set_title(response.data.title)
      }
    })
  }

  function submit() {
    if(name === '')
      base.notify_user(base.i18n.t('name_empty'))
    else{
      var queryParams = queryString.parse(props.location.search)
      var temp = {
        name: name,
        title: title,
      }
      if(data.id != null)
        temp.id = data.id

      base.request(base.host + '/blog/home/type', queryParams.id == null ? 'post' : 'put', temp).then(response => {
        if(response != null && response.status === 'success'){
          window.location.href = "/blog/type"
        }
      })
    }
  }

  return (
    <div>
      <h3>{query.id != null ? base.i18n.t('edit_blog_type') : base.i18n.t('add_blog_type')}</h3>
      <div className="row">
        <div className="col-12 mb-3">
          <div className="card">
            <div className="card-body">
              <div className="form-group">
                <label>{base.i18n.t('name')}</label>
                <input type="text" className="form-control" disabled={query.id != null} onChange={e => set_name(e.target.value)} value={name}/>
              </div>
              <div className="form-group">
                <label>{base.i18n.t('title')}</label>
                <input type="text" className="form-control" onChange={e => set_title(e.target.value)} value={title}/>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-3">
          <button className="btn btn-primary" onClick={() => submit()}>{base.i18n.t('submit')}</button>
        </div>
      </div>
    </div>
  );
}
