import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import UserAdminPage from '../pages/user_admin/index'
import UserAdminActionPage from '../pages/user_admin/action'

export default function UserAdminRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={UserAdminPage} />
      <Route exact path={`${props.match.path}/action`} component={UserAdminActionPage} />
    </>
  )
}
