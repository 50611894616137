import React, { useEffect } from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'

import Home from './home'
import Auth from './auth'
import Banner from './master/banner'
import Blog from './master/blog'
import Setting from './master/setting'
import Client from './master/client'
import Category from './master/category'
import Courier from './master/courier'
import Voucher from './master/voucher'
import ShippingVoucher from './master/shipping_voucher'
import Product from './product'
import Inventory from './inventory'
import User from './user'
import UserAdmin from './user_admin'
import City from './master/city'
import Province from './master/province'
import Country from './master/country'
import Exchange from './master/exchange'
import Discount from './master/discount'
import ReportSales from './report/sales'
import Order from './order'
import CustomerService from './customer_service'

import NotFound from '../pages/not_found'

export default function Base() {

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/auth" component={Auth}/>
        <Route exact path="/" component={Home}/>
        <Route path="/banner" component={Banner}/>
        <Route path="/blog" component={Blog}/>
        <Route path="/client" component={Client}/>
        <Route path="/category" component={Category}/>
        <Route path="/courier" component={Courier}/>
        <Route path="/product" component={Product}/>
        <Route path="/inventory" component={Inventory}/>
        <Route path="/voucher" component={Voucher}/>
        <Route path="/shipping-voucher" component={ShippingVoucher}/>
        <Route path="/user" component={User}/>
        <Route path="/user-admin" component={UserAdmin}/>
        <Route path="/city" component={City}/>
        <Route path="/setting" component={Setting}/>
        <Route path="/province" component={Province}/>
        <Route path="/country" component={Country}/>
        <Route path="/order" component={Order}/>
        <Route path="/exchange" component={Exchange}/>
        <Route path="/customer-service" component={CustomerService}/>
        <Route path="/discount" component={Discount}/>
        <Route path="/report" component={ReportSales}/>
        <Route component={NotFound}/>
      </Switch>
    </BrowserRouter>
  )
}
