import { useEffect, useState } from 'react';
import queryString from "query-string";
import moment from 'moment'

import Base from '../../../utils/base'
import no_image_available from "../../../assets/no_image_available.jpeg"

export default function InventoryInfo(props) {
  var base = new Base()

  return (
    <div className="card">
      <div className="card-footer">
        <h5>{base.i18n.t('inventory_info')}</h5>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12 col-lg-6">
            <p className="m-0">{base.i18n.t('photo_list')}</p>
            <div className="row">
              {
                props.inventory.product_final.photo.length > 0 ?
                props.inventory.product_final.photo.map((photo, index) => (
                  <div className="col-12 col-md-6 mb-3 align-items-center">
                    <img src={photo.file_name} width="100%"/>
                  </div>
                ))
                :
                props.inventory.product_final.product.photo.map((photo, index) => (
                  <div className="col-12 col-md-6 mb-3 align-items-center">
                    <img src={photo.file_name} width="100%"/>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="d-block d-lg-flex justify-content-between mb-3 mb-lg-0">
              <p className="m-0">{base.i18n.t('sku')}</p>
              <p className="m-0">{props.inventory.product_final.id}</p>
            </div>
            <div className="d-block d-lg-flex justify-content-between mb-3 mb-lg-0">
              <p className="m-0">{base.i18n.t('product_name')}</p>
              <p className="m-0">{props.inventory.product_name}</p>
            </div>
            <div className="d-block d-lg-flex justify-content-between mb-3 mb-lg-0">
              <p className="m-0">{base.i18n.t('amount')}</p>
              <p className="m-0">{props.inventory.amount.toLocaleString(base.locale_string)} Pcs</p>
            </div>
            <div className="d-block d-lg-flex justify-content-between mb-3 mb-lg-0">
              <p className="m-0">{base.i18n.t('discount')}</p>
              <p className="m-0">{props.inventory.product_final.discount != null ? props.inventory.product_final.discount.discount + '%' : '0%'}</p>
            </div>
            <div className="d-block d-lg-flex justify-content-between mb-3 mb-lg-0">
              <p className="m-0">{base.i18n.t('price')}</p>
              <p className="m-0">{props.inventory.product_final.currency.name} {props.inventory.product_final.price.toLocaleString(base.locale_string)}</p>
            </div>
            {
              props.inventory.product_final.discount != null ?
              <div className="d-block d-lg-flex justify-content-between mb-3 mb-lg-0">
                <p className="m-0">{base.i18n.t('price_after_discount')}</p>
                <p className="m-0">{props.inventory.product_final.currency.name} {props.inventory.product_final.price_after_discount.toLocaleString(base.locale_string)}</p>
              </div>
              :
              <></>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
