import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../../../utils/base'

export default function ShippingInfo(props) {
  var base = new Base()

  return (
    <div className="card">
      <div className="card-header">
        <h5>{base.i18n.t('shipping_info')}</h5>
      </div>
      <div className="card-body">
        <div className="form-group">
          <label>{base.i18n.t('weight')}</label>
          <div className="input-group">
            <input type="text" className="form-control" onChange={e => props.onChange('weight', e.target.value)} value={props.weight}/>
            <div className="input-group-append">
              <div className="input-group-text">gr</div>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label>{base.i18n.t('dimension')}</label>
          <div className="form-row">
            <div className="col input-group mr-2">
              <input type="text" className="form-control" onChange={e => props.onChange('width', e.target.value)} value={props.width}/>
              <div className="input-group-append">
                <div className="input-group-text">cm</div>
              </div>
            </div>
            <div className="col-auto d-flex align-items-center mr-2 p-0">X</div>
            <div className="col input-group mr-2">
              <input type="text" className="form-control" onChange={e => props.onChange('height', e.target.value)} value={props.height}/>
              <div className="input-group-append">
                <div className="input-group-text">cm</div>
              </div>
            </div>
            <div className="col-auto d-flex align-items-center mr-2 p-0">X</div>
            <div className="col input-group">
              <input type="text" className="form-control" onChange={e => props.onChange('length', e.target.value)} value={props.length}/>
              <div className="input-group-append">
                <div className="input-group-text">cm</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
