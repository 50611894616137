import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../../utils/base'
import OverallInfo from './info_component/overall_info'
import Photo from './info_component/photo'
import ShippingInfo from './info_component/shipping_info'

export default function Info(props) {
  var base = new Base()

  return (
    <div className="row">
      <div className="col-12 col-lg-6 mb-3">
        <OverallInfo
          name={props.name}
          description={props.description}
          is_publish={props.is_publish}
          category={props.category}
          currency={props.currency}
          onChange={(key, value) => props.onChange(key, value)}/>
      </div>
      <div className="col-12 col-lg-6 mb-3">
        <ShippingInfo
          weight={props.weight}
          width={props.width}
          height={props.height}
          length={props.length}
          onChange={(key, value) => props.onChange(key, value)}/>
      </div>
      <div className="col-12 mb-3">
        <Photo
          data={props.arr_photo}
          onDelete={index => props.onDeletePhoto(index)}
          onChangePrimary={index => props.onChangePrimaryPhoto(index)}
          onChange={arr => props.onChangePhoto(arr)}/>
      </div>
    </div>
  );
}
