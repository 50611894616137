import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../../../utils/base'
import CustomTable from '../../../../layout/custom_table/index'

export default function ChooseCategory(props) {
  var base = new Base()
  const [arr_header, set_arr_header] = useState([
    {
      label: base.i18n.t('image'),
      data: 'file_name',
      type: 'image',
    },
    {
      label: base.i18n.t('name'),
      data: 'name',
    },
  ])
  const [arr, set_arr] = useState([])

  function on_update_data(arr){
    arr.forEach((data, index) => {
      if(data.file_name != null)
        data.file_name = base.host + '/image/category?file_name=' + data.file_name
    })
    set_arr(arr)
  }

  function on_choose(index){
    props.on_choose(arr[index])
    window.$('#chooseCategoryModal').modal('hide')
  }

  return (
    <div className="modal fade" id="chooseCategoryModal" data-keyboard="false" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">{base.i18n.t('choose_category')}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <CustomTable
              url='/category'
              arr_header={arr_header}
              arr={arr}
              text_action={base.i18n.t('choose')}
              on_action_clicked={index => on_choose(index)}
              on_update_data={data => on_update_data(data)}/>
          </div>
        </div>
      </div>
    </div>
  );
}
