import { useEffect, useState } from 'react';

import Base from '../../../utils/base'
import CustomTable from '../../../layout/custom_table/index'

export default function Courier() {
  var base = new Base()
  const [arr_header, set_arr_header] = useState([
    {
      label: base.i18n.t('image'),
      data: 'file_name',
      type: 'image',
    },
    {
      label: base.i18n.t('code'),
      data: 'code',
    },
    {
      label: base.i18n.t('name'),
      data: 'name',
    },
    {
      label: base.i18n.t('status_enabled'),
      data: 'status_enabled',
    },
  ])
  const [arr, set_arr] = useState([])

  function on_update_data(arr){
    arr.forEach((data, index) => {
      if(data.file_name != null)
        data.file_name = base.host + '/image/courier?file_name=' + data.file_name
      data.status_enabled = data.is_enabled == 1 ? base.i18n.t('enabled') : base.i18n.t('disabled')
    })
    set_arr(arr)
  }

  return (
    <div>
      <h3>{base.i18n.t('courier')}</h3>
      <CustomTable
        url='/courier'
        arr_header={arr_header}
        arr={arr}
        url_update='/courier/action'
        on_update_data={data => on_update_data(data)}/>
    </div>
  );
}
