import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import SettingGeneralPage from '../../pages/master/setting/general'
import SettingShippingDiscountPage from '../../pages/master/setting/shipping_discount'

export default function SettingRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/general`} component={SettingGeneralPage} />
      <Route exact path={`${props.match.path}/shipping-discount`} component={SettingShippingDiscountPage} />
    </>
  )
}
