import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import BlogTypePage from '../../pages/master/blog_home_type/index'
import BlogTypeActionPage from '../../pages/master/blog_home_type/action'

export default function BlogTypeRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={BlogTypePage} />
      <Route exact path={`${props.match.path}/action`} component={BlogTypeActionPage} />
    </>
  )
}
