import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../utils/base'

export default function InventoryAction(props) {
  var base = new Base()
  const [query, set_query] = useState({})
  const [data, set_data] = useState({})
  const [message, set_message] = useState('')
  const [sender, set_sender] = useState({})
  const [file_name, set_file_name] = useState('')
  const [image, set_image] = useState('')

  useEffect(() => {
    var query1 = queryString.parse(props.location.search)
    set_query(query1)

    get_data()
  }, [])

  function get_data() {
    var queryParams = queryString.parse(props.location.search)
    base.request(base.host + '/customer-service?id=' + queryParams.id)
      .then(response => {
        if(response != null && response.status === 'success'){
          set_data(response.data)
          set_message(response.data.message)
          set_file_name(base.host + '/image/customer-service?file_name=' + response.data.file_name)
        }
      })
  }

  return (
    <div>
      <h3>{base.i18n.t('detail_customer_service')}</h3>

    </div>
  );
}
