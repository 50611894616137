import { useEffect, useState } from 'react';
import moment from 'moment'

import Base from '../../../utils/base'
import CustomTable from '../../../layout/custom_table/index'

export default function Discount() {
  var base = new Base()
  const [arr_header, set_arr_header] = useState([
    {
      label: base.i18n.t('name'),
      data: 'name',
    },
    {
      label: base.i18n.t('discount'),
      data: 'discount',
    },
    {
      label: base.i18n.t('start_date'),
      data: 'start_date',
    },
    {
      label: base.i18n.t('end_date'),
      data: 'end_date',
    },
  ])
  const [arr, set_arr] = useState([])
  const [is_active, set_is_active] = useState(false)

  function on_update_data(arr){
    arr.forEach((data, index) => {
      data.discount = data.type_discount === 'percentage' ? data.discount.toLocaleString(base.locale_string) + '%' : "IDR " + data.discount.toLocaleString(base.locale_string)
      data.start_date = moment(data.start_date).format('DD MMMM YYYY')
      data.end_date = moment(data.end_date).format('DD MMMM YYYY')
      data.allow_update = false
      data.allow_delete = false

      var start_date_var = moment(data.start_date)
      var end_date_var = moment(data.end_date)
      if(moment().isBefore(end_date_var)){
        data.allow_update = true
        data.allow_delete = true
      }
    })
    set_arr(arr)
  }

  return (
    <div>
      <h3>{base.i18n.t('discount')}</h3>
      <CustomTable
        url='/discount'
        arr_header={arr_header}
        arr={arr}
        url_update='/discount/action'
        url_create='/discount/action'
        url_delete='/discount'
        text_delete={base.i18n.t('end')}
        on_update_data={data => on_update_data(data)}/>
    </div>
  );
}
