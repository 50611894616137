import { useEffect, useState } from 'react';
import queryString from "query-string";
import moment from 'moment'

import Base from '../../../utils/base'

export default function OrderAddress(props) {
  var base = new Base()

  return (
    <div className="card">
      <div className="card-header">
        {base.i18n.t('shipping_address')}
      </div>
      <div className="card-body">
        <div className="form-row">
          <div className="form-group col-12">
            <label>{base.i18n.t('address')}</label>
            <textarea disabled className="form-control" value={props.data.address != null ? props.data.address : '-'}></textarea>
          </div>
          <div className="form-group col-12">
            <label>{base.i18n.t('detail_address')}</label>
            <textarea disabled className="form-control" value={props.data.detail_address != null ? props.data.detail_address : '-'}></textarea>
          </div>
          <div className="form-group col-12 col-md-6">
            <label>{base.i18n.t('city')}</label>
            <input type="text" disabled className="form-control" value={props.data.city.name}/>
          </div>
          <div className="form-group col-12 col-md-6">
            <label>{base.i18n.t('province')}</label>
            <input type="text" disabled className="form-control" value={props.data.city.province.name}/>
          </div>
          <div className="form-group col-12 col-md-6">
            <label>{base.i18n.t('country')}</label>
            <input type="text" disabled className="form-control" value={props.data.city.province.country.name}/>
          </div>
        </div>
      </div>
    </div>
  );
}
