import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../utils/base'

export default function ChangePassword(props) {
  var base = new Base()
  const [data, set_data] = useState({})
  const [current_password, set_current_password] = useState('')
  const [new_password, set_new_password] = useState('')
  const [confirm_password, set_confirm_password] = useState('')
  const [first_time, set_first_time] = useState(true)
  const [query, set_query] = useState({})

  useEffect(() => {
    if(!first_time){
      base.limit_data_length(current_password, set_current_password)
      base.limit_data_length(new_password, set_new_password)
      base.limit_data_length(confirm_password, set_confirm_password)
    }
  }, [current_password, new_password, confirm_password])

  useEffect(() => {
    var query1 = queryString.parse(props.location.search)
    set_query(query1)

    // if(query1.id != null)
      get_data()
  }, [])

  function get_data() {
    var queryParams = queryString.parse(props.location.search)
    base.request(base.host + '/auth/profile').then(response => {
      if(response.status === 'success'){
        set_data(response.data)
      }
    })
  }

  function submit() {
    if(current_password === '')
      base.notify_user(base.i18n.t('current_password_empty'))
    else if(new_password === '')
      base.notify_user(base.i18n.t('new_password_empty'))
    else if(confirm_password === '')
      base.notify_user(base.i18n.t('confirm_password_empty'))
    else if(confirm_password !== new_password)
      base.notify_user(base.i18n.t('confirm_password_not_same'))
    else{
      var temp = {
        old_password: current_password,
        new_password: new_password,
      }

      base.request(base.host + '/auth/change-password', 'put', temp).then(response => {
        if(response != null && response.status === 'success'){
          window.location.href = "/auth/profile"
        }
      })
    }
  }

  return (
    <div>
      <h3>{base.i18n.t('change_password')}</h3>
      <div className="row">
        <div className="col-12 mb-3">
          <div className="card">
            <div className="card-body">
              <div className="form-group">
                <label>{base.i18n.t('current_password')}</label>
                <input type="password" className="form-control" onChange={e => set_current_password(e.target.value)}/>
              </div>
              <div className="form-group">
                <label>{base.i18n.t('new_password')}</label>
                <input type="password" className="form-control" onChange={e => set_new_password(e.target.value)}/>
              </div>
              <div className="form-group">
                <label>{base.i18n.t('confirm_password')}</label>
                <input type="password" className="form-control" onChange={e => set_confirm_password(e.target.value)}/>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-3">
          <button className="btn btn-primary" onClick={() => submit()}>{base.i18n.t('submit')}</button>
        </div>
      </div>
    </div>
  );
}
