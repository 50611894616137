import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import VoucherPage from '../../pages/master/voucher/index'
import VoucherActionPage from '../../pages/master/voucher/action'

export default function VoucherRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={VoucherPage} />
      <Route exact path={`${props.match.path}/action`} component={VoucherActionPage} />
    </>
  )
}
