import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../../utils/base'
import BannerItem from './banner_item'
import PleaseWaitWithProgressModal from '../../../layout/modal/please_wait_with_progress_modal'

export default function Banner(props) {
  var base = new Base()
  const [arr, set_arr] = useState([])
  const [progress, set_progress] = useState(0)

  useEffect(() => {
    get_data()
  }, [])

  function get_data() {
    base.request(base.host + '/banner/all')
      .then(response => {
        if(response != null && response.status === 'success'){
          response.data.forEach((data, index) => {
            data.file_name = base.host + '/image/banner?file_name=' + data.file_name
          })
          set_arr(response.data)
        }
      })
  }

  function on_upload_image(e, is_edit = false, index = 0){
    var arr_temp = JSON.parse(JSON.stringify(arr))
    Array.from(e.target.files).forEach((data, index) => {
      var image = URL.createObjectURL(data)
      var temp = !is_edit ? {} : JSON.parse(JSON.stringify(arr[index]))
      temp.file_name = image
      if(temp.is_publish == null)
        temp.is_publish = 0
      if(temp.is_primary == null)
        temp.is_primary = arr.length === 0 && index === 0 ? 1 : 0

      base.getBase64(data, result => {
        temp.image = {
          image: result
        }
      })

      if(!is_edit)
        arr_temp.push(temp)
      else
        arr_temp[index] = temp
    })
    set_arr(arr_temp)
  }

  function on_change_publish(index, is_publish) {
    var temp = JSON.parse(JSON.stringify(arr[index]))
    temp.is_publish = is_publish
    base.update_array(arr, set_arr, temp, index)
  }

  function on_change_primary(index) {
    var temp_arr = JSON.parse(JSON.stringify(arr))
    temp_arr.forEach((temp, index) => {
      temp.is_primary = 0
    })
    temp_arr[index].is_primary = 1
    set_arr(temp_arr)
  }

  function on_delete(index) {
    base.remove_array(arr, set_arr, index)
  }

  function submit() {
    if(arr.length === 0)
      base.notify_user(base.i18n.t("banner_empty"))
    else{
      var temp = {
        arr_banner: arr,
      }

      base.request(base.host + '/banner', 'post', temp,
        response => {
          set_progress(Math.round((100 * response.loaded) / response.total))
        }, true)
        .then(response => {
          set_progress(0)
          if(response != null && response.status == 'success'){
            // get_data()
          }
        })
    }
  }

  return (
    <div>
      <PleaseWaitWithProgressModal progress={progress}/>
      <h3>{base.i18n.t('banner')}</h3>
      <div>
        <input type="file" className="d-none" id="inputFile" multiple onChange={e => on_upload_image(e)}/>
        <button className="btn btn-primary" onClick={e => window.$('#inputFile').trigger('click')}>{base.i18n.t('upload_file')}</button>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <div className="row">
            {
              arr.length > 0 ?
              arr.map((banner, index) => (
                <div className="col-12 col-md-6 col-lg-3 mb-3" key={index}>
                  <BannerItem
                    index={index}
                    data={banner}
                    on_change_publish={is_publish => on_change_publish(index, is_publish)}
                    on_change_primary={() => on_change_primary(index)}
                    on_upload_image={e => on_upload_image(e, true, index)}
                    on_delete={() => on_delete(index)}/>
                </div>
              ))
              :
              <div className="col text-center">{base.i18n.t('no_data_found')}</div>
            }
          </div>
        </div>
      </div>
      <div className="mt-3">
        <button className="btn btn-primary" onClick={() => submit()}>{base.i18n.t('submit')}</button>
      </div>
    </div>
  );
}
