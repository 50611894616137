import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import DiscountPage from '../../pages/master/discount/index'
import DiscountActionPage from '../../pages/master/discount/action'

export default function DiscountRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={DiscountPage} />
      <Route exact path={`${props.match.path}/action`} component={DiscountActionPage} />
    </>
  )
}
