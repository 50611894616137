import { useEffect, useState } from 'react';
import queryString from "query-string";
import moment from 'moment'

import Base from '../../../utils/base'

export default function OrderPayment(props) {
  var base = new Base()

  return (
    <div className="card">
      <div className="card-header">
        {base.i18n.t('payment_info')}
      </div>
      <div className="card-body row">
        <div className="form-group col-12 col-md-6">
          <label>{base.i18n.t('total_price')}</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">IDR</div>
            </div>
            <input type="text" disabled className="form-control" value={props.data.grand_total.toLocaleString(base.locale_string)}/>
          </div>
        </div>
        <div className="form-group col-12 col-md-6">
          <label>{base.i18n.t('payment_method')}</label>
          <input type="text" disabled className="form-control" value={props.data.payment_method.name}/>
        </div>
        <div className="form-group col-12 col-md-6">
          <label>{base.i18n.t('status_payment')}</label>
          <input type="text" disabled className="form-control" value={props.data.status_payment}/>
        </div>
        <div className="form-group col-12 col-md-6">
          <label>{base.i18n.t('voucher')}</label>
          <input type="text" disabled className="form-control" value={props.data.voucher != null ? props.data.voucher.code : '-'}/>
        </div>
      </div>
    </div>
  );
}
