import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../utils/base'
import ProductActionHelper from './action_helper'
import Info from './component/info'
import Variant from './component/variant'
import PleaseWaitWithProgressModal from '../../layout/modal/please_wait_with_progress_modal'
import ConfirmationModal from '../../layout/modal/confirmation_modal'

export default function ProductAction(props) {
  var base = new Base()
  var product_action_helper = new ProductActionHelper()
  const [data_product, set_data_product] = useState({})
  const [name, set_name] = useState('')
  const [description, set_description] = useState('')
  const [sku, set_sku] = useState('')
  const [weight, set_weight] = useState('0')
  const [width, set_width] = useState('0')
  const [height, set_height] = useState('0')
  const [length, set_length] = useState('0')
  const [price, set_price] = useState('0')
  const [stock, set_stock] = useState('0')
  const [currency, set_currency] = useState('CURRENCY_0001')
  const [is_publish, set_is_publish] = useState(0)
  const [category, set_category] = useState('')
  const [arr_photo, set_arr_photo] = useState([])
  const [progress, set_progress] = useState(0)
  const [arr_variant, set_arr_variant] = useState([])
  const [arr_variant_price, set_arr_variant_price] = useState([])
  const [query, set_query] = useState({})
  const [arr_tabs, set_arr_tabs] = useState([])
  const [first_time, set_first_time] = useState(true)
  const [is_variant_enable, set_is_variant_enable] = useState(false)

  useEffect(() => {
    if(!first_time){

      limit_data_length()
      base.to_currency_format(weight, set_weight)
      base.to_currency_format(width, set_width)
      base.to_currency_format(height, set_height)
      base.to_currency_format(length, set_length)
      base.to_currency_format(price, set_price)
      base.to_currency_format(stock, set_stock)
      reload_tabs()
    }
  }, [arr_variant_price, arr_photo, name, description, is_publish, category, weight, width, height, length, price, stock, is_variant_enable, data_product, currency, sku,])

  useEffect(() => {
    if(!first_time){
      var arr_temp = JSON.parse(JSON.stringify(arr_variant))
      set_arr_variant_price(product_action_helper.manage_arr_variant_price(arr_variant_price, arr_temp))
      reload_tabs()
    }
  }, [arr_variant,])

  useEffect(() => {
    reload_tabs()

    setTimeout(() => {
      var query1 = queryString.parse(props.location.search)
      set_query(query1)

      if(query1.id != null)
        get_data()
      else{
        get_currency_data()
      }
    },500)
  }, [])

  function limit_data_length(){
    base.limit_data_length(name, set_name)
    base.limit_data_length(weight, set_weight)
    base.limit_data_length(width, set_width)
    base.limit_data_length(height, set_height)
    base.limit_data_length(length, set_length)
    base.limit_data_length(price, set_price)
    base.limit_data_length(stock, set_stock)
  }

  function reload_tabs(){
    set_arr_tabs([
      {
        id: 'info',
        label: base.i18n.t('info'),
        component: <Info
          name={name}
          description={description}
          currency={currency}
          is_publish={is_publish}
          category={category}
          weight={weight}
          width={width}
          height={height}
          length={length}
          arr_photo={arr_photo}
          onChange={(key, value) => on_info_change(key, value)}
          onChangePhoto={arr => on_change_photo(arr)}
          onChangePrimaryPhoto={index => on_change_primary_photo(index)}
          onDeletePhoto={index => on_delete_photo(index)}/>,
      },
      {
        id: 'variant',
        label: base.i18n.t('variant'),
        component: <Variant
          sku={sku}
          price={price}
          stock={stock}
          currency={currency}
          data_product={data_product}
          is_variant_enable={is_variant_enable}
          arr_variant={arr_variant}
          arr_variant_price={arr_variant_price}
          onChangeVariantEnable={() => set_is_variant_enable(!is_variant_enable)}
          onChange={(key, value) => on_info_change(key, value)}
          onChangeNameVariant={(index, value) => on_change_name_variant(index, value)}
          onAddPhotoVariant={(index, arr) => on_add_photo_variant(index, arr)}
          onDeletePhotoVariant={(index, index1) => on_delete_photo_variant(index, index1)}
          onChangePrimaryPhotoVariant={(index, index1) => on_change_primary_photo_variant(index, index1)}
          onChangeNameSubVariant={(index, index1, value) => on_change_name_sub_variant(index, index1, value)}
          onAddSubVariant={index => on_add_sub_variant(index)}
          onDeleteSubVariant={(index, index1) => on_delete_sub_variant(index, index1)}
          onAddVariant={() => on_add_variant()}
          onDeleteVariant={index => on_delete_variant(index)}
          onChangePriceSubVariant={(index, value) => on_change_price_sub_variant(index, value)}
          onChangeSkuSubVariant={(index, value) => on_change_sku_sub_variant(index, value)}
          onChangeStockSubVariant={(index, value) => on_change_stock_sub_variant(index, value)}
          onChangeCurrencySubVariant={(index, value) => on_change_currency_sub_variant(index, value)}
          onAddPhotoFinal={(index, arr) => on_add_photo_final(index, arr)}
          onDeletePhotoFinal={(index, index1) => on_delete_photo_final(index, index1)}
          onChangePrimaryPhotoFinal={(index, index1) => on_change_primary_photo_final(index, index1)}/>,
      },
    ])
  }

  function on_info_change(key, value) {
    if(key == 'name')
      set_name(value)
    else if(key == 'description')
      set_description(value)
    else if(key == 'is_publish')
      set_is_publish(value)
    else if(key == 'category')
      set_category(value)
    else if(key == 'weight')
      set_weight(value)
    else if(key == 'width')
      set_width(value)
    else if(key == 'length')
      set_length(value)
    else if(key == 'height')
      set_height(value)
    else if(key == 'stock')
      set_stock(value)
    else if(key == 'price')
      set_price(value)
    else if(key == 'currency')
      set_currency(value)
    else if(key == 'sku')
      set_sku(value)
  }

  function on_change_photo(arr) {
    set_arr_photo(product_action_helper.on_change_photo(arr_photo, arr))
  }

  function on_change_primary_photo(index) {
    set_arr_photo(product_action_helper.on_change_primary_photo(arr_photo, index))
  }

  function on_delete_photo(index) {
    set_arr_photo(product_action_helper.on_delete_photo(arr_photo, index))
  }

  function on_change_name_variant(index, value){
    set_arr_variant(product_action_helper.on_change_name_variant(arr_variant, index, value))
  }

  function on_add_photo_variant(index, arr){
    set_arr_variant(product_action_helper.on_add_photo_variant(arr_variant, index, arr))
  }

  function on_delete_photo_variant(index, index1){
    set_arr_variant(product_action_helper.on_delete_photo_variant(arr_variant, index, index1))
  }

  function on_change_primary_photo_variant(index, index1){
    set_arr_variant(product_action_helper.on_change_primary_photo_variant(arr_variant, index, index1))
  }

  function on_add_photo_final(index, arr){
    set_arr_variant_price(product_action_helper.on_add_photo_variant(arr_variant_price, index, arr))
  }

  function on_delete_photo_final(index, index1){
    set_arr_variant_price(product_action_helper.on_delete_photo_variant(arr_variant_price, index, index1))
  }

  function on_change_primary_photo_final(index, index1){
    set_arr_variant_price(product_action_helper.on_change_primary_photo_variant(arr_variant_price, index, index1))
  }

  function on_change_name_sub_variant(index, index1, value){
    set_arr_variant(product_action_helper.on_change_name_sub_variant(arr_variant, index, index1, value))
  }

  function on_change_price_sub_variant(index, value){
    set_arr_variant_price(product_action_helper.on_change_price_sub_variant(arr_variant_price, index, value))
  }

  function on_change_sku_sub_variant(index, value){
    set_arr_variant_price(product_action_helper.on_change_sku_sub_variant(arr_variant_price, index, value))
  }

  function on_change_stock_sub_variant(index, value){
    set_arr_variant_price(product_action_helper.on_change_stock_sub_variant(arr_variant_price, index, value))
  }

  function on_change_currency_sub_variant(index, value){
    set_arr_variant_price(product_action_helper.on_change_currency_sub_variant(arr_variant_price, index, value))
  }

  function on_add_sub_variant(index){
    set_arr_variant(product_action_helper.on_add_sub_variant(arr_variant, index))
  }

  function on_add_variant(){
    base.add_array(arr_variant, set_arr_variant, {
      name: '',
      arr_photo: [],
      arr_sub_variant: [
        {
          name: '',
        }
      ],
    })
  }

  function on_delete_variant(index){
    base.remove_array(arr_variant, set_arr_variant, index)
  }

  function on_delete_sub_variant(index, index1){
    set_arr_variant(product_action_helper.on_delete_sub_variant(arr_variant, index, index1))
  }

  function get_currency_data() {
    base.request(base.host + '/currency?name=idr').then(response => {
      if(response != null && response.status === 'success'){
        set_currency(response.data.id)
        var arr_temp = [
          {
            name: '',
            arr_photo: [],
            arr_sub_variant: [
              {
                name: '',
                price: '0',
                stock: '0',
                currency: response.data,
              }
            ],
          }
        ]
        set_first_time(false)
        set_arr_variant(arr_temp)
      }
    })
  }

  function get_data() {
    var queryParams = queryString.parse(props.location.search)
    base.request(base.host + '/product?id=' + queryParams.id).then(response => {
      if(response != null && response.status === 'success'){
        response.data.arr_photo.forEach((data, index) => {
          data.file_name = base.host + '/image/product?file_name=' + data.file_name
        })

        response.data.arr_variant.forEach((data, index) => {
          data.arr_sub_variant.forEach((sub_variant, index1) => {
            sub_variant.arr_photo.forEach((photo, index2) => {
              photo.file_name = base.host + '/image/product?file_name=' + photo.file_name
            })
          })
          data.arr_photo.forEach((photo, index1) => {
            photo.file_name = base.host + '/image/product?file_name=' + photo.file_name
          })
        })

        response.data.product_final.forEach((data, index) => {
          data.price = data.price.toLocaleString(base.locale_string)
          data.stock = data.stock.toLocaleString(base.locale_string)
          data.is_stock_enabled = false

          data.arr_photo.forEach((photo, index1) => {
            photo.file_name = base.host + '/image/product?file_name=' + photo.file_name
          })
        })
        response.data.is_variant_enable = response.data.arr_variant.length > 0

        set_arr_variant_price(response.data.product_final)
        set_price(response.data.arr_variant.length > 0 ? '0' : response.data.price.toLocaleString(base.locale_string))
        set_stock(response.data.arr_variant.length > 0 ? '0' : response.data.product_final[0].inventory.amount.toLocaleString(base.locale_string))
        set_sku(response.data.product_final[0].sku)
        set_name(response.data.name)
        set_description(response.data.description)
        set_is_publish(response.data.is_publish)
        set_category(response.data.category_id)
        set_weight(response.data.weight.toLocaleString(base.locale_string))
        set_height(response.data.height.toLocaleString(base.locale_string))
        set_width(response.data.width.toLocaleString(base.locale_string))
        set_length(response.data.length.toLocaleString(base.locale_string))
        set_is_variant_enable(response.data.arr_variant.length > 0)
        set_currency(response.data.currency.id)
        set_data_product(response.data)


        set_arr_photo(response.data.arr_photo)
        // setTimeout(() => {
          set_first_time(false)
          set_arr_variant(response.data.arr_variant.length > 0 ? response.data.arr_variant : [
            {
              name: '',
              arr_photo: [],
              arr_sub_variant: [
                {
                  name: '',
                }
              ],
            }
          ])

        // }, 100)
      }
    })
  }

  function submit() {
    if(name === '')
      base.notify_user(base.i18n.t("name_empty"))
    else if(category === '')
      base.notify_user(base.i18n.t("category_empty"))
    else if(weight === '0')
      base.notify_user(base.i18n.t("weight_empty"))
    else if(!is_variant_enable && data_product.id == null && price === '0')
      base.notify_user(base.i18n.t("price_empty"))
    else if(!is_variant_enable && sku === '')
      base.notify_user(base.i18n.t("sku_empty"))
    else if(!is_variant_enable && currency === '')
      base.notify_user(base.i18n.t("currency_empty"))
    else if(arr_photo.length === 0)
      base.notify_user(base.i18n.t("photo_empty"))
    else if(is_variant_enable && (!product_action_helper.check_arr_variant(arr_variant) || !product_action_helper.check_arr_variant_price(arr_variant_price)))
      return
    else{
      var queryParams = queryString.parse(props.location.search)

      var arr_variant_price_temp = JSON.parse(JSON.stringify(arr_variant_price))
      arr_variant_price_temp.forEach((variant_price, index) => {
        variant_price.price = parseFloat(variant_price.price.replace(/\./g,''))
        variant_price.amount = parseFloat(variant_price.stock.replace(/\./g,''))
      })

      var temp = {
        name: name,
        description: description,
        category: {
          id: category
        },
        currency: {
          id: currency,
        },
        price: parseFloat(price.replace(/\./g,'')),
        amount: parseFloat(stock.replace(/\./g,'')),
        weight: parseFloat(weight.replace(/\./g,'')),
        width: parseFloat(width.replace(/\./g,'')),
        length: parseFloat(length.replace(/\./g,'')),
        height: parseFloat(height.replace(/\./g,'')),
        is_publish: is_publish,
        arr_variant: arr_variant,
        arr_inventory: is_variant_enable ? arr_variant_price_temp : [
          {
            sku: sku,
            price: parseFloat(price.replace(/\./g,'')),
            amount: parseFloat(stock.replace(/\./g,'')),
            arr_sub_variant: [],
          }
        ],
        arr_photo: arr_photo,
        is_variant_enable: is_variant_enable,
      }
      if(data_product.id != null)
        temp.id = data_product.id

      base.request(base.host + '/product', queryParams.id == null ? 'post' : 'put', temp, 'application/json',
        response => {
          set_progress(Math.round((100 * response.loaded) / response.total))
        }, true)
        .then(response => {
          if(response != null && response.status == 'success'){
            window.location.href = "/product"
          }
        })
    }
  }

  function on_cancel_confirmation(){
    window.$('#confirmationModal').modal('hide')
  }

  function on_delete(){
    window.$('#confirmationModal').modal('show')
  }

  function on_confirmation(){
    window.$('#confirmationModal').modal('hide')
    base.request(base.host + "/product?id=" + data_product.id, "delete").then((response) => {
      window.location.href = '/product'
    })
  }

  return (
    <div>
      <PleaseWaitWithProgressModal progress={progress}/>
      <ConfirmationModal
        on_cancel_click={() => on_cancel_confirmation()}
        on_confirm_click={() => on_confirmation()}/>
      <h3>{query.id != null ? base.i18n.t('edit_product') : base.i18n.t('add_product')}</h3>
      {
        data_product.allow_delete ?
        <div className="mb-3">
          <button className="btn btn-danger" onClick={() => on_delete()}>{base.i18n.t('delete')}</button>
        </div>
        :
        <></>
      }
      <ul className="nav nav-pills mb-3" id="myTab" role="tablist">
        {
          arr_tabs.map((data, index) => (
            <li className="nav-item" role="presentation" key={index}>
              <a className={"nav-link" + (index == 0 ? ' active' : '')} id={data.id+'-tab'} data-toggle="tab" href={'#'+data.id} role="tab" aria-controls={'nav-'+data.id} aria-selected="false">{data.label}</a>
            </li>
          ))
        }
      </ul>
      <div className="tab-content mb-3" id="myTabContent">
        {
          arr_tabs.map((data, index) => (
            <div className={"tab-pane fade" + (index == 0 ? ' show active' : '')} key={index} id={data.id} role="tabpanel" aria-labelledby={data.id + '-tab'}>{data.component}</div>
          ))
        }
      </div>
      <div className="mb-3">
        <button className="btn btn-primary" onClick={() => submit()}>{base.i18n.t('submit')}</button>
      </div>
    </div>
  );
}
