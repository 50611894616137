import { useEffect, useState } from 'react';

import Base from '../../../../utils/base'
import CustomTable from '../../../../layout/custom_table/index'

export default function BlogContact() {
  var base = new Base()
  const [arr_header, set_arr_header] = useState([
    {
      label: base.i18n.t('title'),
      data: 'title',
    },
    {
      label: base.i18n.t('status_publish'),
      data: 'status_publish',
    },
  ])
  const [arr, set_arr] = useState([])

  function on_update_data(arr){
    arr.forEach((data, index) => {
      if(data.title == null || data.title === '')
        data.title = '-'
      data.status_publish = data.is_publish === 1 ? 'Published' : 'Unpublished'
    })
    set_arr(arr)
  }

  return (
    <div>
      <h3>{base.i18n.t('contact')}</h3>
      <CustomTable
        url='/blog/home?blog_home_type_name=contact'
        arr_header={arr_header}
        arr={arr}
        url_update='/blog/contact/action'
        on_update_data={data => on_update_data(data)}/>
    </div>
  );
}
