import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import BlogCollectionPage from '../../pages/master/blog_content/collection/index'
import BlogCollectionActionPage from '../../pages/master/blog_content/collection/action'

export default function BlogCollectionRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={BlogCollectionPage} />
      <Route exact path={`${props.match.path}/action`} component={BlogCollectionActionPage} />
    </>
  )
}
