import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import CustomerServicePage from '../pages/customer_service/index'
import CustomerServiceActionPage from '../pages/customer_service/action'

export default function CustomerServiceRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={CustomerServicePage} />
      <Route exact path={`${props.match.path}/action`} component={CustomerServiceActionPage} />
    </>
  )
}
