import { useEffect, useState } from 'react';

import Base from '../utils/base'

export default function Sidebar(props) {
  var base = new Base()
  const [arr] = useState([
    {
      id: 'dashboard',
      name: base.i18n.t('dashboard'),
      href: '/',
    },
    {
      id: 'web_content',
      name: base.i18n.t('web_content'),
      href: '#',
      arr: [
        // {
        //   id: 'banner',
        //   name: base.i18n.t('banner'),
        //   href: '/banner',
        // },
        {
          id: 'blog',
          name: base.i18n.t('blog'),
          href: '/blog',
        },
        {
          id: 'blog_type',
          name: base.i18n.t('blog_type'),
          href: '/blog/type',
        },
        {
          id: 'blog_home',
          name: base.i18n.t('blog_home'),
          href: '/blog/home',
        },
        {
          id: 'blog_in_press',
          name: base.i18n.t('blog_in_press'),
          href: '/blog/in-press',
        },
        {
          id: 'sustainability',
          name: base.i18n.t('sustainability'),
          href: '/blog/sustainability',
        },
        {
          id: 'collection',
          name: base.i18n.t('collection'),
          href: '/blog/collection',
        },
        {
          id: 'about_us',
          name: base.i18n.t('about_us'),
          href: '/blog/about-us',
        },
        {
          id: 'promo',
          name: base.i18n.t('promo'),
          href: '/blog/promo',
        },
        {
          id: 'contact',
          name: base.i18n.t('contact'),
          href: '/blog/contact',
        },
        {
          id: 'store_policy',
          name: base.i18n.t('store_policy'),
          href: '/blog/store-policy',
        },
        {
          id: 'blog_payment_method',
          name: base.i18n.t('blog_payment_method'),
          href: '/blog/payment-method',
        },
      ]
    },
    {
      id: 'master',
      name: base.i18n.t('master'),
      href: '#',
      arr: [
        // {
        //   id: 'client',
        //   name: base.i18n.t('client'),
        //   href: '/client',
        // },
        {
          id: 'category',
          name: base.i18n.t('category'),
          href: '/category',
        },
        {
          id: 'courier',
          name: base.i18n.t('courier'),
          href: '/courier',
        },
        {
          id: 'city',
          name: base.i18n.t('city'),
          href: '/city',
        },
        {
          id: 'province',
          name: base.i18n.t('province'),
          href: '/province',
        },
        {
          id: 'country',
          name: base.i18n.t('country'),
          href: '/country',
        },
        {
          id: 'exchange',
          name: base.i18n.t('exchange'),
          href: '/exchange',
        },
        {
          id: 'setting',
          name: base.i18n.t('setting'),
          href: '/setting/general',
        },
      ],
    },
    {
      id: 'report',
      name: base.i18n.t('report'),
      href: '#',
      arr: [
        {
          id: 'sales',
          name: base.i18n.t('sales'),
          href: '/report/sales',
        },
        {
          id: 'ranking',
          name: base.i18n.t('ranking'),
          href: '/report/ranking',
        },
      ]
    },
    {
      id: 'discount',
      name: base.i18n.t('discount'),
      href: '/discount',
    },
    {
      id: 'voucher',
      name: base.i18n.t('voucher'),
      href: '/voucher',
    },
    {
      id: 'shipping_voucher',
      name: base.i18n.t('shipping_voucher'),
      href: '#',
      arr: [
        {
          id: 'shipping_voucher',
          name: base.i18n.t('shipping_voucher'),
          href: '/shipping-voucher',
        },
        {
          id: 'setting_shipping_discount',
          name: base.i18n.t('setting_shipping_discount'),
          href: '/setting/shipping-discount',
        },
      ]
    },
    {
      id: 'product',
      name: base.i18n.t('product'),
      href: '/product',
    },
    {
      id: 'inventory',
      name: base.i18n.t('inventory'),
      href: '/inventory',
    },
    {
      id: 'order',
      name: base.i18n.t('order'),
      href: '/order',
    },
    // {
    //   id: 'customer_service',
    //   name: base.i18n.t('customer_service'),
    //   href: '/customer-service',
    // },
    {
      id: 'user',
      name: base.i18n.t('user'),
      href: '/user',
    },
    {
      id: 'user_admin',
      name: base.i18n.t('user_admin'),
      href: '/user-admin',
    },
  ])

  function check_arr_pathname(temp_index) {
    var flag = false
    for(let x in arr[temp_index].arr){
      if(arr[temp_index].arr[x].href === window.location.pathname){
        flag = true
        break
      }
    }

    return flag
  }

  function on_click_action(data){
    window.localStorage.removeItem('per_page')
    window.localStorage.removeItem('current_page')
    window.localStorage.removeItem('arr_per_page')
    window.localStorage.removeItem('arr_current_page')
    // window.location.href = data.href
  }

  return (
    <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion d-print-none" id="accordionSidebar">
      <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/">
        <div className="sidebar-brand-text mx-3">{base.i18n.t('app_name')}</div>
      </a>
      {
        arr.map((data, index) => (
          <li className={"nav-item" + (window.location.pathname == data.href ? ' active' : '')} key={index}>
            {
              data.arr != null && data.arr.length > 0 ?
              <a className={"nav-link" + (check_arr_pathname(index) ? '' : ' collapsed')}
                onClick={() => on_click_action(data)}
                href={data.href}
                data-toggle="collapse"
                data-target={"#" + data.id}
                aria-expanded="true"
                aria-controls={data.id}>
                <i className="fas fa-fw fa-tachometer-alt"></i>
                <span>{data.name}</span>
              </a>
              :
              <a className={"nav-link" + (window.location.pathname == data.href ? ' active' : '')}
                onClick={() => on_click_action(data)}
                href={data.href} >
                <i className="fas fa-fw fa-tachometer-alt"></i>
                <span>{data.name}</span>
              </a>
            }
            {
              data.arr != null && data.arr.length > 0 ?
              <div id={data.id} className={"collapse" + (check_arr_pathname(index) ? ' show' : '')} aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  {
                    data.arr.map((data1, index1) => (
                      <a className={"collapse-item" + (window.location.pathname == data1.href ? ' active' : '')}
                        onClick={() => on_click_action(data1)}
                        href={data1.href}
                        key={index1}>{data1.name}</a>
                    ))
                  }
                </div>
              </div>
              :
              <></>
            }
          </li>
        ))
      }
      <div className="text-center d-none d-md-inline">
        <button className="rounded-circle border-0" id="sidebarToggle"></button>
      </div>
    </ul>
  );
}
