import { useEffect, useState } from 'react';
import queryString from "query-string";
import moment from 'moment'

import Base from '../../../utils/base'

export default function OrderDetail(props) {
  var base = new Base()

  return (
    <div className="card">
      <div className="card-header">
        {base.i18n.t('detail_order')}
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>{base.i18n.t('product')}</th>
                <th>{base.i18n.t('variant')}</th>
                <th>{base.i18n.t('amount')}</th>
                <th>{base.i18n.t('price')}</th>
                <th>{base.i18n.t('price_in_rupiah')}</th>
                <th>{base.i18n.t('total_price')}</th>
              </tr>
            </thead>
            <tbody>
              {
                props.data.detail.map((data, index) => (
                  <tr key={index}>
                    <td>{data.product.name}</td>
                    <td>{data.product_final.name}</td>
                    <td>{data.amount.toLocaleString(base.locale_string)} Pcs</td>
                    <td>{data.currency.name} {data.price.toLocaleString(base.locale_string)}</td>
                    <td>IDR {data.price_in_rupiah.toLocaleString(base.locale_string)}</td>
                    <td>IDR {(data.price_in_rupiah * data.amount).toLocaleString(base.locale_string)}</td>
                  </tr>
                ))
              }
              <tr>
                <td colSpan="5">{base.i18n.t('total_price')}</td>
                <td>IDR {props.data.total_price.toLocaleString(base.locale_string)}</td>
              </tr>
              <tr>
                <td colSpan="5">{base.i18n.t('shipping_fee')}</td>
                <td>IDR {props.data.shipping_fee.toLocaleString(base.locale_string)}</td>
              </tr>
              <tr>
                <td colSpan="5">{base.i18n.t('discount')}</td>
                <td>IDR {props.data.discount.toLocaleString(base.locale_string)}</td>
              </tr>
              <tr>
                <td colSpan="5">{base.i18n.t('grand_total')}</td>
                <td>IDR {props.data.grand_total.toLocaleString(base.locale_string)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
