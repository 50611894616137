import { useEffect, useState } from 'react';

import Base from '../../utils/base'
import CustomTable from '../../layout/custom_table/index'

export default function Product() {
  var base = new Base()
  const [arr_header, set_arr_header] = useState([
    {
      label: base.i18n.t('image'),
      data: 'file_name',
      type: 'image',
    },
    {
      label: base.i18n.t('name'),
      data: 'name',
    },
    {
      label: base.i18n.t('status_publish'),
      data: 'is_publish',
    },
  ])
  const [arr, set_arr] = useState([])

  function on_update_data(arr){
    base.reset_localstorage()
    arr.forEach((data, index) => {
      data.photo.forEach((photo, index) => {
        if(photo.is_primary === 1 && photo.file_name != null){
          data.file_name = base.host + '/image/product?file_name=' + photo.file_name
          return
        }
      })
      data.is_publish = data.is_publish == 1 ? base.i18n.t('yes') : base.i18n.t('no')
    })
    set_arr(arr)
  }

  return (
    <div>
      <h3>{base.i18n.t('product')}</h3>
      <CustomTable
        url='/product'
        arr_header={arr_header}
        arr={arr}
        url_update='/product/action'
        url_create='/product/action'
        on_update_data={data => on_update_data(data)}/>
    </div>
  );
}
