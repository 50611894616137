import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../../../utils/base'

export default function Photo(props) {
  var base = new Base()

  function delete_data(index){
    props.onDelete(index)
  }

  function on_upload_image(e){
    var temp_arr = []
    var temp_image_arr = []
    Array.from(e.target.files).forEach((data, index) => {
      temp_image_arr.push(data)

      var image = URL.createObjectURL(data)
      var data1 = {}
      data1.file_name = image
      data1.is_primary = false

      base.getBase64(data, result => {
        data1.image = {
          image: result
        }
      })

      temp_arr.push(data1)
    })
    props.onChange(temp_arr)
  }

  return (
    <div className="card">
      <div className="card-header">
        <h5>{base.i18n.t('product_photo')}</h5>
      </div>
      <div className="card-body">
        <div>
          <input type="file" id="inputFile" className="d-none" multiple onChange={e => on_upload_image(e)}/>
          <button type="button" className="btn btn-primary" onClick={() => window.$('#inputFile').trigger('click')}>{base.i18n.t('upload_file')}</button>
        </div>
        <div className="row mt-3">
          {
            props.data.map((data, index) => (
              <div className="col-6 col-lg-3 mb-3" key={index}>
                <img src={data.file_name} width="100%" height="150rem"/>
                {
                  data.is_primary ?
                  <div className="w-100 mt-3 text-center">
                    {base.i18n.t('as_primary')}
                  </div>
                  :
                  <div className="w-100 mt-3">
                    <button className="btn btn-primary w-100" onClick={() => props.onChangePrimary(index)}>{base.i18n.t('set_primary')}</button>
                  </div>
                }

                <div className="w-100 mt-3">
                  <button className="btn btn-danger w-100" onClick={() => delete_data(index)}>{base.i18n.t('delete')}</button>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}
