import React, { useEffect } from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'

import OrderPage from '../pages/order/index'
import OrderActionPage from '../pages/order/action'

export default function OrderRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={OrderPage} />
      <Route exact path={`${props.match.path}/action`} component={OrderActionPage} />
    </>
  )
}
