import { useEffect, useState } from 'react';
import queryString from "query-string";
import moment from "moment";

import Base from '../../../utils/base'
import CustomTable from '../../../layout/custom_table/index'

export default function Exchange(props) {
  var base = new Base()
  const [last_exchange, set_last_exchange] = useState({
    price: 0,
    created_at: '',
    updated_at: '',
  })
  const [progress, set_progress] = useState(0)
  const [arr_header, set_arr_header] = useState([
    {
      label: base.i18n.t('date'),
      data: 'date',
    },
    {
      label: base.i18n.t('currency'),
      data: 'currency_name',
    },
    {
      label: base.i18n.t('price'),
      data: 'price',
    },
  ])
  const [arr, set_arr] = useState([])

  useEffect(() => {
    get_last_data()
  }, [])

  function get_last_data() {
    base.request(base.host + '/exchange/last')
      .then(response => {
        if(response != null && response.status === 'success'){
          set_last_exchange(response.data)
        }
      })
  }

  function on_edit(){
    window.location.href = '/exchange/action?id=' + last_exchange.id
  }

  function on_update_data(arr){
    arr.forEach((data, index) => {
      data.price = 'Rp. ' + data.price.toLocaleString(base.locale_string)
      data.date = moment(data.created_at).format('DD MMMM YYYY HH:mm')
      data.currency_name = data.currency.name
    })
    set_arr(arr)
  }

  return (
    <div>
      <h3>{base.i18n.t('exchange')}</h3>
      <div className="card">
        <div className="card-body d-flex align-items-center">
          <div>
            <h5>{base.i18n.t('last_exchange')}</h5>
            <h3>1 USD = Rp. {last_exchange != null ? last_exchange.price.toLocaleString(base.locale_string) : 0}</h3>
            <p className="m-0">{base.i18n.t('last_updated')}: {moment(last_exchange.created_at).format('DD MMMM YYYY HH:mm')}</p>
          </div>
          <button className="btn btn-primary ml-3" onClick={() => on_edit()}>{base.i18n.t('edit')}</button>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-header">
          {base.i18n.t('history_exchange')}
        </div>
        <div className="card-body">
          <CustomTable
            url='/exchange'
            arr_header={arr_header}
            arr={arr}
            no_search={true}
            on_update_data={data => on_update_data(data)}/>
        </div>
      </div>
    </div>
  );
}
