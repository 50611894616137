import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate'

import Base from '../../utils/base'

export default function Paginate(props) {
  var base = new Base()

  return (
    <nav aria-label="Page navigation example">
      <ReactPaginate
        previousLabel='previous'
        nextLabel='next'
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        breakLabel="..."
        pageCount={props.total_page}
        forcePage={props.current_page - 1}
        onPageChange={(data) => props.on_page_change(data)}
        containerClassName="pagination m-0"
        subContainerClassName="pages pagination"
        activeClassName="active"/>
    </nav>
  );
}