import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import Login from '../pages/auth/login'
import Profile from '../pages/auth/profile'
import ChangePassword from '../pages/auth/change_password'

export default function Auth(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/login`} component={Login} />
      <Route exact path={`${props.match.path}/profile`} component={Profile} />
      <Route exact path={`${props.match.path}/change-password`} component={ChangePassword} />
    </>
  )
}
