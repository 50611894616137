import { useEffect, useState } from 'react';
import queryString from "query-string";
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css';

import Base from '../../../../utils/base'
import ChooseCategory from './choose_category'

export default function OverallInfo(props) {
  var base = new Base()
  const [category, set_category] = useState({})
  const [arr_currency, set_arr_currency] = useState([])

  useEffect(() => {
    get_currency_data()
  }, [])

  useEffect(() => {
    get_category_data()
  }, [props.category])

  function get_category_data() {
    base.request(base.host + '/category?id=' + props.category).then(response => {
      if(response != null && response.status === 'success'){
        set_category(response.data)
      }
    })
  }

  function get_currency_data() {
    base.request(base.host + '/currency/all').then(response => {
      if(response != null && response.status === 'success'){
        set_arr_currency(response.data)
      }
    })
  }

  return (
    <div className="card">
      <div className="card-header">
        <h5>{base.i18n.t('product_info')}</h5>
      </div>
      <div className="card-body">
        <div className="form-group">
          <label>{base.i18n.t('status_publish')}</label>
          <div className="form-check">
            <input className="form-check-input" type="radio" name="status" value="0" onChange={() => props.onChange('is_publish', 0)} id="flexRadioDefault1" checked={props.is_publish == 0}/>
            <label className="form-check-label" htmlFor="flexRadioDefault1">
              {base.i18n.t('no')}
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="radio" name="status" value="1" onChange={() => props.onChange('is_publish', 1)} id="flexRadioDefault2" checked={props.is_publish == 1}/>
            <label className="form-check-label" htmlFor="flexRadioDefault2">
              {base.i18n.t('yes')}
            </label>
          </div>
        </div>
        <div className="form-group">
          <label>{base.i18n.t('name')}</label>
          <input type="text" className="form-control" onChange={e => props.onChange('name', e.target.value)} value={props.name}/>
        </div>
        <div className="form-group">
          <label>{base.i18n.t('description')}</label>
          <ReactSummernote
            value={props.description}
            onChange={content => props.onChange('description', content)}/>
        </div>
        <div className="form-group">
          <label>{base.i18n.t('currency')}</label>
          <select className="form-control" onChange={e => props.onChange('currency', e.target.value)} value={props.currency}>
            <option value="">{base.i18n.t('choose_option')}</option>
            {
              arr_currency.map((currency, index) => (
                <option key={index} value={currency.id}>{currency.name}</option>
              ))
            }
          </select>
        </div>
        <div className="form-group">
          <ChooseCategory on_choose={data => props.onChange('category', data.id)}/>
          <div>
            <label>{base.i18n.t('category')}</label>
          </div>
          <div>
            <button className="btn btn-primary" onClick={() => window.$('#chooseCategoryModal').modal('show')}>{base.i18n.t('choose_category')}</button>
          </div>
          {
            category.id != null ?
            <div>
              <p className="m-0">{category.name}</p>
            </div>
            :
            <></>
          }
        </div>
      </div>
    </div>
  );
}
