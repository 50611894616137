import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import BlogContactPage from '../../pages/master/blog_content/contact/index'
import BlogContactActionPage from '../../pages/master/blog_content/contact/action'

export default function BlogContactRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={BlogContactPage} />
      <Route exact path={`${props.match.path}/action`} component={BlogContactActionPage} />
    </>
  )
}
