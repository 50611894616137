import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../../../utils/base'

export default function SalesInfo(props) {
  var base = new Base()
  const [currency, set_currency] = useState({
    name: ''
  })

  useEffect(() => {
    get_currency_data()
  }, [props.currency])

  function get_currency_data() {
    base.request(base.host + '/currency?id=' + props.currency).then(response => {
      if(response != null && response.status === 'success'){
        set_currency(response.data)
      }
    })
  }

  return (
    <div className="card">
      <div className="card-header">
        <h5>{base.i18n.t('sales_info')}</h5>
      </div>
      <div className="card-body">
        <div className="form-group">
          <label>{base.i18n.t('sku')}</label>
          <input type="text" className="form-control" value={props.sku} onChange={e => props.onChange('sku', e.target.value)}/>
        </div>

        <div className="form-group">
          <label>{base.i18n.t('price')}</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">{currency.name}</div>
            </div>
            <input type="text" className="form-control" onChange={e => props.onChange('price', e.target.value)} value={props.price}/>
          </div>
        </div>

        <div className="form-group">
          <label>{base.i18n.t('stock')}</label>
          <input type="text" className="form-control" onChange={e => props.onChange('stock', e.target.value)} value={props.stock} disabled={props.data_product.id != null && !props.data_product.is_variant_enable}/>
        </div>
      </div>
    </div>
  );
}
