import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

import BlogAboutUsPage from '../../pages/master/blog_content/about_us/index'
import BlogAboutUsActionPage from '../../pages/master/blog_content/about_us/action'

export default function BlogAboutUsRoute(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/`} component={BlogAboutUsPage} />
      <Route exact path={`${props.match.path}/action`} component={BlogAboutUsActionPage} />
    </>
  )
}
